import React from 'react'

import '../styles.scss'
import {
  Note,
  NoteType,
  Membership,
  MILESTONE_ICONS,
  Role,
  NOTE_DESC_MAX_LENGTH,
  CalculatorType,
} from 'shared/types'
import {
  ModalHeader,
  Modal,
  ModalBody,
  Button,
  Input,
  Form,
  Spinner,
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Formik, FormikProps, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import { TIMELINE_LENGTH } from 'shared/constants'

interface TypeButtonProps {
  type: NoteType
  disabled: boolean
  selected: boolean
  onClick: (evt: React.MouseEvent) => void
}

const TypeButton: React.FC<TypeButtonProps> = (props: TypeButtonProps) => {
  const Icon = MILESTONE_ICONS[props.type]
  const { t } = useTranslation()
  const className = props.selected ? 'type-button selected' : 'type-button'
  return (
    <Button
      disabled={props.disabled}
      className={className}
      onClick={props.onClick}
    >
      <Icon />
      <span>{t(`shared.noteTypes.${props.type}`)}</span>
    </Button>
  )
}

interface NoteEditModalBodyProps {
  toggle: () => void
  note?: Note
  memberships: Membership[]
  addNote: (note: NoteFormValues) => Promise<void>
  editNote: (note: NoteFormValues, id: number) => Promise<void>
  deleteNote: (note: Note) => Promise<void>
  noteYearMin: number
  noteYearMax: number
}

export interface NoteFormValues {
  type: NoteType | null
  membershipId: number | ''
  year: number | ''
  description?: string
}

const buttonTypes = [
  NoteType.Milestone,
  NoteType.Travel,
  NoteType.Education,
  NoteType.Fitness,
  NoteType.Housing,
  NoteType.Child,
]

export const NoteEditModalBody: React.FC<NoteEditModalBodyProps> = (
  props: NoteEditModalBodyProps
) => {
  const { t } = useTranslation()

  const initialValues: NoteFormValues = {
    type: props.note?.type || NoteType.Milestone,
    membershipId:
      props.note?.membership?.id === undefined ? '' : props.note?.membership.id,
    year: props.note?.year || '',
    description: props.note?.description || '',
  }

  const validationSchema = yup.object().shape({
    year: yup
      .number()
      .required(t('features.notes.noteEditModal.year.errors.required'))
      .when(
        'membershipId',
        (membershipId: number, schema: yup.NumberSchema) => {
          const membership = props.memberships.find(m => m.id === membershipId)
          const min = membership
            ? membership.person.birthYear
            : props.noteYearMin
          const max = membership
            ? membership.person.birthYear + TIMELINE_LENGTH
            : props.noteYearMax
          const error = t(
            'features.notes.noteEditModal.year.errors.outOfRange',
            {
              min: min,
              max: max,
            }
          )
          return schema.min(min, error).max(max, error)
        }
      ),
    description: yup.string(),
  })

  const handleSubmit = async (vals: NoteFormValues) => {
    if (props.note) {
      await props.editNote(vals, props.note.id)
    } else {
      await props.addNote(vals)
    }
  }

  return (
    <>
      <ModalHeader toggle={props.toggle} />
      <h3 className="text-center">
        {props.note
          ? t('features.notes.noteEditModal.editHeader')
          : t('features.notes.noteEditModal.addHeader')}
      </h3>
      <ModalBody>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
        >
          {(formik: FormikProps<NoteFormValues>) => {
            const onDelete = async () => {
              if (props.note) {
                formik.setSubmitting(true)
                await props.deleteNote(props.note)
                formik.setSubmitting(false)
              }
            }

            return (
              <Form
                onSubmit={async (event: React.FormEvent): Promise<void> => {
                  event.preventDefault()
                  await formik.submitForm()
                }}
              >
                <div className="note-type-selector">
                  {buttonTypes.map(type => (
                    <TypeButton
                      key={type}
                      type={type}
                      disabled={formik.isSubmitting}
                      selected={formik.values?.type === type}
                      onClick={(): void => {
                        formik.setFieldValue('type', type)
                      }}
                    />
                  ))}
                </div>
                <Field
                  as={Input}
                  type="select"
                  name="membershipId"
                  disabled={formik.isSubmitting}
                  className={
                    formik.errors.membershipId && formik.touched.membershipId
                      ? 'note-edit-modal-field has-errors'
                      : 'note-edit-modal-field'
                  }
                >
                  <option value="">
                    {t('features.notes.noteEditModal.owner.default')}
                  </option>
                  {props.memberships
                    .filter(membership => membership.role !== Role.Member)
                    .map((membership, index) => (
                      <option key={index} value={membership.id}>
                        {membership.person.firstName}
                      </option>
                    ))}
                </Field>
                <Field
                  as={Input}
                  type="number"
                  name="year"
                  disabled={formik.isSubmitting}
                  placeholder={t('features.notes.noteEditModal.year.default')}
                  className={
                    formik.errors.year && formik.touched.year
                      ? 'note-edit-modal-field has-errors'
                      : 'note-edit-modal-field'
                  }
                />
                <ErrorMessage name="year">
                  {msg => <div className="form-error">{msg}</div>}
                </ErrorMessage>
                <Field
                  as={Input}
                  type="textarea"
                  name="description"
                  disabled={formik.isSubmitting}
                  placeholder={t(
                    'features.notes.noteEditModal.description.default'
                  )}
                  maxLength={NOTE_DESC_MAX_LENGTH}
                  className={
                    formik.errors.description && formik.errors.description
                      ? 'note-edit-modal-field has-errors'
                      : 'note-edit-modal-field'
                  }
                />
                <div className="d-flex justify-content-around w-100">
                  {formik.isSubmitting ? (
                    <Spinner />
                  ) : (
                    <>
                      {props.note?.calculator === CalculatorType.Custom && (
                        <Button color="link" onClick={onDelete}>
                          <h2>{t('features.notes.noteEditModal.delete')}</h2>
                        </Button>
                      )}
                      <Button type="submit" color="link">
                        <h2>{t('features.notes.noteEditModal.done')}</h2>
                      </Button>
                    </>
                  )}
                </div>
              </Form>
            )
          }}
        </Formik>
      </ModalBody>
    </>
  )
}

interface NoteEditModalProps extends NoteEditModalBodyProps {
  isOpen: boolean
}

const NoteEditModal: React.FC<NoteEditModalProps> = (
  props: NoteEditModalProps
) => {
  return (
    <Modal
      contentClassName="note-edit-modal"
      isOpen={props.isOpen}
      toggle={props.toggle}
      centered
    >
      <NoteEditModalBody {...props} />
    </Modal>
  )
}

export default NoteEditModal
