export const TopUserMargin = 81.5
export const UserDistance = 79.5
export const LeftYearMargin = 70
export const YearDistance = 10

interface CoordinateTranslator {
  user: (index: number, useMargin?: boolean) => number
  year: (year: number, useMargin?: boolean) => number
}

const AxisTranslator: CoordinateTranslator = {
  user: (index: number, useMargin = true) => {
    return (useMargin ? TopUserMargin : 0) + UserDistance * index
  },
  year: (yearFromStart: number, useMargin = true) => {
    return (useMargin ? LeftYearMargin : 0) + YearDistance * yearFromStart
  },
}

export default AxisTranslator
