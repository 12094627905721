import React, { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
	useParams,
	useHistory,
	useRouteMatch,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import Header from "shared/views/Header";
import { useTranslation } from "react-i18next";
import * as queries from "./queries";
import LoadingSpinner from "shared/views/spinner/LoadingSpinner";
import EducationCalculatorForm from "./views/EducationCalculatorForm";
import { Education } from "./types";
import EducationConfirm from "./views/EducationConfirm";
import { apolloErrorToString } from "shared/utils/api/ApolloClient";
import i18n from "i18n";
import { EDUCATION_GRAPH_REFETCH } from "features/graph/finances/queries";

const EducationCalculator: React.FC = () => {
	const { membershipId } = useParams();
	const { t } = useTranslation();
	const history = useHistory();
	const match = useRouteMatch();

	const { data, loading } = useQuery<queries.EducationQuery, queries.EducationQueryInput>(queries.EDUCATION_QUERY, { variables: { membershipId: Number(membershipId) } });
	const [
		mutate,
		{ data: mutationData, loading: mutationLoading, error: mutationError },
	] = useMutation<queries.EducationMutation, queries.EducationMutationInput>(
		queries.EDUCATION_MUTATION
	);

	useEffect(() => {
		window.gtag("event", "education_calculator_page_view", {
			page: history.location.pathname,
		});
	}, [history.location]);

	if (isNaN(Number(membershipId)) || !membershipId)
		return <Redirect to="/timeline" />;

	if (!data || loading) {
		return <LoadingSpinner />;
	}

	return (
		<Switch>
			<Route exact path={`${match.url}`}>
				<Header text={t("features.calculator.education.formHeader")} />
				<EducationCalculatorForm
					initialValues={data.education || undefined}
					onSubmit={async (values: Education | null) => {
						await mutate({
							variables: {
								membershipId: Number(membershipId),
								education: values,
							},
							refetchQueries: [
								{
									query: queries.EDUCATION_QUERY,
									variables: {
										membershipId: Number(membershipId),
									},
								},
								{
									query: EDUCATION_GRAPH_REFETCH,
								},
								{
									query: queries.SEGMENT_REFETCH,
								},
							],
							awaitRefetchQueries: true,
						});
						if (values !== null) history.push(`${match.url}/results`);
						else history.push(`/dashboard/${membershipId}`);
					}}
					loading={mutationLoading}
					mutationError={
						mutationError ? apolloErrorToString(mutationError, i18n) : ""
					}
				/>
			</Route>
			<Route exact path={`${match.url}/results`}>
				<Header text={t("features.calculator.education.resultsHeader")} />
				{mutationData?.updateEducation && (
					<EducationConfirm
						backUrl={`/dashboard/${membershipId}`}
						projectedSavings={
              mutationData?.updateEducation.projectedSavings || 0
						}
						savingsGoal={mutationData?.updateEducation.savingsGoal || 0}
						surplus={mutationData?.updateEducation.surplus || 0}
					/>
				)}
				{!mutationData?.updateEducation && (
					<Redirect to={`/dashboard/${membershipId}`} />
				)}
			</Route>
		</Switch>
	);
};

export default EducationCalculator;
