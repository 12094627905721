import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import '../styles.scss';
import {
  Button,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Formik, FormikProps, Form, Field, FieldProps } from 'formik';
import * as yup from 'yup';
import { SetActivity } from '../reducer';
import { showLIfeExpectancyActivity } from './LifeExpectancy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface FormValues {
  continuousActivity?: number;
  nonContinuousActivity?: number;
  lightActivity?: number;
}

interface Props {
  continuousActivity?: number;
  nonContinuousActivity?: number;
  lightActivity?: number;
  setActivity: (s: SetActivity) => void;
}

const PhysicalActivity: React.FC<Props> = ({
  continuousActivity,
  nonContinuousActivity,
  lightActivity,
  setActivity,
}: Props) => {
  const { t } = useTranslation();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const history = useHistory();

  const handleSubmit = (values: FormValues) => {
    if (
      values.continuousActivity !== undefined &&
      values.nonContinuousActivity !== undefined &&
      values.lightActivity !== undefined
    ) {
      setActivity({
        type: 'set_activity',
        vigorousActivityHours: values.continuousActivity,
        vigorousNonContinuousActivityHours: values.nonContinuousActivity,
        lightActivityHours: values.lightActivity,
      });
      setButtonDisabled(true)
      // let result = showLIfeExpectancyActivity(
      //   values.continuousActivity,
      //   values.nonContinuousActivity,
      //   values.lightActivity
      // );
      // toast.info(`Predicted Life Expectancy: ${result}`, {
      //   position: 'top-right',
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   onClose: () => {
      //     history.push('/smoking');
      //   },
      // });
       showLIfeExpectancyActivity(
        values.continuousActivity,
        values.nonContinuousActivity,
        values.lightActivity
      );
       history.push('/smoking');
    }
  };

  const unit = t('features.calculator.lifeExpectancy.units.time');

  const validationSchema = yup.object().shape({
    continuousActivity: yup
      .number()
      .required(
        t(
          'features.calculator.lifeExpectancy.physical.continuousActivity.errors.empty'
        )
      )
      .min(
        0,
        t(
          'features.calculator.lifeExpectancy.physical.continuousActivity.errors.negative'
        )
      )
      .integer(
        t(
          'features.calculator.lifeExpectancy.physical.continuousActivity.errors.nonInteger'
        )
      ),
    nonContinuousActivity: yup
      .number()
      .required(
        t(
          'features.calculator.lifeExpectancy.physical.nonContinuousActivity.errors.empty'
        )
      )
      .min(
        0,
        t(
          'features.calculator.lifeExpectancy.physical.nonContinuousActivity.errors.negative'
        )
      )
      .integer(
        t(
          'features.calculator.lifeExpectancy.physical.nonContinuousActivity.errors.nonInteger'
        )
      ),
    lightActivity: yup
      .number()
      .required(
        t(
          'features.calculator.lifeExpectancy.physical.lightActivity.errors.empty'
        )
      )
      .min(
        0,
        t(
          'features.calculator.lifeExpectancy.physical.lightActivity.errors.negative'
        )
      )
      .integer(
        t(
          'features.calculator.lifeExpectancy.physical.lightActivity.errors.nonInteger'
        )
      ),
  });

  return (
    <Container className='content'>
      <h1 className='display-4 text-center title'>
        {t('features.calculator.lifeExpectancy.physical.title')}
      </h1>
      <Formik
        initialValues={{
          continuousActivity: continuousActivity,
          nonContinuousActivity: nonContinuousActivity,
          lightActivity: lightActivity,
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
      >
        {({ errors }: FormikProps<FormValues>) => (
          <Form>
            <h6>
              {t(
                'features.calculator.lifeExpectancy.physical.continuousActivity.label'
              )}
            </h6>
            <Field name='continuousActivity' type='number'>
              {({ field }: FieldProps) => (
                <InputGroup className='mb-2'>
                  <Input
                    placeholder={t(
                      'features.calculator.lifeExpectancy.physical.continuousActivity.placeholder'
                    )}
                    bsSize='lg'
                    type={'number'}
                    className={`goal custom${
                      errors.continuousActivity ? ' has-errors' : ''
                    }`}
                    {...field}
                  />
                  <InputGroupAddon
                    addonType='append'
                    className={`${
                      errors.continuousActivity ? 'has-errors' : ''
                    }`}
                  >
                    {unit}
                  </InputGroupAddon>
                </InputGroup>
              )}
            </Field>
            {errors.continuousActivity && (
              <div className='form-error'>{errors.continuousActivity}</div>
            )}
            <h6>
              {t(
                'features.calculator.lifeExpectancy.physical.nonContinuousActivity.label'
              )}
            </h6>
            <Field name='nonContinuousActivity' type='number'>
              {({ field }: FieldProps) => (
                <InputGroup className='mb-2'>
                  <Input
                    placeholder={t(
                      'features.calculator.lifeExpectancy.physical.nonContinuousActivity.placeholder'
                    )}
                    bsSize='lg'
                    type={'number'}
                    className={`goal custom${
                      errors.nonContinuousActivity ? ' has-errors' : ''
                    }`}
                    {...field}
                  />
                  <InputGroupAddon
                    addonType='append'
                    className={`${
                      errors.nonContinuousActivity ? 'has-errors' : ''
                    }`}
                  >
                    {unit}
                  </InputGroupAddon>
                </InputGroup>
              )}
            </Field>
            {errors.nonContinuousActivity && (
              <div className='form-error'>{errors.nonContinuousActivity}</div>
            )}
            <h6>
              {t(
                'features.calculator.lifeExpectancy.physical.lightActivity.label'
              )}
            </h6>
            <Field name='lightActivity' type='number'>
              {({ field }: FieldProps) => (
                <InputGroup className='mb-2'>
                  <Input
                    placeholder={t(
                      'features.calculator.lifeExpectancy.physical.lightActivity.placeholder'
                    )}
                    bsSize='lg'
                    type={'number'}
                    className={`goal custom${
                      errors.lightActivity ? ' has-errors' : ''
                    }`}
                    {...field}
                  />
                  <InputGroupAddon
                    addonType='append'
                    className={`${errors.lightActivity ? 'has-errors' : ''}`}
                  >
                    {unit}
                  </InputGroupAddon>
                </InputGroup>
              )}
            </Field>
            {errors.lightActivity && (
              <div className='form-error'>{errors.lightActivity}</div>
            )}
            <hr />
            <Button disabled={buttonDisabled} type='submit' color='primary' block size='lg'>
              {t('features.getstarted.next')}
            </Button>
            <Button
              outline
              color='link'
              onClick={() => {
                history.goBack();
              }}
              className='w-100'
            >
              {t('features.calculator.lifeExpectancy.physical.confirmBack')}
            </Button>
          </Form>
        )}
      </Formik>
      <ToastContainer />
    </Container>
  );
};

export default PhysicalActivity;
