import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import '../styles.scss'
import { Button, Container, Input } from 'reactstrap'
import allCountries from '../countries'
import { useHistory } from 'react-router-dom'
import { SearchIcon } from 'shared/views/Icon'

interface Props {
  name: string
  country?: string
  setCountry: (country?: string) => void
}

interface CountryProps {
  label: string
  selected: boolean
  onSelect: () => void
}

const CountryItemView: React.FC<CountryProps> = ({
  label,
  selected,
  onSelect,
}: CountryProps) => (
  <Button
    block
    outline={!selected}
    color="success"
    className="goal"
    onClick={onSelect}
  >
    {label}
  </Button>
)

const Country: React.FC<Props> = ({ name, country, setCountry }: Props) => {
  const { t } = useTranslation()
  const [countryInput, setCountryInput] = useState<string>(country || '')
  const [countries, setCountries] = useState<{ name: string; code: string }[]>(
    allCountries
  )
  const [showError, setShowError] = useState<boolean>(false)
  const history = useHistory()

  const onCountrySelect = (country: { name: string; code: string }): void => {
    setCountryInput(country.name || '')
    setCountry(country.code)
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCountries(
      allCountries.filter(country =>
        country.name.toLowerCase().startsWith(event.target.value.toLowerCase())
      )
    )
    setCountryInput(event.currentTarget.value)
  }

  const handleSubmit = () => {
    if (country) {
      history.push('/add-partner')
    } else {
      setShowError(true)
    }
  }

  return (
    <Container className="content">
      <h3 className="text-center">{name}</h3>
      <h1 className="display-4 text-center title">
        {t('features.getstarted.country.title')}
      </h1>
      <div className="searchbar">
        <SearchIcon />
        <Input
          type="search"
          name="search"
          id="search"
          placeholder={t('features.getstarted.country.search')}
          className="goal custom"
          value={countryInput}
          onChange={onInputChange}
        />
      </div>
      {showError && (
        <div className="form-error">
          {t('features.getstarted.country.errors.empty')}
        </div>
      )}
      <div className="scrollview">
        {countries.map((c, index) => (
          <CountryItemView
            key={index}
            label={c.name}
            selected={country === c.code}
            onSelect={(): void => onCountrySelect(c)}
          />
        ))}
      </div>
      <hr />
      <Button color="primary" block size="lg" onClick={handleSubmit}>
        {t('features.getstarted.next')}
      </Button>
    </Container>
  )
}

export default Country
