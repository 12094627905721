import React from 'react'
import { useAuth0 } from './auth/Provider'
import { NavItem, Navbar, NavbarBrand, Nav, NavLink } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const NavBar: React.FC = () => {
  const { t } = useTranslation()
  const { isAuthenticated, logout } = useAuth0()

  return (
    <div>
      <Navbar color="primary" dark={true}>
        <NavbarBrand href="/" className="navBrand">
          {t('shared.app.name')}
        </NavbarBrand>
        <Nav>
          {isAuthenticated && (
            <NavItem>
              <NavLink href="/timeline" className="text-white clickable">
                Go to Timeline
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            {!isAuthenticated && (
              <NavLink href="/login" className="text-white clickable">
                Log in
              </NavLink>
            )}

            {isAuthenticated && (
              <NavLink
                href="#"
                onClick={async (): Promise<void> => await logout()}
                className="text-white clickable"
              >
                Log out
              </NavLink>
            )}
          </NavItem>
        </Nav>
      </Navbar>
    </div>
  )
}

export default NavBar
