import React from 'react'

import '../styles.scss'
import {
  ModalHeader,
  Modal,
  ModalBody,
  Button,
  Input,
  Form,
  Spinner,
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Formik, FormikProps, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import { ContributionType } from '../types'
import { MAX_ASSET_VALUE } from 'shared/constants'

export interface FormValues {
  frequency: ContributionType
  food: number | undefined
  healthcare: number | undefined
  housing: number | undefined
  transport: number | undefined
  financial: number | undefined
  other: number | undefined
}

interface ExpenseModalBodyProps {
  toggle: () => void
  submit: (total: number, freq: ContributionType) => void
}

export const ExpenseModalBody: React.FC<ExpenseModalBodyProps> = (
  props: ExpenseModalBodyProps
) => {
  const { t } = useTranslation()

  const initialValues: FormValues = {
    frequency: ContributionType.Monthly,
    food: undefined,
    healthcare: undefined,
    housing: undefined,
    transport: undefined,
    financial: undefined,
    other: undefined,
  }

  const validationSchema = yup.object().shape({
    frequency: yup
      .string()
      .required(t('features.calculator.retirement.errors.required')),
    food: yup
      .number()
      .required(t('features.calculator.retirement.errors.required'))
      .min(
        0,
        t('features.calculator.retirement.errors.outOfRange', {
          max: MAX_ASSET_VALUE.toLocaleString(),
        })
      )
      .max(
        MAX_ASSET_VALUE,
        t('features.calculator.retirement.errors.outOfRange', {
          max: MAX_ASSET_VALUE.toLocaleString(),
        })
      ),
    healthcare: yup
      .number()
      .required(t('features.calculator.retirement.errors.required'))
      .min(
        0,
        t('features.calculator.retirement.errors.outOfRange', {
          max: MAX_ASSET_VALUE.toLocaleString(),
        })
      )
      .max(
        MAX_ASSET_VALUE,
        t('features.calculator.retirement.errors.outOfRange', {
          max: MAX_ASSET_VALUE.toLocaleString(),
        })
      ),
    housing: yup
      .number()
      .required(t('features.calculator.retirement.errors.required'))
      .min(
        0,
        t('features.calculator.retirement.errors.outOfRange', {
          max: MAX_ASSET_VALUE.toLocaleString(),
        })
      )
      .max(
        MAX_ASSET_VALUE,
        t('features.calculator.retirement.errors.outOfRange', {
          max: MAX_ASSET_VALUE.toLocaleString(),
        })
      ),
    transport: yup
      .number()
      .required(t('features.calculator.retirement.errors.required'))
      .min(
        0,
        t('features.calculator.retirement.errors.outOfRange', {
          max: MAX_ASSET_VALUE.toLocaleString(),
        })
      )
      .max(
        MAX_ASSET_VALUE,
        t('features.calculator.retirement.errors.outOfRange', {
          max: MAX_ASSET_VALUE.toLocaleString(),
        })
      ),
    financial: yup
      .number()
      .required(t('features.calculator.retirement.errors.required'))
      .min(
        0,
        t('features.calculator.retirement.errors.outOfRange', {
          max: MAX_ASSET_VALUE.toLocaleString(),
        })
      )
      .max(
        MAX_ASSET_VALUE,
        t('features.calculator.retirement.errors.outOfRange', {
          max: MAX_ASSET_VALUE.toLocaleString(),
        })
      ),
    other: yup
      .number()
      .required(t('features.calculator.retirement.errors.required'))
      .min(
        0,
        t('features.calculator.retirement.errors.outOfRange', {
          max: MAX_ASSET_VALUE.toLocaleString(),
        })
      )
      .max(
        MAX_ASSET_VALUE,
        t('features.calculator.retirement.errors.outOfRange', {
          max: MAX_ASSET_VALUE.toLocaleString(),
        })
      ),
  })

  const handleSubmit = async (vals: FormValues) => {
    const { frequency, ...nums } = vals
    const total = Object.values(nums).reduce((a, b) => a + b)
    props.submit(total, frequency)
    props.toggle()
  }

  return (
    <>
      <ModalHeader toggle={props.toggle} />
      <h3 className="text-center">
        {t('features.calculator.retirement.expenseModal.title')}
      </h3>
      <ModalBody>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
        >
          {(formik: FormikProps<FormValues>) => {
            return (
              <Form
                onSubmit={async (event: React.FormEvent): Promise<void> => {
                  event.preventDefault()
                  await formik.submitForm()
                }}
              >
                <Field
                  as={Input}
                  type="select"
                  name="frequency"
                  disabled={formik.isSubmitting}
                  className="mb-2"
                >
                  {Object.values(ContributionType).map(type => (
                    <option key={type} value={type}>
                      {t(
                        `features.calculator.retirement.contributionType.options.${type}`
                      )}
                    </option>
                  ))}
                </Field>
                <label htmlFor="food" className="mt-1">
                  {t('features.calculator.retirement.expenseModal.food')}
                </label>
                <Field
                  as={Input}
                  type="number"
                  name="food"
                  disabled={formik.isSubmitting}
                  placeholder={t(
                    'features.calculator.retirement.expenseModal.food'
                  )}
                  className={
                    formik.errors.food && formik.touched.food
                      ? 'mb-2 has-errors'
                      : 'mb-2'
                  }
                />
                <ErrorMessage name="food">
                  {msg => <div className="form-error">{msg}</div>}
                </ErrorMessage>
                <label htmlFor="healthcare" className="mt-1">
                  {t('features.calculator.retirement.expenseModal.healthcare')}
                </label>
                <Field
                  as={Input}
                  type="number"
                  name="healthcare"
                  disabled={formik.isSubmitting}
                  placeholder={t(
                    'features.calculator.retirement.expenseModal.healthcare'
                  )}
                  className={
                    formik.errors.healthcare && formik.touched.healthcare
                      ? 'mb-2 has-errors'
                      : 'mb-2'
                  }
                />
                <ErrorMessage name="healthcare">
                  {msg => <div className="form-error">{msg}</div>}
                </ErrorMessage>
                <label htmlFor="housing" className="mt-1">
                  {t('features.calculator.retirement.expenseModal.housing')}
                </label>
                <Field
                  as={Input}
                  type="number"
                  name="housing"
                  disabled={formik.isSubmitting}
                  placeholder={t(
                    'features.calculator.retirement.expenseModal.housing'
                  )}
                  className={
                    formik.errors.housing && formik.touched.housing
                      ? 'mb-2 has-errors'
                      : 'mb-2'
                  }
                />
                <ErrorMessage name="housing">
                  {msg => <div className="form-error">{msg}</div>}
                </ErrorMessage>
                <label htmlFor="transport" className="mt-1">
                  {t('features.calculator.retirement.expenseModal.transport')}
                </label>
                <Field
                  as={Input}
                  type="number"
                  name="transport"
                  disabled={formik.isSubmitting}
                  placeholder={t(
                    'features.calculator.retirement.expenseModal.transport'
                  )}
                  className={
                    formik.errors.transport && formik.touched.transport
                      ? 'mb-2 has-errors'
                      : 'mb-2'
                  }
                />
                <ErrorMessage name="transport">
                  {msg => <div className="form-error">{msg}</div>}
                </ErrorMessage>
                <label htmlFor="financial" className="mt-1">
                  {t('features.calculator.retirement.expenseModal.financial')}
                </label>
                <Field
                  as={Input}
                  type="number"
                  name="financial"
                  disabled={formik.isSubmitting}
                  placeholder={t(
                    'features.calculator.retirement.expenseModal.financial'
                  )}
                  className={
                    formik.errors.financial && formik.touched.financial
                      ? 'mb-2 has-errors'
                      : 'mb-2'
                  }
                />
                <ErrorMessage name="financial">
                  {msg => <div className="form-error">{msg}</div>}
                </ErrorMessage>
                <label htmlFor="other" className="mt-1">
                  {t('features.calculator.retirement.expenseModal.other')}
                </label>
                <Field
                  as={Input}
                  type="number"
                  name="other"
                  disabled={formik.isSubmitting}
                  placeholder={t(
                    'features.calculator.retirement.expenseModal.other'
                  )}
                  className={
                    formik.errors.other && formik.touched.other
                      ? 'mb-2 has-errors'
                      : 'mb-2'
                  }
                />
                <ErrorMessage name="other">
                  {msg => <div className="form-error">{msg}</div>}
                </ErrorMessage>

                <div className="d-flex justify-content-around w-100">
                  {formik.isSubmitting ? (
                    <Spinner />
                  ) : (
                    <Button type="submit" color="link">
                      <h2>{t('features.notes.noteEditModal.done')}</h2>
                    </Button>
                  )}
                </div>
              </Form>
            )
          }}
        </Formik>
      </ModalBody>
    </>
  )
}

interface ExpenseModalProps extends ExpenseModalBodyProps {
  isOpen: boolean
}

const ExpenseModal: React.FC<ExpenseModalProps> = (
  props: ExpenseModalProps
) => {
  return (
    <Modal
      contentClassName="note-edit-modal"
      isOpen={props.isOpen}
      toggle={props.toggle}
      centered
    >
      <ExpenseModalBody {...props} />
    </Modal>
  )
}

export default ExpenseModal
