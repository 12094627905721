import React, { useState } from 'react'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Family, Role, Membership } from '../../shared/types'
import PersonForm, {
  PersonFormValues,
} from '../../shared/views/person/PersonForm'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container, Input } from 'reactstrap'

import { GET_TIMELINE } from 'features/timeline'
import Header from 'shared/views/Header'
import { personAndMembershipFragment } from 'shared/queries'
import {
  analyticsAddMembership,
  analyticsEditMembership,
} from 'shared/views/analytics'

interface PersonQuery {
  family: Family
}

const PERSON_QUERY = gql`
  query {
    family {
      id
      profiles {
        membership {
          ...membershipFields
        }
      }
    }
  }

  ${personAndMembershipFragment}
`

interface EditPersonProps {
  membershipId?: string
  family: Family
  addPerson: (values: PersonFormValues, role: Role) => Promise<void>
  editPerson: (values: PersonFormValues, role: Role) => Promise<void>
}

const getValidRoles = (family: Family, membership?: Membership): Role[] => {
  const roles = []
  // you can always be your current role
  if (membership) {
    roles.push(membership.role)
  }
  // primaries can't change their role at all
  if (membership?.role !== Role.Primary) {
    // if there's no partner already, you can be a partner
    if (
      !family.profiles.some(profile => profile.membership.role === Role.Partner)
    ) {
      roles.push(Role.Partner)
    }
    // you can always be a member or child, but only if we didn't add that already
    if (membership?.role !== Role.Member) {
      roles.push(Role.Member)
    }
    if (membership?.role !== Role.Child) {
      roles.push(Role.Child)
    }
  }
  return roles
}

export const EditPersonInner: React.FC<EditPersonProps> = (
  props: EditPersonProps
) => {
  const { t } = useTranslation()
  const history = useHistory()

  const defaultInitialValues: PersonFormValues = {
    firstName: '',
    lastName: '',
    birthYear: '',
    sex: '',
    country: '',
    reset: false,
  }

  const membership = props.membershipId
    ? props.family.profiles.find(
        profile => profile.membership.id.toString() === props.membershipId
      )?.membership
    : undefined

  const initialValues: PersonFormValues = membership
    ? {
        ...membership.person,
        reset: false,
      }
    : defaultInitialValues

  const [role, setRole] = useState<Role | ''>(membership?.role || '')

  const onSubmit = async (values: PersonFormValues) => {
    if (role !== '') {
      if (props.membershipId) {
        await props.editPerson(values, role)
        history.push('/timeline')
      } else {
        await props.addPerson(values, role)
        history.push('/timeline')
      }
    }
  }

  return (
    <>
      <Header
        text={
          props.membershipId
            ? t('features.person.edit.editHeader')
            : t('features.person.edit.createHeader')
        }
      />
      <Container className="content">
        <Input
          type="select"
          className="goal custom"
          value={role}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
            setRole(evt.currentTarget.value as Role)
          }}
        >
          <option>{t('features.person.edit.role.default')}</option>
          {getValidRoles(props.family, membership).map(role => (
            <option key={role} value={role}>
              {t(`shared.roles.${role}`)}
            </option>
          ))}
        </Input>
        <PersonForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          disabled={role === ''}
          submitText={t('features.person.edit.done')}
        />
      </Container>
    </>
  )
}

const ADD_MEMBERSHIP_MUTATION = gql`
  mutation AddMembership($person: PersonInput!, $role: String!) {
    addMembership(person: $person, role: $role) {
      id
    }
  }
`

const EDIT_MEMBERSHIP_MUTATION = gql`
  mutation EditMembership(
    $person: PersonInput!
    $role: String!
    $membershipId: ID!
  ) {
    editMembership(person: $person, role: $role, membershipId: $membershipId) {
      id
    }
  }
`

const EditPerson = () => {
  const { data } = useQuery<PersonQuery>(PERSON_QUERY)
  const { id } = useParams()

  const [editMembershipMutation] = useMutation(ADD_MEMBERSHIP_MUTATION)
  const addPerson = async (
    values: PersonFormValues,
    role: Role
  ): Promise<void> => {
    analyticsAddMembership(role)
    await editMembershipMutation({
      variables: {
        person: {
          firstName: values.firstName,
          lastName: values.lastName,
          birthYear: values.birthYear,
          country: values.country,
          sex: values.sex,
        },
        role: role,
      },
      refetchQueries: [{ query: GET_TIMELINE }],
    })
  }

  const [editPersonMutation] = useMutation(EDIT_MEMBERSHIP_MUTATION)
  const editPerson = async (
    values: PersonFormValues,
    role: Role
  ): Promise<void> => {
    analyticsEditMembership(role)
    await editPersonMutation({
      variables: {
        person: {
          firstName: values.firstName,
          lastName: values.lastName,
          birthYear: values.birthYear,
          country: values.country,
          sex: values.sex,
        },
        role: role,
        membershipId: id,
      },
      refetchQueries: [{ query: GET_TIMELINE }],
    })
  }

  if (data) {
    return (
      <EditPersonInner
        family={data.family}
        membershipId={id}
        addPerson={addPerson}
        editPerson={editPerson}
      />
    )
  } else {
    return <div />
  }
}

export default EditPerson
