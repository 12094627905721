import React, { useState, useRef, useEffect } from "react";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
	Family,
	FeatureFlags,
	Note,
	Role,
	Person,
	CalculatorType,
} from "shared/types";
import NavbarTimeline, { HeaderItem } from "./views/NavbarTimeline";
import Timeline from "./views/Timeline";
import LeftNav from "./views/LeftNav";
import "./styles.scss";
import TimelineIntroPage from "./views/TimelineIntroPage";
import Minimap from "./views/Minimap";
import TimelineRuler from "./views/TimelineRuler";
import AxisTranslator from "shared/utils/axis";
import NoteEditModal, {
	NoteFormValues,
} from "features/notes/views/NoteEditModal";
import NoteViewModal from "features/notes/views/NoteViewModal";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "shared/views/spinner/LoadingSpinner";
import {
	personAndMembershipFragment,
	timelineFragment,
	noteFragment,
	featureFlagsFragment,
} from "shared/queries";
import {
	analyticsDeleteNote,
	analyticsEditNote,
	analyticsAddNote,
	analyticsViewNote,
	analyticsShareNote,
	analyticsUnShareNote,
} from "shared/views/analytics";
import NoteShareModal from "features/notes/views/NoteShareModal";
import RateInfoGraph from "features/graph/RateInfoGraph";
import EventsGraph from "features/graph/EventsGraph";
import FinancesGraph from "features/graph/finances/FinancesGraph";
import { RETIREMENT_GRAPH_REFETCH } from "features/graph/finances/queries";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

interface TimelineQuery {
  me: {
	person: Person
  }
  family: Family
  featureFlags: FeatureFlags
}

export const GET_TIMELINE = gql`
  query {
	me {
	  person {
		...personFields
	  }
	}
	family {
	  id
	  profiles {
		membership {
		  ...membershipFields
		}
		timeline {
		  ...timelineFields
		  notes {
			membership {
			  ...membershipFields
			}
			...noteFields
		  }
		}
	  }
	  notes {
		group {
		  id
		}
		owner {
		  ...personFields
		}
		sharedWith {
		  ...personFields
		}
		...noteFields
	  }
	}
	featureFlags {
	  ...featureFlagsFields
	}
  }

  ${personAndMembershipFragment}
  ${timelineFragment}
  ${noteFragment}
  ${featureFlagsFragment}
`;
const SET_SHOW_TIMELINE_INTRO_FALSE = gql`
  mutation {
	updateFeatureFlags(showTimelineIntro: false) {
	  id
	}
  }
`;
const ADD_NOTE = gql`
  mutation AddNote(
	$description: String!
	$groupId: ID
	$membershipId: ID
	$type: String!
	$year: Int!
  ) {
	addNote(
	  description: $description
	  groupId: $groupId
	  membershipId: $membershipId
	  type: $type
	  year: $year
	) {
	  id
	}
  }
`;

const EDIT_NOTE = gql`
  mutation AddNote(
	$description: String!
	$groupId: ID
	$id: ID!
	$membershipId: ID
	$type: String!
	$year: Int!
  ) {
	editNote(
	  description: $description
	  groupId: $groupId
	  membershipId: $membershipId
	  type: $type
	  year: $year
	  id: $id
	) {
	  id
	}
  }
`;

const DELETE_NOTE = gql`
  mutation DeleteNote($id: ID!) {
	deleteNote(id: $id)
  }
`;

const SHARE_NOTE = gql`
  mutation DeleteNote($note: ID!, $person: ID!) {
	shareNote(noteId: $note, personId: $person)
  }
`;

const UNSHARE_NOTE = gql`
  mutation UnshareNote($note: ID!, $person: ID!) {
	unshareNote(noteId: $note, personId: $person)
  }
`;

const TimelinePage: React.FC = () => {
	const { loading, error, data, refetch } = useQuery<TimelineQuery>(
		GET_TIMELINE
	);

	const [setShowTimelineIntroFalse] = useMutation(SET_SHOW_TIMELINE_INTRO_FALSE);
	const [introShown, setIntroShown] = useState<boolean>(false);
	const history = useHistory();

	useEffect(() => {
		if (data) {
			console.log("=====timeline data is data.me.person.id =======",data.me.person.id)
			let person_id:any=0
			person_id = data.me.person.id
			localStorage.setItem("person_id",person_id)
    
			if(localStorage.getItem("survialAllAges") !== null){
			   fetch(process.env.REACT_APP_AUTH0_AUDIENCE+'update-life-expectancy-data-and-get-data?id='+localStorage.getItem("person_id")+'&lifeexpectancy='+localStorage.getItem("expectancy")+'&healthage='+localStorage.getItem("healthAge")+'&survialallages='+localStorage.getItem('survialAllAges')+'&update=yes')
			   .then(response => response.json())
			   .then((response) => {
				 
				 console.log(response)})
			   .catch((error) => {console.log(error)}) 
			 }
			 else{
			   fetch(process.env.REACT_APP_AUTH0_AUDIENCE+'update-life-expectancy-data-and-get-data?id='+localStorage.getItem("person_id")+'&update=no')
			   .then(response => response.json())
			   .then((response) => {
				 if(response.message_id === 2){
		           if(response.lifeexpectancy !== null){
					localStorage.setItem("expectancy",response.lifeexpectancy)
				   }
				   if(response.healthage !== null){
					localStorage.setItem("healthAge",response.healthage)
				   }
				   if(response.survialallages !== null){
					localStorage.setItem('survialAllAges',response.survialallages)
				   }
				   
				 }
				 console.log(response)})
			   .catch((error) => {console.log(error)})
			 }



			if (data.featureFlags.showConfirmPersonInfo)
				history.replace("/confirm-info");
			if (data.featureFlags.showNewMemberOnboarding)
				history.replace("/invite-onboarding");
		}
	}, [data, history]);

	const onIntroPageClose = (): void => {
		setShowTimelineIntroFalse({
			refetchQueries: [
				{
					query: gql`
			query {
			  featureFlags {
				...featureFlagsFields
			  }
			}

			${featureFlagsFragment}
		  `,
				},
			],
		});
		setIntroShown(true);
	};

	const [addNoteMutation] = useMutation(ADD_NOTE);

	const addNote = async (noteVals: NoteFormValues): Promise<void> => {
		analyticsAddNote(noteVals);
		await addNoteMutation({
			variables: {
				description: noteVals.description || "",
				groupId: noteVals.membershipId === "" ? data?.family.id : null,
				membershipId: noteVals.membershipId || null,
				type: noteVals.type,
				year: noteVals.year,
			},
			refetchQueries: [
				{
					query: GET_TIMELINE,
				},
			],
		});
	};

	const [editNoteMutation] = useMutation(EDIT_NOTE);

	const editNote = async (
		noteVals: NoteFormValues,
		id: number
	): Promise<void> => {
		analyticsEditNote(noteVals);

		const refetchQueries = [];

		// if this is a retirement note, refetch the graph info
		if (
	data?.family.profiles
		.find(profile => profile.membership.id === noteVals.membershipId)
		?.timeline.notes.find(note => note.id === id)?.calculator ===
	CalculatorType.Retirement
		) {
			refetchQueries.push({
				query: RETIREMENT_GRAPH_REFETCH,
			});
		}

		await editNoteMutation({
			variables: {
				description: noteVals.description || "",
				groupId: noteVals.membershipId === "" ? data?.family.id : null,
				membershipId: noteVals.membershipId || null,
				type: noteVals.type,
				year: noteVals.year,
				id: id,
			},
			refetchQueries: refetchQueries,
		});
		await refetch();
	};

	const [deleteNoteMutation] = useMutation(DELETE_NOTE);

	const deleteNote = async (note: Note): Promise<void> => {
		analyticsDeleteNote(note);
		await deleteNoteMutation({
			variables: {
				id: note.id,
			},
		});
		await refetch();
	};

	const [shareNoteMutation] = useMutation(SHARE_NOTE);

	const shareNote = async (note: Note, personId: number): Promise<void> => {
		analyticsShareNote(note);
		await shareNoteMutation({
			variables: {
				note: note.id,
				person: personId,
			},
		});
		await refetch();
	};

	const [unshareNoteMutation] = useMutation(UNSHARE_NOTE);

	const unshareNote = async (note: Note, personId: number): Promise<void> => {
		analyticsUnShareNote(note);
		await unshareNoteMutation({
			variables: {
				note: note.id,
				person: personId,
			},
		});
		await refetch();
	};

	if (loading) return <LoadingSpinner />;

	if (error) {
		return <div />;
	}

	if (data) {
		if (data.featureFlags.showTimelineIntro && !introShown) {
			return <TimelineIntroPage onClose={onIntroPageClose} />;
		} else {
			return (
				<TimelinePageInner
					data={data}
					addNote={addNote}
					editNote={editNote}
					deleteNote={deleteNote}
					shareNote={shareNote}
					unshareNote={unshareNote}
				/>
			);
		}
	}

	return <div />;
};

interface TimelinePageInnerProps {
  data: TimelineQuery
  addNote: (noteVals: NoteFormValues) => Promise<void>
  editNote: (noteVals: NoteFormValues, id: number) => Promise<void>
  deleteNote: (note: Note) => Promise<void>
  shareNote: (note: Note, personId: number) => Promise<void>
  unshareNote: (note: Note, personId: number) => Promise<void>
}

export type RateType = "fertility" | "mobility" | "happiness"

const HEALTH_NOTES: {[key in RateType]: { header: string, bodyRows: Array<string>}} = {
	fertility: {
		header: "Fertility benchmark",
		bodyRows: ["Male and Female", "Age-specific fertility rates", "Fertility rate per 1000  (WHO)"],
	},
	mobility: {
		header: "Mobility benchmark",
		bodyRows: ["Function of body systems", " (% peak function by age)", "Exercise and diet", "    - Maintain health", "    - Recover mild deficits", "    - Improve mobility"],
	},
	happiness: {
		header: "Happiness benchmark",
		bodyRows: ["Well being tends to follow a U shaped curve", "Overall life satisfaction", "Self reported (1-10)", "Male and Female by age"],
	},
};


export const TimelinePageInner: React.FC<TimelinePageInnerProps> = (
	props: TimelinePageInnerProps
) => {
	const [currentNote, setCurrentNote] = useState<number | undefined>(undefined);
	const [healthNoteShow, setHealthNoteShow] = useState(false);
	const [selectedRate, setSelectedRate] = useState<RateType>("fertility");

	const [headerItem, setHeaderItem] = useState<HeaderItem>(HeaderItem.Timeline);

	useEffect(() => {
		window.gtag("event", `timeline_${headerItem}_graph_view`);
	}, [headerItem]);

	useEffect(() => {
		window.gtag("event", `timeline_health_${selectedRate}_graph_view`);
	}, [selectedRate]);

  
	const getNote = (): Note | undefined => {
		return [
			...props.data.family.notes,
			...props.data.family.profiles.flatMap(profile => profile.timeline.notes),
		].find(n => n.id === currentNote);
	};

	const shiftNote = (direction: "forward" | "back"): void => {
		const note = getNote();

		if (!note) {
			return;
		}
		// select the right list of notes to scroll through
		const notes = note.membership
			? props.data.family.profiles.find(
				profile => profile.membership.id === note.membership?.id
			)?.timeline.notes
			: props.data.family.notes;
		const idx = notes?.findIndex(n => n.id === note.id);
		if (notes === undefined || idx === undefined) {
			// this means we've failed to locate the current note in our data, should never happen
			return;
		}
		if (direction === "forward" && idx < notes?.length - 1) {
			analyticsViewNote(notes[idx + 1]);
			setCurrentNote(notes[idx + 1].id);
		}
		if (direction === "back" && idx > 0) {
			analyticsViewNote(notes[idx - 1]);
			setCurrentNote(notes[idx - 1].id);
		}
	};

	const [showNoteEditModal, setShowNoteEditModal] = useState<boolean>(false);
	const toggleNoteEditModal = (): void => {
		setShowNoteEditModal(!showNoteEditModal);
	};

	const [showNoteViewModal, setShowNoteViewModal] = useState<boolean>(false);
	const toggleNoteViewModal = (): void => {
		setShowNoteViewModal(!showNoteViewModal);
	};

	const [showNoteShareModal, setShowNoteShareModal] = useState<boolean>(false);
	const toggleNoteShareModal = (): void => {
		setShowNoteShareModal(!showNoteShareModal);
	};

	const openEditNoteModal = (note: Note): void => {
		setCurrentNote(note.id);
		setShowNoteEditModal(true);
		setShowNoteViewModal(false);
		setShowNoteShareModal(false);
	};

	const openViewNoteModal = (note: Note): void => {
		analyticsViewNote(note);
		setCurrentNote(note.id);
		setShowNoteEditModal(false);
		setShowNoteViewModal(true);
		setShowNoteShareModal(false);
	};

	const openShareNoteModal = (note: Note): void => {
		setCurrentNote(note.id);
		setShowNoteEditModal(false);
		setShowNoteViewModal(false);
		setShowNoteShareModal(true);
	};

	const ref = useRef<HTMLDivElement>(document.createElement("div"));

	const start = Math.min(
		...props.data.family.profiles.map(profile => profile.timeline.startYear)
	);
	const end = Math.max(
		...props.data.family.profiles.map(profile => profile.timeline.endYear)
	);

	const moveTimelineScroll = (x: number): void => {
		ref.current.scrollBy(x, 0);
	};

	const [yearScrolled, setYearScrolled] = useState<boolean>(false);
	useEffect(() => {
		if (ref.current && props.data && !yearScrolled) {
			const start = Math.min(
				...props.data.family.profiles.map(profile => profile.timeline.startYear)
			);
			// round the start year down since the timeline starts at the lowest multiple of 5
			const startRounded = start - (start % 5);
			const currentYear = new Date().getFullYear();
			moveTimelineScroll(AxisTranslator.year(currentYear - startRounded, false));
			setYearScrolled(true);
		}
	}, [ref, props.data, yearScrolled]);

	const profiles = [
		...props.data.family.profiles.filter(
			profile => profile.membership.role === Role.Primary
		),
		...props.data.family.profiles.filter(
			profile => profile.membership.role === Role.Partner
		),
		...props.data.family.profiles
			.filter(profile => profile.membership.role === Role.Child)
			.sort((a, b) => a.timeline.startYear - b.timeline.startYear),
		...props.data.family.profiles
			.filter(profile => profile.membership.role === Role.Member)
			.sort((a, b) => a.timeline.startYear - b.timeline.startYear),
	];

	const note = getNote();
	const [pointedYear0, setPointedYear0] = useState<string>('');
	const printUrl = (arg: string) => {
    setPointedYear0(arg);
}

	return (
		<div className="root">
			<NavbarTimeline pointedYear3={pointedYear0} selected={headerItem} setSelectedItem={setHeaderItem} />
			<div className="timeline-main-vertical">
				<TimelineRuler
				    pointerYear2={printUrl}
					start={start}
					end={end}
					notes={props.data.family.notes}
					targetRef={ref}
					onViewNote={openViewNoteModal}
				/>
				<div className="timeline-main-horizontal">
					<LeftNav
						profiles={profiles}
						targetRef={ref}
						onAddNote={(): void => {
							setCurrentNote(undefined);
							toggleNoteEditModal();
						}}
					/>
					<div className="timeline-view-scroll" ref={ref}>
						{headerItem === HeaderItem.Rates && (
							<RateInfoGraph
								show
								startYear={start}
								endYear={end}
								primary={
									props.data.family.profiles.find(
										profile => profile.membership.role === Role.Primary
									)?.membership.person
								}
								partner={
									props.data.family.profiles.find(
										profile => profile.membership.role === Role.Partner
									)?.membership.person
								}
								targetRef={ref}
								selectedRate={selectedRate}
								onRateSelect={setSelectedRate}
							/>
						)}
						{headerItem === HeaderItem.World && (
							<EventsGraph
								show
								startYear={start}
								endYear={end}
								targetRef={ref}
							/>
						)}
						{headerItem === HeaderItem.Finance && (
							<FinancesGraph
								show
								startYear={start}
								endYear={end}
								targetRef={ref}
							/>
						)}
						<Timeline
							profiles={profiles}
							start={start}
							end={end}
							onViewNote={openViewNoteModal}
						/>
					</div>
				</div>
			</div>
			<Minimap
				selectedHeaderItem={headerItem}
				onInfoClick={setHealthNoteShow}
				targetRef={ref}
				moveTarget={moveTimelineScroll}
				profiles={profiles}
			/>
			{note && (
				<NoteViewModal
					note={note}
					family={props.data.family}
					isOpen={showNoteViewModal}
					toggle={toggleNoteViewModal}
					editNote={openEditNoteModal}
					shiftNote={shiftNote}
					hideNote={async () => {
						await props.unshareNote(note, props.data.me.person.id);
						setCurrentNote(undefined);
					}}
					shareNote={openShareNoteModal}
				/>
			)}
			{note && (
				<NoteShareModal
					note={note}
					isOpen={showNoteShareModal}
					family={props.data.family}
					toggle={toggleNoteShareModal}
					share={(personId: number) => props.shareNote(note, personId)}
					unshare={(personId: number) => props.unshareNote(note, personId)}
				/>
			)} 
			<Modal
				className="health-modal"
				isOpen={healthNoteShow && headerItem === HeaderItem.Rates}
				toggle={() => setHealthNoteShow(!healthNoteShow)}
				centered>
				<ModalHeader>
					{HEALTH_NOTES[selectedRate].header}
				</ModalHeader>
				<ModalBody>
					{HEALTH_NOTES[selectedRate].bodyRows.map((r, i)=> <p key={i}>{r}</p>)}
				</ModalBody>
			</Modal>
			<NoteEditModal
				note={note}
				memberships={
					props.data.family.profiles.map(profile => profile.membership) || []
				}
				isOpen={showNoteEditModal && !healthNoteShow}
				toggle={toggleNoteEditModal}
				addNote={async (values: NoteFormValues): Promise<void> => {
					await props.addNote(values);
					setShowNoteEditModal(false);
				}}
				editNote={async (values: NoteFormValues, id: number): Promise<void> => {
					await props.editNote(values, id);
					setShowNoteEditModal(false);
				}}
				deleteNote={async (note: Note): Promise<void> => {
					await props.deleteNote(note);
					setShowNoteEditModal(false);
				}}
				noteYearMin={start}
				noteYearMax={end}
			/>
		</div>
	);
};

export default TimelinePage;
