import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../styles.scss';
import { Button, Container, Input } from 'reactstrap';

import * as quizTypes from '../reducer';
import * as yup from 'yup';
import SelectItemView from './SelectItemView';
import { showLifeExpectancySmoking} from './LifeExpectancy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface Props {
  formerHeavyYear?: quizTypes.FormerHeavySmoker;
  setFormerHeavyYear: (s: quizTypes.SetFormerHeavyYear) => void;
}
const FormerHeavySmoker: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { formerHeavyYear, setFormerHeavyYear } = props;
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const history = useHistory();
  const handleSubmit = () => {
    if (formerHeavyYear) {
       setButtonDisabled(true)
     if(formerHeavyYear === 'LESS_THAN_YEAR_AGO'){
      //let result = showLifeExpectancySmoking("USED_TO_MORE_PACK_DAILY",formerHeavyYear)
      showLifeExpectancySmoking("USED_TO_MORE_PACK_DAILY",formerHeavyYear)
      // toast.info(`Predicted Life Expectancy: ${result}`, {
      //   position: 'top-right',
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   onClose: () => {history.push('/alcohol');},
      // });
      history.push('/alcohol');
     }
     if(formerHeavyYear === 'USED_TO_LESS_PACK_DAILY'){
      //let result = showLifeExpectancySmoking("USED_TO_MORE_PACK_DAILY",formerHeavyYear)
      showLifeExpectancySmoking("USED_TO_MORE_PACK_DAILY",formerHeavyYear)
      // toast.info(`Predicted Life Expectancy: ${result}`, {
      //   position: 'top-right',
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   onClose: () => {history.push('/alcohol');},
      // });
      history.push('/alcohol');
     }
     if(formerHeavyYear === 'CURRENT_LESS_PACK_DAILY'){
      //let result = showLifeExpectancySmoking("USED_TO_MORE_PACK_DAILY",formerHeavyYear)
      // toast.info(`Predicted Life Expectancy: ${result}`, {
      //   position: 'top-right',
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   onClose: () => {history.push('/alcohol');},
      // });
      showLifeExpectancySmoking("USED_TO_MORE_PACK_DAILY",formerHeavyYear)
      history.push('/alcohol');
     }
     if(formerHeavyYear === 'CURRENT_MORE_PACK_DAILY'){
      //let result = showLifeExpectancySmoking("USED_TO_MORE_PACK_DAILY",formerHeavyYear)
      showLifeExpectancySmoking("USED_TO_MORE_PACK_DAILY",formerHeavyYear)
      // toast.info(`Predicted Life Expectancy: ${result}`, {
      //   position: 'top-right',
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   onClose: () => {history.push('/alcohol');},
      // });
      history.push('/alcohol');
     }

      // {
      //   formerHeavyYear === 'LESS_THAN_YEAR_AGO' && history.push('/alcohol');
      // }
      // {
      //   formerHeavyYear === 'USED_TO_LESS_PACK_DAILY' &&
      //     history.push('/alcohol');
      // }
      // {
      //   formerHeavyYear === 'CURRENT_LESS_PACK_DAILY' &&
      //     history.push('/alcohol');
      // }
      // {
      //   formerHeavyYear === 'CURRENT_MORE_PACK_DAILY' &&
      //     history.push('/alcohol');
      // }
    } else {
      setShowError(true);
    }
  };

  const options = [
    {
      label: t(
        'features.calculator.lifeExpectancy.FormerHeavySmoker.less_than_year_ago'
      ),
      value: quizTypes.FormerHeavySmoker.less_than_year_ago,
    },
    {
      label: t(
        'features.calculator.lifeExpectancy.FormerHeavySmoker.one_year_to_less_than_two_year_ago'
      ),
      value: quizTypes.FormerHeavySmoker.one_year_to_less_than_two_year_ago,
    },
    {
      label: t(
        'features.calculator.lifeExpectancy.FormerHeavySmoker.two_year_to_less_than_three_year_ago'
      ),
      value: quizTypes.FormerHeavySmoker.two_year_to_less_than_three_year_ago,
    },
    {
      label: t(
        'features.calculator.lifeExpectancy.FormerHeavySmoker.three_or_more_year_ago'
      ),
      value: quizTypes.FormerHeavySmoker.three_or_more_year_ago,
    },
  ];

  const validationSchema = yup.object().shape({
    dailySmoking: yup
      .number()
      .required(
        t('features.calculator.lifeExpectancy.FormerHeavySmoker.errors.empty')
      )
      .integer(
        t(
          'features.calculator.lifeExpectancy.FormerHeavySmoker.errors.nonInteger'
        )
      )
      .min(
        0,
        t(
          'features.calculator.lifeExpectancy.FormerHeavySmoker.errors.negative'
        )
      ),
  });

  return (
    <Container className='content'>
      <h1 className='display-4 text-center title'>
        {t('features.calculator.lifeExpectancy.FormerHeavySmoker.title')}
      </h1>
      <div className='mb-2'>
        {options.map((option, index) => (
          <SelectItemView
            key={index}
            label={option.label}
            selected={formerHeavyYear === option.value}
            onSelect={(): void =>
              setFormerHeavyYear({
                type: 'set_former_heavy_year',
                formerHeavyYearCount: option.value,
              })
            }
          />
        ))}
      </div>
      {showError && (
        <div className='form-error'>
          {t(
            'features.calculator.lifeExpectancy.FormerHeavySmoker.errors.empty'
          )}
        </div>
      )}
      <hr />
      <Button disabled={buttonDisabled} color='primary' block size='lg' onClick={handleSubmit}>
        {t('features.getstarted.next')}
      </Button>
      <Button
        outline
        color='link'
        onClick={() => {
          history.goBack();
        }}
        className='w-100'
      >
        {t('features.calculator.lifeExpectancy.FormerHeavySmoker.confirmBack')}
      </Button>
      <ToastContainer />
    </Container>
  );
};

export default FormerHeavySmoker;
