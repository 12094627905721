import React, {
	useRef,
	useEffect,
	useState,
	useCallback,
	MutableRefObject,
} from "react";

import "./Graph.scss";
import AxisTranslator, { LeftYearMargin } from "shared/utils/axis";
import { getToolTip } from "../../shared/views/toolTip/getToolTip";
import { Sex } from "shared/types";

export enum GraphMarkerShape {
  Diamond = "diamond",
  Circle = "circle",
}

export interface DataPoint {
  x: number
  y: number
  value: React.ReactNode
}

export interface DataSeries {
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  name?: string
  sex?: Sex
  showPoppers: boolean
  markerShape: GraphMarkerShape
  data: DataPoint[]
}

interface GraphSeriesMarkerProps {
  sex?: Sex
  markerShape: GraphMarkerShape
  position: DataPoint
  onClick: () => void
}

export const GRAPH_MARKER_DIAMOND_WIDTH = 38;
const GRAPH_MARKER_DIAMOND_RADIUS = 2;
const GRAPH_MARKER_CIRCLE_WIDTH = 42;

export const GraphSeriesMarker: React.FC<GraphSeriesMarkerProps> = (
	props: GraphSeriesMarkerProps
) => {
	switch (props.markerShape) {
	case GraphMarkerShape.Diamond:
		return (
			<>
				<rect
					className={`graph-series-marker ${
						props.sex === Sex.Female ? "dark-fill" : ""
					}`}
					transform={`rotate(45, ${props.position.x} ${props.position.y})`}
					x={props.position.x - GRAPH_MARKER_DIAMOND_WIDTH / Math.sqrt(2) / 2}
					y={props.position.y - GRAPH_MARKER_DIAMOND_WIDTH / Math.sqrt(2) / 2}
					rx={GRAPH_MARKER_DIAMOND_RADIUS}
					width={GRAPH_MARKER_DIAMOND_WIDTH / Math.sqrt(2)}
					height={GRAPH_MARKER_DIAMOND_WIDTH / Math.sqrt(2)}
					onClick={props.onClick}
				></rect>
			</>
		);
	case GraphMarkerShape.Circle:
		return (
			<circle
				className={`graph-series-marker ${
					props.sex === Sex.Female ? "dark-fill" : ""
				}`}
				cx={props.position.x}
				cy={props.position.y}
				r={GRAPH_MARKER_CIRCLE_WIDTH / 2}
			></circle>
		);
	}
};

interface GraphSeriesIconProps {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  markerShape: GraphMarkerShape
  position: DataPoint
}

const GRAPH_ICON_WIDTH = 22;

export const GraphSeriesIcon: React.FC<GraphSeriesIconProps> = (
	props: GraphSeriesIconProps
) => {
	return (
		<props.icon
			className="graph-series-icon"
			x={props.position.x - GRAPH_ICON_WIDTH / 2}
			y={props.position.y - GRAPH_ICON_WIDTH / 2}
			width={GRAPH_ICON_WIDTH}
			height={GRAPH_ICON_WIDTH}
		></props.icon>
	);
};

interface GraphSeriesNameProps {
  name: string
  position: DataPoint
  displayPopper?: boolean
}


const GRAPH_NAME_TEXT_SIZE = 10;
const POPPER_X_OFFSET = -30;

const GraphSeriesName: React.FC<GraphSeriesNameProps> = (
	props: GraphSeriesNameProps
) => {
	return props.displayPopper ? (
		<foreignObject
			x={props.position.x + POPPER_X_OFFSET}
			y={props.position.y}
			className="events-popper"
		>
			<div className="aligner">
				<div className="bubble">
					<span>{props.name}</span>
				</div>
			</div>
		</foreignObject>
	) : (
		<text
			className="graph-series-text"
			x={props.position.x}
			y={props.position.y}
			textAnchor="middle"
			fontSize={GRAPH_NAME_TEXT_SIZE}
			dominantBaseline="middle"
		>
			{props.name}
		</text>
	);
};

interface GraphIntermediateMarkerProps {
  sex?: Sex
  position: DataPoint
}

const GRAPH_INTERMEDIATE_MARKER_WIDTH = 16;

const GraphIntermediateMarker: React.FC<GraphIntermediateMarkerProps> = (
	props: GraphIntermediateMarkerProps
) => {
	const { ToolTip, toolTipId } = getToolTip(props.position);

	return (
		<>
			<ToolTip />
			<rect
				id={toolTipId}
				className={`graph-series-marker ${
					props.sex === Sex.Female ? "dark-fill" : ""
				}`}
				x={props.position.x - GRAPH_INTERMEDIATE_MARKER_WIDTH / 2}
				y={props.position.y - GRAPH_INTERMEDIATE_MARKER_WIDTH / 2}
				width={GRAPH_INTERMEDIATE_MARKER_WIDTH}
				height={GRAPH_INTERMEDIATE_MARKER_WIDTH}
			></rect>
		</>
	);
};

export interface GraphProps {
  show: boolean
  data: DataSeries[]
  startYear: number
  endYear: number
  targetRef: MutableRefObject<HTMLDivElement>
  isEvents?: boolean
  onMarkerClick?: (idx: number) => void
}

const pointsToPathStr = (svgPoints: DataPoint[]): string => {
	const firstPt = svgPoints[0];
	const pathStr = [`M ${firstPt.x} ${firstPt.y}`];
	svgPoints.slice(1).forEach((pt) => {
		pathStr.push(`L ${pt.x} ${pt.y}`);
	});
	return pathStr.join(" ");
};

const Graph: React.FC<GraphProps> = (props: GraphProps) => {
	const svgRef = useRef<SVGSVGElement | null>(null);

	const [svgHeight, setSvgHeight] = useState<number>(0);

	const start = props.startYear - (props.startYear % 5);
	const end = props.endYear + (props.endYear % 5);
	const rightMargin = AxisTranslator.year(5 - (end % 5), false) + 50;

	const refHeight = svgRef.current?.clientHeight;
	useEffect(() => {
		if (svgRef.current) {
			setSvgHeight(svgRef.current.clientHeight);
		}
	}, [refHeight]);

	const [vScrollPos, setVScrollPos] = useState<number>(0);

	const updatePos = useCallback(
		(event: any) => {
			setVScrollPos(event.target.scrollTop);
		},
		[setVScrollPos]
	);

	useEffect(() => {
		const current = props.targetRef.current || document.createElement("div");
		current.addEventListener("scroll", updatePos);
		return () => {
			current.removeEventListener("scroll", updatePos);
		};
	}, [props.targetRef, updatePos]);

	if (!props.show) {
		return null;
	}

	// To prevent flashing a broken graph before the SVG has finished determining height
	// we only render the body of the graph when svgHeight !== 0
	return (
		<svg
			ref={svgRef}
			style={{ top: `${vScrollPos}px` }}
			className="graph-svg"
			width={
				AxisTranslator.year(end) -
        AxisTranslator.year(start) +
        LeftYearMargin +
        rightMargin
			}
		>
			{svgHeight !== 0 && (
				<GraphBody
					data={props.data}
					startYear={start}
					endYear={end}
					svgHeight={svgHeight}
					isEvents={!!props.isEvents}
					onMarkerClick={props.onMarkerClick}
				/>
			)}
		</svg>
	);
};

interface GraphBodyProps {
  data: DataSeries[]
  startYear: number
  endYear: number
  svgHeight: number
  isEvents?: boolean
  onMarkerClick?: (idx: number) => void
}

const GRAPH_TOP_PADDING = 50;
const INTERMEDIATE_POINT_SPACING = 5;

export const GraphBody = (props: GraphBodyProps) => {
	const yValues = props.data
		.map(series => series.data.map(pt => pt.y))
		.reduce((a, b) => a.concat(b), []);

	const yMin = props.isEvents ? 1 : Math.min(...yValues);
	const yMax = props.isEvents ? 5 : Math.max(...yValues);
	const range = yMax - yMin;

	const dataPointToPixelPoint = (point: DataPoint): DataPoint => {
		if (props.isEvents) {
			const svgX = AxisTranslator.year(point.x - props.startYear, true);
			const svgY = AxisTranslator.user(point.y - 1);
			return { x: svgX, y: svgY, value: point.value };
		} else {
			const topMargin = GRAPH_TOP_PADDING;
			const height = Math.min(
				AxisTranslator.user(4) - GRAPH_TOP_PADDING,
				props.svgHeight
			);

			const graphY = (point.y - yMin) * ((height - topMargin) / range);
			const svgX = AxisTranslator.year(point.x - props.startYear, true);
			const svgY = height - graphY;
			return { x: svgX, y: svgY, value: point.value };
		}
	};

	return (
		<>
			{!props.isEvents && (
				<>
					<g>
						{props.data.map((series, idx) => {
							const svgPoints = series.data.map(dataPointToPixelPoint);

							if (!svgPoints[0]) {
								return null;
							}

							const pathStr = pointsToPathStr(svgPoints);

							return (
								<path
									key={idx}
									d={pathStr}
									className={`graph-line ${
										series.sex === Sex.Female ? "dark-stroke" : ""
									}`}
								/>
							);
						})}
					</g>
					<g>
						{props.data.map(series => {
							// start counting points where we add the series marker
							const intermediatePoints = [series.data[1]];
							for (let i = 1; i < series.data.length; i++) {
								const pt = series.data[i];
								if (
									pt.x - intermediatePoints[intermediatePoints.length - 1].x >=
                  INTERMEDIATE_POINT_SPACING
								) {
									intermediatePoints.push(pt);
								}
							}
							// remove the first point since it overlaps the series marker
							return intermediatePoints
								.slice(1)
								.map(pt => (
									<GraphIntermediateMarker
										key={pt.x}
										sex={series.sex}
										position={dataPointToPixelPoint(pt)}
									/>
								));
						})}
					</g>
				</>
			)}
			<g>
				{props.data.map((series, idx) => {
					if (!series.data) return null;
					const seriesMarkerDataPoint = series.data.length > 1 ? series.data[1] : series.data[0];

					return (
						seriesMarkerDataPoint && (
							<React.Fragment key={idx}>
								<GraphSeriesMarker
									sex={series.sex}
									position={dataPointToPixelPoint(seriesMarkerDataPoint)}
									markerShape={series.markerShape}
									onClick={() => {
										if (props.onMarkerClick) props.onMarkerClick(idx);
										else return;
									}}
								/>
								{series.icon && (
									<GraphSeriesIcon
										icon={series.icon}
										markerShape={series.markerShape}
										position={dataPointToPixelPoint(seriesMarkerDataPoint)}
									/>
								)}
								{series.name && (
									<GraphSeriesName
										name={series.name}
										position={dataPointToPixelPoint(seriesMarkerDataPoint)}
										displayPopper={series.showPoppers}
									/>
								)}
							</React.Fragment>
						)
					);
				})}
			</g>
		</>
	);
};

export default Graph;
