import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles.scss';
import { Button, Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import * as quizTypes from '../reducer';
import SelectItemView from './SelectItemView';
import { showLifeExpectancyEducation} from './LifeExpectancy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface Props {
  education?: quizTypes.EducationLevel;
  setEducation: (s: quizTypes.SetEducation) => void;
}

const Education: React.FC<Props> = ({ education, setEducation }: Props) => {
  const { t } = useTranslation();

  const [showError, setShowError] = useState<boolean>(false);
  const history = useHistory();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const handleSubmit = () => {
    if (education) {
      setButtonDisabled(true)
      //let result = showLifeExpectancyEducation(education)
      // toast.info(`Predicted Life Expectancy: ${result}`, {
      //   position: 'top-right',
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   onClose: () => { history.push('/fruits-vegetables');},
      // });
       showLifeExpectancyEducation(education)
       history.push('/fruits-vegetables');
    } else {
      setShowError(true);
    }
  };

  const options = [
    {
      label: t(
        'features.calculator.lifeExpectancy.education.post_secondary_school_graduate'
      ),
      value: quizTypes.EducationLevel.post_secondary_school_graduate,
    },
    {
      label: t(
        'features.calculator.lifeExpectancy.education.high_school_graduate'
      ),
      value: quizTypes.EducationLevel.high_school_graduate,
    },
    {
      label: t(
        'features.calculator.lifeExpectancy.education.less_than_high_school_education'
      ),
      value: quizTypes.EducationLevel.less_than_high_school_education,
    },
  ];

  return (
    <Container className='content'>
      <h1 className='display-4 text-center title'>
        {t('features.calculator.lifeExpectancy.education.title')}
      </h1>
      <div className='mb-2'>
        {options.map((option, index) => (
          <SelectItemView
            key={index}
            label={option.label}
            selected={education === option.value}
            onSelect={(): void =>
              setEducation({
                type: 'set_education',
                education: option.value,
              })
            }
          />
        ))}
      </div>
      {showError && (
        <div className='form-error'>
          {t('features.calculator.lifeExpectancy.education.errors.empty')}
        </div>
      )}
      <hr />
      <Button disabled={buttonDisabled} color='primary' block size='lg' onClick={handleSubmit}>
        {t('features.getstarted.next')}
      </Button>
      <Button
        outline
        color='link'
        onClick={() => {
          history.goBack();
        }}
        className='w-100'
      >
        {t('features.calculator.lifeExpectancy.education.confirmBack')}
      </Button>
      <ToastContainer />
    </Container>
  );
};

export default Education;
