import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useAuth0 } from "./Provider";
import LoadingSpinner from "../spinner/LoadingSpinner";

const PrivateRoute: React.FC<RouteProps> = ({
	children,
	...rest
}: RouteProps) => {
	const { isAuthenticated, loading } = useAuth0();
  
	return (
		<Route {...rest}>
			{loading && <LoadingSpinner />}
			{!loading && (
				<>
					{isAuthenticated && children}
					{!isAuthenticated && <Redirect to="/" />}
				</>
			)}
		</Route>
	);
};

export default PrivateRoute;
