import React from "react";
import * as types from "../../shared/types";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Spinner } from "reactstrap";
import { useParams } from "react-router-dom";
import LoadingSpinner from "shared/views/spinner/LoadingSpinner";
import ViewInvitation from "./views/ViewInvitation";
import { personFragment } from "shared/queries";

interface InviteInfoQuery {
  inviteInfo: {
    inviter: types.Person
    showRemoveGroupConfirm: boolean
  }
}

export const INVITE_QUERY = gql`
  query($token: String!) {
    inviteInfo(token: $token) {
      showRemoveGroupConfirm
      inviter {
        ...personFields
      }
    }
  }

  ${personFragment}
`;

export const ACCEPT_INVITE = gql`
  mutation($token: String!, $accept: Boolean!, $redirectUri: String!) {
    respondInvite(token: $token, accept: $accept, redirectUri: $redirectUri)
  }
`;

const Invitation: React.FC = () => {
	const { token } = useParams();

	const { loading, error, data } = useQuery<InviteInfoQuery>(INVITE_QUERY, {
		variables: {
			token: token,
		},
	});
	const [mutate, { loading: mutateLoading }] = useMutation(ACCEPT_INVITE);

	if (loading)
		return (
			<div className="spinner-container">
				<Spinner size="lg" />
			</div>
		);

	if (error) {
		return <div />;
	}

	if (data) {
		return (
			<>
				{mutateLoading && <LoadingSpinner overlay={true} />}
				<ViewInvitation
					inviter={data.inviteInfo.inviter}
					hasTimeline={data.inviteInfo.showRemoveGroupConfirm}
					respond={async (accept: boolean) => {
						await mutate({
							variables: {
								token: token,
								accept: accept,
								redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
							},
						});
						return;
					}}
				/>
			</>
		);
	}
	return <div />;
};

export default Invitation;
