import React, { useEffect ,useState} from 'react';
import { useTranslation } from 'react-i18next';
import '../styles.scss';
import {
  Button,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Col,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import {
  Formik,
  FormikProps,
  Form,
  Field,
  FieldProps,
  FieldInputProps,
} from 'formik';
import * as yup from 'yup';
import { SetWeightHeight } from '../reducer';
import { showLifeExpectancyBMI, getLifeExpectancy } from './LifeExpectancy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface FormValues {
  heightCm: number | undefined;
  heightFt: number | undefined;
  heightIn: number | undefined;
  weightKg: number | undefined;
  weightLb: number | undefined;
}

const cmToFtIn = (cm: number) => {
  if (isNaN(Number(cm))) return [undefined, undefined];
  const totalInches = cm / 2.54;
  const ft = Math.floor(totalInches / 12);
  const inches = Math.round(totalInches % 12);
  if (inches === 12) {
    return [ft + 1, 0];
  } else {
    return [ft, inches];
  }
};

const ftInToCm = (ft: number, inch: number) => {
  if (isNaN(ft) && isNaN(inch)) return undefined;
  const feet = ft ? ft : 0;
  const inches = ft ? ft : 0;
  return (feet * 12 + inches) * 2.54;
};

const kgToLb = (kg: number) => (isNaN(Number(kg)) ? undefined : kg * 2.205);
const lbToKg = (lb: number) => (isNaN(Number(lb)) ? undefined : lb / 2.205);

const safeRound: (num: number | undefined) => number | string = (
  num: number | undefined
) => {
  return num ? Math.round(num) : '';
};

interface CustomFieldProps {
  name: string;
  placeholder: string;
  unit: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  form: FormikProps<FormValues>;
  field: FieldInputProps<string>;
}

const CustomField: React.FC<CustomFieldProps> = (props: CustomFieldProps) => {
  return (
    <InputGroup className='mb-2'>
      <Input
        name={props.name}
        placeholder={props.placeholder}
        bsSize='lg'
        type={'number'}
        step='any'
        className={`goal custom${
          props.form.getFieldMeta(props.name).error ? ' has-errors' : ''
        }`}
        onChange={props.onChange}
        value={props.field.value}
      />
      <InputGroupAddon
        addonType='append'
        className={`${
          props.form.getFieldMeta(props.name).error ? 'has-errors' : ''
        }`}
      >
        {props.unit}
      </InputGroupAddon>
    </InputGroup>
  );
};

interface Props {
  heightCm?: number;
  weightKg?: number;
  setWeightHeight: (s: SetWeightHeight) => void;
}

const WeightHeight: React.FC<Props> = ({
  heightCm,
  weightKg,
  setWeightHeight,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  useEffect(() => {
   // let result =  getLifeExpectancy();
    //getLifeExpectancy();
    // toast.info(`Predicted Life Expectancy: ${result}`, {
    //   position: 'top-right',
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });
  }, []);

  const handleSubmit = (values: FormValues) => {
    if (values.heightCm !== undefined && values.weightKg !== undefined) {
      setWeightHeight({
        type: 'set_weight_height',
        heightCm: values.heightCm,
        weightKg: values.weightKg,
      });
      setButtonDisabled(true)
      let array = [];
      // append new value to the array
      array.push(values.weightKg);
      array.push(values.heightCm);
      //let result = showLifeExpectancyBMI(array);
         showLifeExpectancyBMI(array);
      // toast.info(`Predicted Life Expectancy: ${result}`, {
      //   position: 'top-right',
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   onClose: () => {
      //     history.push('/physical-activity');
      //   },
      // });

       history.push('/physical-activity');
    }
  };

  const validationSchema = yup.object().shape({
    weightKg: yup
      .number()
      .required(
        t('features.calculator.lifeExpectancy.weightHeight.weight.errors.empty')
      )
      .positive(
        t(
          'features.calculator.lifeExpectancy.weightHeight.weight.errors.negative'
        )
      )
      .min(18, 'Number should greater than 18')
      .max(260, 'Number should less than 260'),
    weightLb: yup
      .number()
      .required(
        t('features.calculator.lifeExpectancy.weightHeight.weight.errors.empty')
      )
      .positive(
        t(
          'features.calculator.lifeExpectancy.weightHeight.weight.errors.negative'
        )
      )
      .min(40, 'Number should greater than 40')
      .max(575, 'Number should less than 575'),
    heightCm: yup
      .number()
      .required(
        t('features.calculator.lifeExpectancy.weightHeight.height.errors.empty')
      )
      .positive(
        t(
          'features.calculator.lifeExpectancy.weightHeight.height.errors.negative'
        )
      ),
    heightFt: yup
      .number()
      .required(
        t('features.calculator.lifeExpectancy.weightHeight.height.errors.empty')
      )
      .min(
        0,
        t(
          'features.calculator.lifeExpectancy.weightHeight.height.errors.negative'
        )
      ),
    heightIn: yup.number().when(['heightFt'], {
      is: null,
      then: yup
        .number()
        .required(
          t(
            'features.calculator.lifeExpectancy.weightHeight.height.errors.empty'
          )
        )
        .min(
          0,
          t(
            'features.calculator.lifeExpectancy.weightHeight.height.errors.negative'
          )
        ),
      otherwise: yup
        .number()
        .min(
          0,
          t(
            'features.calculator.lifeExpectancy.weightHeight.height.errors.negative'
          )
        ),
    }),
  });

  const [initialFt, initialIn] = heightCm
    ? cmToFtIn(heightCm)
    : [undefined, undefined];
  const initialLb = weightKg ? kgToLb(weightKg) : undefined;

  return (
    <Container className='content'>
      <h1 className='display-4 text-center title'>
        {t('features.calculator.lifeExpectancy.weightHeight.title')}
      </h1>
      <Formik
        initialValues={{
          heightCm: heightCm,
          weightKg: weightKg,
          heightFt: initialFt,
          heightIn: initialIn,
          weightLb: initialLb,
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
      >
        {({ errors }: FormikProps<FormValues>) => (
          <Form>
            <h6>
              {t(
                'features.calculator.lifeExpectancy.weightHeight.weight.label'
              )}
            </h6>
            <Row className='mb-3'>
              <Col sm={5}>
                <Field name='weightKg' type='number'>
                  {({ form, field }: FieldProps) => (
                    <CustomField
                      name='weightKg'
                      form={form}
                      placeholder={t(
                        'features.calculator.lifeExpectancy.weightHeight.weight.placeholderMetric'
                      )}
                      unit={t(
                        'features.calculator.lifeExpectancy.weightHeight.weight.kg'
                      )}
                      field={field}
                      onChange={(e) => {
                        form.handleChange(e);
                        form.setFieldValue(
                          'weightLb',
                          safeRound(kgToLb(e.target.valueAsNumber))
                        );
                      }}
                    />
                  )}
                </Field>
                {errors.weightKg && (
                  <div className='form-error'>{errors.weightKg}</div>
                )}
              </Col>
              <Col
                className='text-center d-flex align-items-center justify-content-center'
                sm={2}
              >
                <h3>
                  {t('features.calculator.lifeExpectancy.weightHeight.or')}
                </h3>
              </Col>
              <Col sm={5}>
                <Field name='weightLb' type='number'>
                  {({ form, field }: FieldProps) => (
                    <CustomField
                      name='weightLb'
                      form={form}
                      placeholder={t(
                        'features.calculator.lifeExpectancy.weightHeight.weight.placeholderImperial'
                      )}
                      unit={t(
                        'features.calculator.lifeExpectancy.weightHeight.weight.lb'
                      )}
                      field={field}
                      onChange={(e) => {
                        form.handleChange(e);
                        form.setFieldValue(
                          'weightKg',
                          safeRound(lbToKg(e.target.valueAsNumber))
                        );
                      }}
                    />
                  )}
                </Field>
                {errors.weightLb && (
                  <div className='form-error'>{errors.weightLb}</div>
                )}
              </Col>
            </Row>
            <h6>
              {t(
                'features.calculator.lifeExpectancy.weightHeight.height.label'
              )}
            </h6>
            <Row className='mb-3'>
              <Col sm={4}>
                <Field name='heightCm' type='number'>
                  {({ form, field }: FieldProps) => (
                    <CustomField
                      name='heightCm'
                      form={form}
                      placeholder={t(
                        'features.calculator.lifeExpectancy.weightHeight.height.placeholder'
                      )}
                      unit={t(
                        'features.calculator.lifeExpectancy.weightHeight.height.cm'
                      )}
                      field={field}
                      onChange={(e) => {
                        form.handleChange(e);
                        const [ft, inches] = cmToFtIn(e.target.valueAsNumber);
                        form.setFieldValue('heightFt', safeRound(ft));
                        form.setFieldValue('heightIn', safeRound(inches));
                      }}
                    />
                  )}
                </Field>
                {errors.heightCm && (
                  <div className='form-error'>{errors.heightCm}</div>
                )}
              </Col>
              <Col
                className='text-center d-flex align-items-center justify-content-center'
                sm={1}
              >
                <h3>
                  {t('features.calculator.lifeExpectancy.weightHeight.or')}
                </h3>
              </Col>
              <Col sm={4}>
                <Field name='heightFt' type='number'>
                  {({ form, field }: FieldProps) => (
                    <CustomField
                      name='heightFt'
                      form={form}
                      placeholder={t(
                        'features.calculator.lifeExpectancy.weightHeight.height.placeholder'
                      )}
                      unit={t(
                        'features.calculator.lifeExpectancy.weightHeight.height.ft'
                      )}
                      field={field}
                      onChange={(e) => {
                        form.handleChange(e);
                        const cm = ftInToCm(
                          e.target.valueAsNumber,
                          form.values.heightIn || 0
                        );
                        form.setFieldValue('heightCm', safeRound(cm));
                      }}
                    />
                  )}
                </Field>
                {errors.heightFt && (
                  <div className='form-error'>{errors.heightFt}</div>
                )}
              </Col>
              <Col sm={3} className='px-0'>
                <Field name='heightIn' type='number'>
                  {({ form, field }: FieldProps) => (
                    <CustomField
                      name='heightIn'
                      form={form}
                      placeholder={t(
                        'features.calculator.lifeExpectancy.weightHeight.height.placeholder'
                      )}
                      unit={t(
                        'features.calculator.lifeExpectancy.weightHeight.height.in'
                      )}
                      field={field}
                      onChange={(e) => {
                        form.handleChange(e);
                        const cm = ftInToCm(
                          form.values.heightFt || 0,
                          e.target.valueAsNumber
                        );
                        form.setFieldValue('heightCm', safeRound(cm));
                      }}
                    />
                  )}
                </Field>
                {errors.heightIn && (
                  <div className='form-error'>{errors.heightIn}</div>
                )}
              </Col>
            </Row>
            <Button disabled={buttonDisabled} type='submit' color='primary' block size='lg'>
              {t('features.getstarted.next')}
            </Button>
            <Button
              outline
              color='link'
              onClick={() => {
                history.goBack();
              }}
              className='w-100'
            >
              {t('features.calculator.lifeExpectancy.weightHeight.confirmBack')}
            </Button>
          </Form>
        )}
      </Formik>
      <ToastContainer />
    </Container>
  );
};

export default WeightHeight;
