import React from 'react'
import { Navbar, Nav, NavItem, NavbarText, NavLink } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import '../styles.scss'

interface Props {
  selectedIndex: number
}

const Header: React.FC<Props> = ({ selectedIndex = 0 }: Props) => {
  const { t } = useTranslation()
  if (selectedIndex === 5) {
    return (
      <Navbar color="primary" className="text-white justify-content-center">
        <Nav className="justify-content-center">
          <NavItem>
            <NavbarText>
              {t('features.getstarted.nav.addFamilyMember')}
            </NavbarText>
          </NavItem>
        </Nav>
      </Navbar>
    )
  }

  if (selectedIndex === 10) {
    return (
      <Navbar color="primary" className="text-white justify-content-center">
        <Nav className="justify-content-center">
          <NavItem>
            <NavbarText>{t('features.getstarted.nav.family')}</NavbarText>
          </NavItem>
        </Nav>
      </Navbar>
    )
  }

  if (selectedIndex === 11) {
    return (
      <Navbar color="primary" className="text-white justify-content-center">
        <Nav className="justify-content-center">
          <NavItem>
            <NavbarText>Email Login
              {/* {t('features.getstarted.nav.createAccount')} */}
            </NavbarText>
          </NavItem>
        </Nav>
      </Navbar>
    )
  }

  return (
    <Navbar color="primary" className="text-white justify-content-center">
      <Nav className="justify-content-center">
        <NavItem>
          <NavLink  className="text-white-hover" active={selectedIndex === 0} muted={selectedIndex > 0}>
            {t('features.getstarted.nav.goal')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavbarText> | </NavbarText>
        </NavItem>
        <NavItem>
          <NavLink  className="text-white-hover" active={selectedIndex === 1} muted={selectedIndex > 1}>
            {t('features.getstarted.nav.name')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavbarText> | </NavbarText>
        </NavItem>
        <NavItem>
          <NavLink  className="text-white-hover" active={selectedIndex === 2} muted={selectedIndex > 2}>
            {t('features.getstarted.nav.age')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavbarText> | </NavbarText>
        </NavItem>
        <NavItem>
          <NavLink  className="text-white-hover" active={selectedIndex === 3} muted={selectedIndex > 3}>
            {t('features.getstarted.nav.sex')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavbarText> | </NavbarText>
        </NavItem>
        <NavItem>
          <NavLink  className="text-white-hover" active={selectedIndex === 4}>
            {t('features.getstarted.nav.country')}
          </NavLink>
        </NavItem>
      </Nav>
    </Navbar>
  )
}

export default Header
