import React, { useState ,useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Input,
  Button,
  Navbar,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik'
import Google from '../images/Google.svg'
import Facebook from '../images/Facebook.svg'
import * as yup from 'yup'
import InviteSent from 'shared/views/auth/InviteSent'
import { gql, ApolloError } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { apolloErrorToString } from 'shared/utils/api/ApolloClient'
import { useAuth0 } from 'shared/views/auth/Provider'
interface Values {
  email?: string
}

interface Props {
  socialSignin?: boolean
}

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $redirectUri: String!) {
    login(email: $email, redirectUri: $redirectUri)
  }
`

const Login: React.FC<Props> = ({ socialSignin = false }: Props) => {
  const { t, i18n } = useTranslation()
  const initialValues: Values = {
    email: '',
  }
  const { isAuthenticated } = useAuth0()

  // useEffect(() => {
  //   const IS_PRODUCTION_ENV = process.env.NODE_ENV === 'production'
  //   const HOMEPAGE_URL = process.env.REACT_APP_HOMEPAGE_URI

  //   // In production: if not authenticated, kick to homepage url
  //   if (IS_PRODUCTION_ENV && !isAuthenticated) {
  //     if (HOMEPAGE_URL) window.location.replace(HOMEPAGE_URL)
  //   }
  //   if (isAuthenticated) {
  //     window.location.href = '/timeline'
  //   }
  // }, [isAuthenticated])
  const [login] = useMutation(LOGIN_MUTATION)

  const [inviteSent, setInviteSent] = useState<boolean>(false)

  const EmailSchema = yup.object().shape({
    email: yup
      .string()
      .required(t('features.getstarted.login.errors.email.empty'))
      .email(t('features.getstarted.login.errors.email.invalid')),
  })

  const onSubmit = (values: Values, formik: FormikHelpers<Values>): void => {
    if (values.email) {
      login({
        variables: {
          email: values.email,
          redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
        },
      })
        .then(() => {
          setInviteSent(true)
        })
        .catch((error: ApolloError) => {
          formik.setFieldError('email', apolloErrorToString(error, i18n))
        })
    }
  }

  if (inviteSent) {
    return <InviteSent />
  }

  return (
    <>
      <Navbar color="primary" className="text-white justify-content-center">
        <Nav className="justify-content-center">
          <NavItem>
            <NavLink style={{color:"white"}} active={true}>
              {t('features.landing.login.description')}
            </NavLink>
          </NavItem>
        </Nav>
      </Navbar>
      <Container className="content">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
          validationSchema={EmailSchema}
        >
          <Form>
            <Field
              as={Input}
              name="email"
              type="email"
              placeholder={t('features.getstarted.login.email')}
              className="goal custom"
            />
            <ErrorMessage name="email">
              {msg => <div className="form-error">{msg}</div>}
            </ErrorMessage>
            <Button
              type="submit"
              color="primary"
              block
              size="lg"
              className="login"
            >
              {t('features.landing.login.login')}
            </Button>
            {socialSignin ? (
              <>
                <b className="login-spacer">{t('features.landing.login.or')}</b>
                <Button
                  color="primary"
                  outline
                  block
                  size="lg"
                  className="social_signin"
                >
                  <img
                    src={Google}
                    alt={t('features.landing.login.google_signin')}
                  />
                  {t('features.landing.login.google_signin')}
                </Button>
                <Button
                  color="primary"
                  block
                  outline
                  size="lg"
                  className="social_signin"
                >
                  <img
                    src={Facebook}
                    alt={t('features.landing.login.facebook_signin')}
                  />
                  {t('features.landing.login.facebook_signin')}
                </Button>
              </>
            ) : null}
          </Form>
        </Formik>
      </Container>
    </>
  )
}

export default Login
