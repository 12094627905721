import React, { SVGProps } from 'react'
import { Person, Note, MILESTONE_ICONS } from 'shared/types'
import {
  Notes2Icon,
  Notes3Icon,
  Notes4Icon,
  Notes5Icon,
  Notes6Icon,
  Notes7Icon,
  Notes8Icon,
  Notes9Icon,
  Notes9PlusIcon,
} from 'shared/views/Icon'

const MILESTONE_SIZE = 38

const SQUARE_SIZE = 30

// adjust the size so it'll fit when the rect is rotated
const ROTATED_SIZE = MILESTONE_SIZE / Math.sqrt(2)

const ICON_HEIGHT = 18
const ICON_Y = 5

const TEXT_Y = 30

export interface TimelineNoteProps {
  person: Person
  note: Note
  onViewNote: (note: Note) => void
}

const TimelineNote: React.FC<TimelineNoteProps> = (
  props: TimelineNoteProps
) => {
  const Icon = MILESTONE_ICONS[props.note.type]

  const RECT_SIZE = props.note.isModified ? SQUARE_SIZE : ROTATED_SIZE

  return (
    <svg
      viewBox={`0 0 ${MILESTONE_SIZE} ${MILESTONE_SIZE}`}
      onClick={() => {
        props.onViewNote(props.note)
      }}
    >
      <rect
        className="milestone-polygon"
        x={(MILESTONE_SIZE - RECT_SIZE) / 2}
        y={(MILESTONE_SIZE - RECT_SIZE) / 2}
        width={RECT_SIZE}
        height={RECT_SIZE}
        rx="2"
        transform={`rotate(${props.note.isModified ? 0 : 45}, ${MILESTONE_SIZE /
          2}, ${MILESTONE_SIZE / 2})`}
      />
      <Icon x="0" y={ICON_Y} width={MILESTONE_SIZE} height={ICON_HEIGHT} />
      {props.person.birthYear && (
        <text className="milestone-text" x={MILESTONE_SIZE / 2} y={TEXT_Y}>
          {props.note.year - props.person.birthYear}
        </text>
      )}
    </svg>
  )
}

export interface TimelineNoteMultiProps {
  person: Person
  notes: Note[]
  onViewNote: (note: Note) => void
}

const MULTI_ICONS: { [num: number]: React.FC<SVGProps<SVGSVGElement>> } = {
  2: Notes2Icon,
  3: Notes3Icon,
  4: Notes4Icon,
  5: Notes5Icon,
  6: Notes6Icon,
  7: Notes7Icon,
  8: Notes8Icon,
  9: Notes9Icon,
}

const MULTI_ICON_Y = 10

export const TimelineNoteMulti: React.FC<TimelineNoteMultiProps> = (
  props: TimelineNoteMultiProps
) => {
  const Icon =
    props.notes.length <= 9 ? MULTI_ICONS[props.notes.length] : Notes9PlusIcon

  const RECT_SIZE = props.notes[0].isModified ? SQUARE_SIZE : ROTATED_SIZE

  return (
    <svg
      viewBox={`0 0 ${MILESTONE_SIZE} ${MILESTONE_SIZE}`}
      onClick={() => {
        props.onViewNote(props.notes[0])
      }}
    >
      <rect
        className="milestone-polygon"
        x={(MILESTONE_SIZE - RECT_SIZE) / 2}
        y={(MILESTONE_SIZE - RECT_SIZE) / 2}
        width={RECT_SIZE}
        height={RECT_SIZE}
        rx="2"
        transform={`rotate(${
          props.notes[0].isModified ? 0 : 45
        }, ${MILESTONE_SIZE / 2}, ${MILESTONE_SIZE / 2})`}
      />
      <Icon
        className="milestone-icon"
        x="0"
        y={MULTI_ICON_Y}
        width={MILESTONE_SIZE}
        height={ICON_HEIGHT}
      />
    </svg>
  )
}

export default TimelineNote
