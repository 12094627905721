import React, { useState } from "react";
import {
	Formik,
	Form,
	FieldArray,
	FormikProps,
	Field,
	ErrorMessage,
	FieldProps,
} from "formik";
import {
	Button,
	Container,
	InputGroup,
	InputGroupAddon,
	Input,
	Row,
	Col,
} from "reactstrap";
import { Account, Income, ContributionType } from "../types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { MAX_ASSET_VALUE } from "shared/constants";
import "../styles.scss";
import RetirementAccountItem from "./RetirementAccountItem";
import RetirementIncomeItem from "./RetirementIncomeItem";
import ExpenseModal from "./RetirementExpenseModal";

interface Props {
  initialValues: FormValues
  onSubmit: (values: FormValues) => Promise<void>
  mutationError?: string
  loading?: boolean
  isRetired: boolean
}

export interface FormValues {
  currentIncome?: number
  incomeType?: ContributionType
  predictedExpenses?: number
  expensesType?: ContributionType
  postRetirementGrowthRate?: number
  accounts: Account[]
  incomes: Income[]
}

interface AccountsListProps {
  items: Account[]
  isRetired: boolean
  formik: FormikProps<FormValues>
  remove: (index: number) => void
  push: (item: unknown) => void
}

const AccountsList: React.FC<AccountsListProps> = ({
	items,
	isRetired,
	formik,
	remove,
	push,
}: AccountsListProps) => {
	const { t } = useTranslation();
	return (
		<>
			{items.map((item, index) => (
				<div key={index}>
					<RetirementAccountItem
						isRetired={isRetired}
						index={index}
						isSubmitting={formik.isSubmitting}
						handleChange={formik.handleChange}
						remove={remove}
					/>
				</div>
			))}
			<Button
				title="[Add]"
				color="success"
				disabled={formik.isSubmitting}
				className="mb-3"
				onClick={() =>
				// Must set default values so the remove function works correctly
					push({
						name: "",
						value: 0,
						annualGrowth: 0,
						contribution: 0,
						contributionType: ContributionType.Yearly,
					})
				}
			>
        + {t("features.calculator.retirement.addItem")}
			</Button>
		</>
	);
};

interface IncomesListProps {
  items: Income[]
  formik: FormikProps<FormValues>
  remove: (index: number) => void
  push: (item: unknown) => void
}

const IncomesList: React.FC<IncomesListProps> = ({
	items,
	formik,
	remove,
	push,
}: IncomesListProps) => {
	const { t } = useTranslation();
	return (
		<>
			{items.map((item, index) => (
				<div key={index}>
					<RetirementIncomeItem
						index={index}
						isSubmitting={formik.isSubmitting}
						handleChange={formik.handleChange}
						remove={remove}
					/>
				</div>
			))}
			<Button
				title="[Add]"
				color="success"
				disabled={formik.isSubmitting}
				className="mb-3"
				onClick={() =>
				// Must set default values so the remove function works correctly
					push({
						name: "",
						contribution: 0,
						contributionType: ContributionType.Yearly,
					})
				}
			>
        + {t("features.calculator.retirement.addItem")}
			</Button>
		</>
	);
};

const RetirementCalculatorForm: React.FC<Props> = (props: Props) => {
	const { t } = useTranslation();
	const [showExpenseModal, setShowExpenseModal] = useState(false);

	const validationSchema = yup.object().shape({
		currentIncome: yup
			.number()
			.required(t("features.calculator.retirement.errors.required"))
			.min(
				0,
				t("features.calculator.retirement.errors.outOfRange", {
					max: MAX_ASSET_VALUE.toLocaleString(),
				})
			)
			.max(
				MAX_ASSET_VALUE,
				t("features.calculator.retirement.errors.outOfRange", {
					max: MAX_ASSET_VALUE.toLocaleString(),
				})
			),
		incomeType: yup
			.string()
			.required(t("features.calculator.retirement.errors.required")),
		predictedExpenses: yup
			.number()
			.required(t("features.calculator.retirement.errors.required"))
			.min(
				0,
				t("features.calculator.retirement.errors.outOfRange", {
					max: MAX_ASSET_VALUE.toLocaleString(),
				})
			)
			.max(
				MAX_ASSET_VALUE,
				t("features.calculator.retirement.errors.outOfRange", {
					max: MAX_ASSET_VALUE.toLocaleString(),
				})
			),
		expensesType: yup
			.string()
			.required(t("features.calculator.retirement.errors.required")),
		postRetirementGrowthRate: yup
			.number()
			.required(t("features.calculator.retirement.errors.required"))
			.min(
				0,
				t("features.calculator.retirement.errors.outOfRange", {
					max: 100,
				})
			)
			.max(
				100,
				t("features.calculator.retirement.errors.outOfRange", {
					max: 100,
				})
			),
		accounts: yup.array().of(
			yup.object().shape({
				name: yup
					.string()
					.required(t("features.calculator.retirement.errors.required")),
				value: yup
					.number()
					.required(t("features.calculator.retirement.errors.required"))
					.min(
						0,
						t("features.calculator.retirement.errors.outOfRange", {
							max: MAX_ASSET_VALUE.toLocaleString(),
						})
					)
					.max(
						MAX_ASSET_VALUE,
						t("features.calculator.retirement.errors.outOfRange", {
							max: MAX_ASSET_VALUE.toLocaleString(),
						})
					),
				annualGrowth: yup
					.number()
					.required(t("features.calculator.retirement.errors.required"))
					.min(
						0,
						t("features.calculator.retirement.errors.outOfRange", {
							max: 100,
						})
					)
					.max(
						100,
						t("features.calculator.retirement.errors.outOfRange", {
							max: 100,
						})
					),
				contribution: yup
					.number()
					.required(t("features.calculator.retirement.errors.required"))
					.min(
						0,
						t("features.calculator.retirement.errors.outOfRange", {
							max: MAX_ASSET_VALUE.toLocaleString(),
						})
					)
					.max(
						MAX_ASSET_VALUE,
						t("features.calculator.retirement.errors.outOfRange", {
							max: MAX_ASSET_VALUE.toLocaleString(),
						})
					),
				contributionType: yup
					.string()
					.required(t("features.calculator.retirement.errors.required")),
			})
		),
		incomes: yup.array().of(
			yup.object().shape({
				name: yup
					.string()
					.required(t("features.calculator.retirement.errors.required")),
				contribution: yup
					.number()
					.required(t("features.calculator.retirement.errors.required"))
					.min(
						0,
						t("features.calculator.retirement.errors.outOfRange", {
							max: MAX_ASSET_VALUE.toLocaleString(),
						})
					)
					.max(
						MAX_ASSET_VALUE,
						t("features.calculator.retirement.errors.outOfRange", {
							max: MAX_ASSET_VALUE.toLocaleString(),
						})
					),
				contributionType: yup
					.string()
					.required(t("features.calculator.retirement.errors.required")),
			})
		),
	});

	// override initial values if we're retired
	const initialValues: FormValues = {
		...props.initialValues,
		accounts: props.initialValues.accounts
			? props.initialValues.accounts.map(account => {
				if (props.isRetired) {
					return {
						id: account.id,
						name: account.name,
						value: account.value,
						annualGrowth: props.isRetired ? 0 : account.annualGrowth,
						contribution: props.isRetired ? 0 : account.contribution,
						contributionType: props.isRetired
							? ContributionType.Monthly
							: account.contributionType,
					};
				} else {
					return account;
				}
			})
			: [],
	};

	return (
		<Container className="content">
			<Formik
				initialValues={initialValues}
				onSubmit={props.onSubmit}
				validationSchema={validationSchema}
				validateOnBlur={false}
				validateOnChange={false}
				validateOnMount={false}
			>
				{(formik: FormikProps<FormValues>) => {
					return (
						<>
							{showExpenseModal && (
								<ExpenseModal
									submit={(total: number, freq: ContributionType) => {
										formik.setFieldValue("predictedExpenses", total);
										formik.setFieldValue("expensesType", freq);
									}}
									toggle={() => setShowExpenseModal(false)}
									isOpen={showExpenseModal}
								/>
							)}
							<Form>
								<h2>{t("features.calculator.retirement.currentHeader")}</h2>
								<div className="first-fields">
									<Row form>
										<label>
											{t("features.calculator.retirement.labels.currentIncome")}
										</label>
									</Row>
									<Row form className="mb-3">
										<Col sm={5} className="networth-form-column">
											<InputGroup>
												<InputGroupAddon addonType="prepend">$</InputGroupAddon>
												<Field
													as={Input}
													type="number"
													name="currentIncome"
													disabled={formik.isSubmitting}
													className={`${
														formik.errors.currentIncome ? " has-errors" : ""
													}`}
													placeholder={t(
														"features.calculator.retirement.default.currentIncome"
													)}
												/>
											</InputGroup>
											<ErrorMessage name="currentIncome">
												{msg => <div className="mt-1 form-error">{msg}</div>}
											</ErrorMessage>
										</Col>
										<Col sm={4} className="networth-form-column">
											<Field
												as={Input}
												type="select"
												name="incomeType"
												className={`${
													formik.errors.incomeType ? " has-errors" : ""
												}`}
												disabled={formik.isSubmitting}
											>
												<option value={"default"}>
													{t(
														"features.calculator.retirement.contributionType.options.DEFAULT"
													)}
												</option>
												{Object.values(ContributionType).map(type => (
													<option key={type} value={type}>
														{t(
															`features.calculator.retirement.contributionType.options.${type}`
														)}
													</option>
												))}
											</Field>
											<ErrorMessage name="incomeType">
												{msg => <div className="mt-1 form-error">{msg}</div>}
											</ErrorMessage>
										</Col>
									</Row>
									<Row form>
										<label>
											{t(
												"features.calculator.retirement.labels.predictedExpenses"
											)}
										</label>
									</Row>
									<Row form className="mb-3">
										<Col sm={5} className="networth-form-column">
											<InputGroup>
												<InputGroupAddon addonType="prepend">$</InputGroupAddon>
												<Field name="predictedExpenses" type="number">
													{({ form, field }: FieldProps) => (
														<Input
															name="predictedExpenses"
															placeholder={t(
																"features.calculator.retirement.default.predictedExpenses"
															)}
															type={"number"}
															step="any"
															className={`${
																form.errors.predictedExpenses
																	? " has-errors"
																	: ""
															}`}
															onChange={e => {
																form.handleChange(e);
															}}
															value={field.value}
														/>
													)}
												</Field>
											</InputGroup>
											<ErrorMessage name="predictedExpenses">
												{msg => <div className="mt-1 form-error">{msg}</div>}
											</ErrorMessage>
										</Col>
										<Col sm={4} className="networth-form-column">
											<Field name="expensesType" disabled={formik.isSubmitting}>
												{({ form, field }: FieldProps) => (
													<Input
														name="expensesType"
														placeholder={t(
															"features.calculator.retirement.default.expensesType"
														)}
														type="select"
														step="any"
														className={`${
															form.errors.expensesType ? " has-errors" : ""
														}`}
														onChange={e => {
															form.handleChange(e);
														}}
														value={field.value}
													>
														<option value={"default"}>
															{t(
																"features.calculator.retirement.contributionType.options.DEFAULT"
															)}
														</option>
														{Object.values(ContributionType).map(type => (
															<option key={type} value={type}>
																{t(
																	`features.calculator.retirement.contributionType.options.${type}`
																)}
															</option>
														))}
													</Input>
												)}
											</Field>
											<ErrorMessage name="expensesType">
												{msg => <div className="mt-1 form-error">{msg}</div>}
											</ErrorMessage>
										</Col>
										<Col sm={3} className="networth-form-column">
											<Button
												color="primary"
												className="mt-1"
												disabled={formik.isSubmitting}
												block
												onClick={() => setShowExpenseModal(true)}
											>
												{t("features.calculator.retirement.estimateExpense")}
											</Button>
										</Col>
									</Row>
								</div>

								<FieldArray name="accounts">
									{({ push, remove }) => (
										<div className="items-list">
											<h2>
												{t("features.calculator.retirement.accountsHeader")}
											</h2>
											<AccountsList
												isRetired={props.isRetired}
												items={formik.values.accounts || []}
												formik={formik}
												push={push}
												remove={remove}
											/>
										</div>
									)}
								</FieldArray>
								<FieldArray name="incomes">
									{({ push, remove }) => (
										<div className="items-list">
											<h2>
												{t("features.calculator.retirement.incomesHeader")}
											</h2>
											<IncomesList
												items={formik.values.incomes || []}
												formik={formik}
												push={push}
												remove={remove}
											/>
										</div>
									)}
								</FieldArray>
								<Row form>
									<label>
										{t(
											"features.calculator.retirement.labels.postRetirementGrowthRate"
										)}
									</label>
								</Row>
								<Row form className="mb-3">
									<Col sm={5} className="networth-form-column">
										<InputGroup>
											<Field
												as={Input}
												type="number"
												name="postRetirementGrowthRate"
												className={`${
													formik.errors.postRetirementGrowthRate
														? " has-errors"
														: ""
												}`}
												disabled={formik.isSubmitting}
												placeholder={t(
													"features.calculator.retirement.default.postRetirementGrowthRate"
												)}
											/>
											<InputGroupAddon addonType="append">%</InputGroupAddon>
										</InputGroup>
										<ErrorMessage name="postRetirementGrowthRate">
											{msg => <div className="mt-1 form-error">{msg}</div>}
										</ErrorMessage>
									</Col>
								</Row>
								<Button
									type="submit"
									color="primary"
									className="my-3"
									disabled={formik.isSubmitting}
									block
								>
									{t("features.calculator.retirement.submit")}
								</Button>
								{props.mutationError && (
									<div className="form-error mt-0">{props.mutationError}</div>
								)}
							</Form>
						</>
					);
				}}
			</Formik>
		</Container>
	);
};

export default RetirementCalculatorForm;
