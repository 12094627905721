import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { currencyFormatter } from '../../../../shared/utils'

interface RetirementConfirmProps {
  backUrl: string
  savingsGoal: number
  projectedSavings: number
  surplus: number
}

const RetirementConfirm: React.FC<RetirementConfirmProps> = (
  props: RetirementConfirmProps
) => {
  const history = useHistory()
  const { t } = useTranslation()

  const surplusPositive = props.surplus >= 0
  const surplusDisplay = Math.abs(props.surplus)

  return (
    <div className="text-center mt-3">
      <h4>{t('features.calculator.retirement.savingsGoal')}</h4>
      <h2>{currencyFormatter.format(props.savingsGoal)}</h2>
      <h4>{t('features.calculator.retirement.projectedSavings')}</h4>
      <h2>{currencyFormatter.format(props.projectedSavings)}</h2>
      <h4>
        {surplusPositive
          ? t('features.calculator.retirement.surplus')
          : t('features.calculator.retirement.shortfall')}
      </h4>
      <h2 className={surplusPositive ? 'text-success' : 'text-danger'}>
        {currencyFormatter.format(surplusDisplay)}
      </h2>
      <Button
        color="primary"
        onClick={() => {
          history.push(props.backUrl)
        }}
      >
        {t('features.calculator.retirement.confirmBack')}
      </Button>
    </div>
  )
}

export default RetirementConfirm
