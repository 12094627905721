import {
  FitnessIcon,
  HousingIcon,
  ObolxIcon,
  ChildIcon,
  TravelIcon,
  UniversityIcon,
} from './views/Icon'

export enum Sex {
  Male = 'MALE',
  Female = 'FEMALE',
}

export enum Role {
  Primary = 'PRIMARY',
  Partner = 'PARTNER',
  Child = 'CHILD',
  Member = 'MEMBER',
}

export enum NoteType {
  Fitness = 'FITNESS',
  Housing = 'HOUSING',
  Milestone = 'MILESTONE',
  Education = 'EDUCATION',
  Child = 'CHILD',
  Travel = 'TRAVEL',
}

export const MILESTONE_ICONS = {
  [NoteType.Fitness]: FitnessIcon,
  [NoteType.Housing]: HousingIcon,
  [NoteType.Milestone]: ObolxIcon,
  [NoteType.Education]: UniversityIcon,
  [NoteType.Child]: ChildIcon,
  [NoteType.Travel]: TravelIcon,
}

export enum CalculatorType {
  LifeExpectancy = 'LIFE_EXPECTANCY',
  HealthyLifeExpectancy = 'HEALTHY_LIFE_EXPECTANCY',
  Retirement = 'RETIREMENT',
  Century = 'CENTURY',
  Custom = 'CUSTOM',
}

export enum SegmentType {
  School = 'SCHOOL',
  University = 'UNIVERSITY',
  Work = 'WORK',
  Gold = 'GOLD',
  Silver = 'SILVER',
  Bronze = 'BRONZE',
}

export interface Group {
  id: number
}

export const PERSON_NAME_MAX_LENGTH = 255

export interface Person {
  id: number
  firstName: string
  lastName: string
  birthYear: number
  sex: Sex
  country: string
  hasUser: boolean
}

export interface Membership {
  id: number
  person: Person
  role: Role
  canEdit: boolean
  canDelete: boolean
  canInvite: boolean
  showCalculators: boolean
  showEducationCalculator?: boolean
}

export const NOTE_DESC_MAX_LENGTH = 255

export interface Note {
  id: number
  group?: Group
  membership?: Membership

  type: NoteType
  calculator: CalculatorType
  year: number
  isModified?: boolean
  description?: string
  owner?: Person
  sharedWith?: Person[]
  canEdit: boolean
}

export interface Segment {
  type: SegmentType
  start: number
  end: number
}

export interface Timeline {
  startYear: number
  endYear: number
  segments: Segment[]
  notes: Note[]
}

export interface Profile {
  membership: Membership
  timeline: Timeline
}

export interface Family {
  id: number
  profiles: Profile[]
  notes: Note[]
}

export interface FeatureFlags {
  showTimelineIntro: boolean
  showConfirmPersonInfo?: boolean
  showNewMemberOnboarding?: boolean
}

export interface RateInfo {
  age: number
  sex: Sex
  value: number
}

export enum EventRow {
  First = 'A_1',
  Second = 'A_2',
  Third = 'A_3',
  Fourth = 'A_4',
}

export const eventRowToInteger: (er: EventRow) => number = (er: EventRow) => {
  switch (er) {
    case EventRow.First: {
      return 1
    }
    case EventRow.Second: {
      return 2
    }
    case EventRow.Third: {
      return 3
    }
    default: {
      return 4
    }
  }
}
export interface Event {
  id: number
  year: number
  row: EventRow
  name: string
}
