export enum ContributionType {
  Yearly = 'YEARLY',
  Monthly = 'MONTHLY',
}

export enum SchoolType {
  University = 'UNIVERSITY',
  College = 'COLLEGE',
  Trade = 'TRADE',
}

export interface Account {
  id?: number
  name: string
  value: number
  annualGrowth: number
  contribution: number
  contributionType: ContributionType
}

export interface Education {
  id?: number
  schoolType: SchoolType
  studyAge: number
  studyYears: number
  annualTuition: number
  annualHousing: number
  annualOther: number
  accounts: Account[]
  projectedSavings?: number
  savingsGoal?: number
  surplus?: number
}

export interface EducationInput {
  membershipId?: number
  education: Education
}
