import React, { useEffect } from 'react'
import { Container, Button, Media } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Navbar from 'shared/views/Navbar'
import './styles.scss'

import { useAuth0 } from 'shared/views/auth/Provider'

// images
import timeline from './images/timeline.svg'

const LandingPage: React.FC = () => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuth0()

  useEffect(() => {
    const IS_PRODUCTION_ENV = process.env.NODE_ENV === 'production'
    const HOMEPAGE_URL = process.env.REACT_APP_HOMEPAGE_URI

    // In production: if not authenticated, kick to homepage url
    if (IS_PRODUCTION_ENV && !isAuthenticated) {
      if (HOMEPAGE_URL) window.location.replace(HOMEPAGE_URL)
    }
    if (isAuthenticated) {
      window.location.href = '/timeline'
    }
  }, [isAuthenticated])

  return (
    <div>
      <Navbar />
      <Container className="content">
        <h1 className="display-3 title">{t('features.landing.title')}</h1>
        <p className="subtitle">{t('features.landing.subtitle')}</p>
        <div className="text-center">
          <img
            src={timeline}
            alt={t('features.landing.alt_timeline')}
            width="315"
            height="260"
          />
        </div>
        <Link to="/get-started">
          <Button color="primary" block size="lg" className="get-started">
            {t('features.landing.get_started')}
          </Button>
        </Link>
        <hr />
        <h3>{t('features.landing.heading_what_is_obolx')}</h3>
        <p>{t('features.landing.body_what_is_obolx')}</p>
        <hr />
        <h3>{t('features.landing.heading_obolx_includes')}</h3>
        <Media>
          <Media left></Media>
          <Media body>
            <Media heading>
              {t('features.landing.features.timeline.heading')}
            </Media>
            {t('features.landing.features.timeline.body')}
          </Media>
        </Media>
        <Media>
          <Media left></Media>
          <Media body>
            <Media heading>
              {t('features.landing.features.dashboard.heading')}
            </Media>
            {t('features.landing.features.dashboard.body')}
          </Media>
        </Media>
        <Media>
          <Media left></Media>
          <Media body>
            <Media heading>
              {t('features.landing.features.reports.heading')}
            </Media>
            {t('features.landing.features.reports.body')}
          </Media>
        </Media>
        <Media>
          <Media left></Media>
          <Media body>
            <Media heading className="feature-heading">
              {t('features.landing.features.network.heading')}
            </Media>
            {t('features.landing.features.network.body')}
          </Media>
        </Media>
        <hr />
        <Link to="/get-started">
          <Button color="primary" block size="lg" className="get-started">
            {t('features.landing.get_started')}
          </Button>
        </Link>
      </Container>
     <div className="text-center">
        <div className="ul-sp">
          <ul>
            <li><a href="https://www.facebook.com/obolxlife"><img alt="" src="https://daks2k3a4ib2z.cloudfront.net/5e8a8d228a0cee496d84da9c/5ebecc3a17386cb76d325cd3_Facebook_2.png"/></a></li>
            <li><a href="https://www.instagram.com/obolxlife"><img alt=""src="https://daks2k3a4ib2z.cloudfront.net/5e8a8d228a0cee496d84da9c/5ebecc395fa22d3b55623a37_Instagram_2.png"/></a></li>
            <li><a href=" https://twitter.com/obolx"><img alt="" src="https://daks2k3a4ib2z.cloudfront.net/5e8a8d228a0cee496d84da9c/5ebecc39d340492e7a024df1_Twitter_2.png"/></a></li>

          </ul>
        </div>
        <small>
          <strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {t('features.landing.footer.links.terms')} |{' '}
            {t('features.landing.footer.links.data_policies')}
          </strong>
        </small>
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>{t('features.landing.footer.copyright')}</small>
      </div>
    </div>
  )
}

export default LandingPage
