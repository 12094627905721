import { gql } from 'apollo-boost'
import { Education } from './types'

export const EDUCATION_FRAGMENT = gql`
  fragment EducationFields on EducationDetails {
    id
    schoolType
    studyAge
    studyYears
    annualTuition
    annualHousing
    annualOther

    projectedSavings
    savingsGoal
    surplus

    accounts {
      id
      annualGrowth
      contribution
      contributionType
      name
      value
    }
  }
`
export const EDUCATION_QUERY = gql`
  query educationQuery($membershipId: ID!) {
    education(id: $membershipId) {
      ...EducationFields
    }
  }

  ${EDUCATION_FRAGMENT}
`

export interface EducationQuery {
  education: Education | null
}

export interface EducationMutation {
  updateEducation: Education | null
}

export interface EducationQueryInput {
  membershipId: number
}

export interface EducationMutationInput {
  membershipId: number
  education: Education | null
}

export const EDUCATION_MUTATION = gql`
  mutation UpdateEducationDetails(
    $membershipId: ID!
    $education: EducationInput
  ) {
    updateEducation(membershipId: $membershipId, educationInput: $education) {
      ...EducationFields
    }
  }

  ${EDUCATION_FRAGMENT}
`

export const SEGMENT_REFETCH = gql`
  query {
    family {
      id
      profiles {
        timeline {
          id
          segments {
            id
            type
            start
            end
          }
        }
      }
    }
  }
`
