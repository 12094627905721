import React from 'react'
import { Person, Timeline, Role, Note } from 'shared/types'
import { groupNotes } from 'shared/utils'
import AxisTranslator from 'shared/utils/axis'
import TimelineBarSegment from './TimelineBarSegment'
import TimelineNote, { TimelineNoteMulti } from './TimelineNote'
import '../styles.scss'

interface TimelineBarProps {
  person: Person
  timeline: Timeline
  start: number
  role: Role
  onViewNote: (note: Note) => void
}

const MILESTONE_SIZE = 38

const TimelineBar: React.FC<TimelineBarProps> = ({
  person,
  timeline,
  start,
  role,
  onViewNote,
}: TimelineBarProps) => {
  const notes = timeline.notes.sort((a, b) => a.year - b.year) || []
  const segments = timeline.segments || []

  const noteGroups = groupNotes(notes)

  return (
    <div
      className={`bar long-bar role-${role.toLowerCase()}`}
      style={{
        marginLeft: AxisTranslator.year(timeline.startYear - start, false),
        width: AxisTranslator.year(
          timeline.endYear - timeline.startYear,
          false
        ),
      }}
    >
      {segments.map((segment, index) => (
        <TimelineBarSegment
          start={timeline.startYear}
          segment={segment}
          key={`timeline-bar-segment-${person.firstName}${person.lastName}-${index}`}
        />
      ))}
      {noteGroups.map(group => {
        const note = group[0]
        return (
          <div
            key={`${note.type}-${note.year}`}
            className="timeline-milestone-marker"
            style={{
              left:
                AxisTranslator.year(note.year - timeline.startYear, false) -
                MILESTONE_SIZE / 2,
              top: '2.5px',
              width: MILESTONE_SIZE,
            }}
          >
            {group.length === 1 ? (
              <TimelineNote
                person={person}
                note={note}
                onViewNote={onViewNote}
              />
            ) : (
              <TimelineNoteMulti
                person={person}
                notes={group}
                onViewNote={onViewNote}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default TimelineBar
