import React from 'react'
import { Formik, Form, FieldArray, FormikProps } from 'formik'
import { Button, Container } from 'reactstrap'
import { Item } from '../types'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { MAX_ASSET_VALUE } from 'shared/constants'
import '../styles.scss'
import NetWorthItem from './NetWorthItem'
import { currencyFormatter } from '../../../../shared/utils'

interface Props {
  assets: Item[]
  liabilities: Item[]
  onSubmit: (values: FormValues) => Promise<void>
  mutationError?: string
  loading?: boolean
}

export interface FormValues {
  assets: Item[]
  liabilities: Item[]
}

// calculate total value of all assets
const sumItems = (items: Item[]): number => {
  let sum = 0
  items.forEach(item => {
    sum += item.value * (item.ownershipPercent / 100)
  })
  return sum
}

interface ItemsListProps {
  items: Item[]
  name: 'assets' | 'liabilities'
  formik: FormikProps<FormValues>
  remove: (index: number) => void
  push: (item: unknown) => void
}

const ItemsList: React.FC<ItemsListProps> = (props: ItemsListProps) => {
  const { t } = useTranslation()

  return (
    <>
      {props.items.map((item, index) => (
        <div key={index}>
          <NetWorthItem
            name={props.name}
            index={index}
            isSubmitting={props.formik.isSubmitting}
            handleChange={props.formik.handleChange}
            remove={props.remove}
          />
        </div>
      ))}
      <Button
        title="[Add]"
        color="success"
        disabled={props.formik.isSubmitting}
        className="mb-3"
        onClick={() =>
          // Must set default values so the remove function works correctly
          props.push({
            itemType: '',
            name: '',
            value: 0,
            ownershipPercent: 100,
          })
        }
      >
        + {t('features.calculator.netWorth.addItem')}
      </Button>
    </>
  )
}

const NetWorthCalculatorForm: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const itemArraySchema = yup.array().of(
    yup.object().shape({
      itemType: yup
        .string()
        .required(t('features.calculator.netWorth.errors.required')),
      name: yup
        .string()
        .required(t('features.calculator.netWorth.errors.required')),
      value: yup
        .number()
        .required(t('features.calculator.netWorth.errors.required'))
        .min(
          0,
          t('features.calculator.netWorth.errors.outOfRange', {
            max: MAX_ASSET_VALUE.toLocaleString(),
          })
        )
        .max(
          MAX_ASSET_VALUE,
          t('features.calculator.netWorth.errors.outOfRange', {
            max: MAX_ASSET_VALUE.toLocaleString(),
          })
        ),
      ownershipPercent: yup
        .number()
        .required(t('features.calculator.netWorth.errors.required'))
        .min(
          0,
          t('features.calculator.netWorth.errors.outOfRange', {
            max: 100,
          })
        )
        .max(
          100,
          t('features.calculator.netWorth.errors.outOfRange', {
            max: 100,
          })
        ),
    })
  )

  const validationSchema = yup.object().shape({
    assets: itemArraySchema,
    liabilities: itemArraySchema,
  })

  return (
    <Container className="content">
      <Formik
        initialValues={{
          assets: props.assets,
          liabilities: props.liabilities,
        }}
        onSubmit={props.onSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
      >
        {(formik: FormikProps<FormValues>) => {
          return (
            <Form>
              <FieldArray name="assets">
                {({ push, remove }) => (
                  <div className="items-list">
                    <h2>{t('features.calculator.netWorth.assetsHeader')}</h2>
                    <ItemsList
                      items={formik.values.assets}
                      name="assets"
                      formik={formik}
                      push={push}
                      remove={remove}
                    />
                    <h3>
                      {t('features.calculator.netWorth.assetsTotal', {
                        total: currencyFormatter.format(
                          sumItems(formik.values.assets)
                        ),
                      })}
                    </h3>
                  </div>
                )}
              </FieldArray>
              <FieldArray name="liabilities">
                {({ push, remove }) => (
                  <div className="items-list">
                    <h2>
                      {t('features.calculator.netWorth.liabilitiesHeader')}
                    </h2>
                    <ItemsList
                      items={formik.values.liabilities}
                      name="liabilities"
                      formik={formik}
                      push={push}
                      remove={remove}
                    />
                    <h3>
                      {t('features.calculator.netWorth.liabilitiesTotal', {
                        total: currencyFormatter.format(
                          sumItems(formik.values.liabilities)
                        ),
                      })}
                    </h3>
                  </div>
                )}
              </FieldArray>
              <h1>
                {t('features.calculator.netWorth.total', {
                  total: currencyFormatter.format(
                    sumItems(formik.values.assets) -
                      sumItems(formik.values.liabilities)
                  ),
                })}
              </h1>
              <Button
                type="submit"
                color="primary"
                className="mt-3"
                disabled={formik.isSubmitting}
                block
              >
                {t('features.calculator.netWorth.submit')}
              </Button>
              {props.mutationError && (
                <div className="form-error mt-0">{props.mutationError}</div>
              )}
            </Form>
          )
        }}
      </Formik>
    </Container>
  )
}

export default NetWorthCalculatorForm
