import React, { useState } from 'react'
import { State } from '../reducer'
import { useTranslation } from 'react-i18next'
import { Container, Input, Button } from 'reactstrap'
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik'
import { gql, ApolloError } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import * as yup from 'yup'
import InviteSent from 'shared/views/auth/InviteSent'
import { apolloErrorToString } from 'shared/utils/api/ApolloClient'

interface Values {
  email?: string
}

interface Props {
  family: State
}

const CREATE_FAMILY = gql`
  mutation CreateFamily(
    $primary: PersonInput!
    $partner: PersonInput
    $children: [PersonInput]
    $members: [PersonInput]
    $email: String!
    $goal: String!
    $redirectUri: String!
  ) {
    createTempGroup(
      primary: $primary
      partner: $partner
      children: $children
      members: $members
      email: $email
      goal: $goal
      redirectUri: $redirectUri
    ) {
      id
    }
  }
`

const Login: React.FC<Props> = ({ family }: Props) => {
  const { t, i18n } = useTranslation()
  const initialValues: Values = {
    email: '',
  }

  const EmailSchema = yup.object().shape({
    email: yup
      .string()
      .required(t('features.getstarted.login.errors.email.empty'))
      .email(t('features.getstarted.login.errors.email.invalid')),
  })

  const [mutate, { data }] = useMutation(CREATE_FAMILY)
  const [submitted, setSubmitted] = useState<boolean>(false)

  const onSubmit = (values: Values, formik: FormikHelpers<Values>): void => {
    const variables = {
      primary: family.me,
      partner: family.partner,
      children: family.children,
      members: family.others,
      goal: family.goal,
      email: values.email,
      redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
    }
    if (values.email) {
      mutate({
        variables: variables,
      })
        .then(() => {
          setSubmitted(true)
        })
        .catch((error: ApolloError) => {
          formik.setFieldError('email', apolloErrorToString(error, i18n))
        })
    }
  }

  if (data && submitted) {
    return <InviteSent />
  }

  return (
    <Container className="content">
      <p>{t('features.getstarted.login.description')}</p>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        validationSchema={EmailSchema}
      >
        <Form>
          <Field
            as={Input}
            name="email"
            type="email"
            placeholder={t('features.getstarted.login.email')}
            className="goal custom"
          />
          <ErrorMessage name="email">
            {msg => <div className="form-error">{msg}</div>}
          </ErrorMessage>
          <Button
            type="submit"
            color="primary"
            block
            size="lg"
            className="create-account"
          >
            {t('features.getstarted.login.createAccount')}
          </Button>
        </Form>
      </Formik>
    </Container>
  )
}

export default Login
