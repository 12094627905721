import React, { useEffect, useReducer, Reducer } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  useHistory,
  Link
} from 'react-router-dom';
import reducer, { State, Action, initialState, SetExtras } from './reducer';
import Header from './views/Header';
import Smoking from './views/Smoking';
import FormerLightSmoker from './views/FormerLightSmoker';
import FormerHeavySmoker from './views/FormerHeavySmoker';
import Alcohol from './views/Alcohol';
import PhysicalActivity from './views/PhysicalActivity';
import FruitVegetables from './views/FruitVegetables';
import Ethnicity from './views/Ethnicity';
import Education from './views/Education';
import WeightHeight from './views/WeightHeight';
import Conditions from './views/Conditions';
import Deprivation from './views/Deprivation';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { Container, Row, Col, Button } from 'reactstrap';
import Extras from './views/Extras';

const SUBMIT_FORM = gql`
  mutation submit($details: LifeExpectancyInput!) {
    updateLifeExpectancyDetails(details: $details) {
      lifeExpectancy
    }
  }
`;

interface SubmitFormOutput {
  updateLifeExpectancyDetails: {
    lifeExpectancy: number;
  };
}

interface LifeExpectancyRoutesProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}

const LifeExpectancyRoutes: React.FC<LifeExpectancyRoutesProps> = ({
  state,
  dispatch,
}: LifeExpectancyRoutesProps) => {
  const history = useHistory();

  const [mutate, { data }] = useMutation<SubmitFormOutput>(SUBMIT_FORM);

  useEffect(() => {
    window.gtag('event', 'life_expectancy_page_view', {
      page: history.location.pathname,
    });
  }, [history.location]);

  const NUMBER_OF_STEPS = 11;
  let allAges:any = []
  if(localStorage.getItem("survialAllAges") !== null){
    allAges = localStorage.getItem("survialAllAges")
    allAges = JSON.parse(allAges)
    console.log("====all ages===",allAges)
  }
  return (
    <Switch>
      <Route exact path={['/', '/weight-height']}>
        <div>
          <Header progress={1} total={NUMBER_OF_STEPS} />
          <WeightHeight
            weightKg={state.weightKg}
            heightCm={state.heightCm}
            setWeightHeight={dispatch}
          />
        </div>
      </Route>
      <Route exact path='/physical-activity'>
        <div>
          <Header progress={2} total={NUMBER_OF_STEPS} />
          <PhysicalActivity
            continuousActivity={state.vigorousActivityHours}
            nonContinuousActivity={state.vigorousNonContinuousActivityHours}
            lightActivity={state.lightActivityHours}
            setActivity={dispatch}
          />
        </div>
      </Route>
      <Route exact path='/smoking'>
        <div>
          <Header progress={3} total={NUMBER_OF_STEPS} />
          <Smoking
            smokingStatus={state.smokingFrequency}
            setSmoking={dispatch}
          />
        </div>
      </Route>
      <Route exact path='/former-light-smoker'>
        <div>
          <Header progress={4} total={NUMBER_OF_STEPS} />
          <FormerLightSmoker
            formerLightYear={state.formerLightYearCount}
            setFormerLightYear={dispatch}
          />
        </div>
      </Route>
      <Route exact path='/former-heavy-smoker'>
        <div>
          <Header progress={5} total={NUMBER_OF_STEPS} />
          <FormerHeavySmoker
            formerHeavyYear={state.formerHeavyYearCount}
            setFormerHeavyYear={dispatch}
          />
        </div>
      </Route>
      <Route exact path='/alcohol'>
        <div>
          <Header progress={6} total={NUMBER_OF_STEPS} />
          <Alcohol
            alcoholConsumption={state.alcoholConsumption}
            setAlcohol={dispatch}
          />
        </div>
      </Route>
      <Route exact path='/deprivation'>
        <div>
          <Header progress={7} total={NUMBER_OF_STEPS} />
          <Deprivation
            deprivation={state.deprivation}
            setDeprivation={dispatch}
          />
        </div>
      </Route>
      <Route exact path='/education'>
        <div>
          <Header progress={8} total={NUMBER_OF_STEPS} />
          <Education education={state.education} setEducation={dispatch} />
        </div>
      </Route>

      <Route exact path='/fruits-vegetables'>
        <div>
          <Header progress={9} total={NUMBER_OF_STEPS} />
          <FruitVegetables
            fruitJuice={state.fruitJuiceIntake}
            fruit={state.fruitIntake}
            potatoes={state.potatoIntake}
            vegetables={state.vegetableIntake}
            setIntakes={dispatch}
          />
        </div>
      </Route>

      {/* <Route exact path='/ethnicity'>
        <div>
          <Header progress={5} total={NUMBER_OF_STEPS} />
          <Ethnicity ethnicity={state.ethnicity} setEthnicity={dispatch} />
        </div>
      </Route> */}

      <Route exact path='/conditions'>
        <div>
          <Header progress={10} total={NUMBER_OF_STEPS} />
          <Conditions
            health={{
              hasHeartDisease: state.hasHeartDisease,
              hasStroke: state.hasStroke,
              hasCancer: state.hasCancer,
              hasDiabetes: state.hasDiabetes,
            }}
            setHealth={dispatch}
            // finishForm={async (s: SetExtras) => {
            //   dispatch(s);
            //   const { type, ...extrasValues } = s;
            //   await mutate({
            //     variables: { details: { ...state, ...extrasValues } },
            //   });
            // }}
          />
        </div>
      </Route>
      <Route exact path='/additional-information'>
        <div>
          <Header progress={9} total={NUMBER_OF_STEPS} />
          <Extras
            hoursSleepPerNight={state.hoursSleepPerNight}
            stressRating={state.stressRating}
            // happinessRating={state.happinessRating}
            hoursSocializing={state.hoursSocializing}
            // postalCode={state.postalCode}
            finishForm={async (s: SetExtras) => {
              dispatch(s);
              const { type, ...extrasValues } = s;
              await mutate({
                variables: { details: { ...state, ...extrasValues } },
              });
            }}
          />
        </div>
      </Route>
      <Route exact path='/results'>
        <div style={{ textAlign: "center" }}>
          <Header progress={11} total={NUMBER_OF_STEPS} />
          <Container className='content'>
            <Row>
              <Col>

                <h3>{`Predicted Life Expectancy : ${localStorage.getItem("expectancy")}`}</h3>
                <h3>{`Biological Age : ${localStorage.getItem("healthAge")}`}</h3>
                {/* <Button
                  outline
                  color='link'
                  onClick={() => {
                    history.push(`/dashboard/` + localStorage.getItem("user_id"));
                  }}
                  className='w-100'
                >

                </Button> */}
                {/* <a style={{ textDecoration: 'none' }} href={`/dashboard/` + localStorage.getItem("user_id")} >Back to dashboard </a> */}
                
              </Col>
            </Row>
           
                 {/* <Row>
              <Col>
                <p>Age</p>
              </Col>
              <Col>
              <p>Year</p>
              </Col>
              <Col>
              <p>Surviving Prediction</p>
              </Col>
            </Row> */}
          {/* {Object.keys(allAges).map(function(keyName, keyIndex) {
  
          let age:any = 0
          age  = Number(localStorage.getItem("age"))
          age = (age + keyIndex)
            return(
              <Row>
                  <Col key={keyIndex}><p>{keyName}</p></Col>
                  <Col><p>{age}</p></Col>
                  <Col><p>{allAges[keyName]}</p></Col>
                  </Row>
            )
          })} */}
         
            {/* <Row>
              <Col>
                <p>Age</p>
              </Col>
              <Col>
              <p>Year</p>
              </Col>
              <Col>
              <p>Surviving Prediction</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>{Number(localStorage.getItem("age"))}</p>
              </Col>
              <Col>
              <p>2020</p>
              </Col>
              <Col>
              <p>{localStorage.getItem("sur1")}%</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>{Number(localStorage.getItem("age"))+10}</p>
              </Col>
              <Col>
              <p>2030</p>
              </Col>
              <Col>
              <p>{localStorage.getItem("sur2")}%</p>
              </Col>
            </Row>
            {localStorage.getItem("sur3") && <Row>
              <Col>
              <p>{Number(localStorage.getItem("age"))+10+10}</p>
              </Col>
              <Col>
              <p>2040</p>
              </Col>
              <Col>
              <p>{localStorage.getItem("sur3")}%</p>
              </Col>
            </Row>}
            {localStorage.getItem("sur4") && <Row>
              <Col>
              <p>{Number(localStorage.getItem("age"))+10+10+10}</p>
              </Col>
              <Col>
              <p>2050</p>
              </Col>
              <Col>
              <p>{localStorage.getItem("sur4")}%</p>
              </Col>
            </Row>}
            {localStorage.getItem("sur5") && <Row>
              <Col>
              <p>{Number(localStorage.getItem("age"))+10+10+10+10}</p>
              </Col>
              <Col>
              <p>2060</p>
              </Col>
              <Col>
              <p>{localStorage.getItem("sur5")}%</p>
              </Col>
            </Row>}
            {localStorage.getItem("sur6") && <Row>
              <Col>
              <p>80</p>
              </Col>
              <Col>
              <p>{localStorage.getItem("final_year")}</p>
              </Col>
              <Col>
              <p>{localStorage.getItem("sur6")}%</p>
              </Col>
            </Row>} */}
            
            <Row><Col><a style={{ textDecoration: 'none' }} href={`/dashboard/` + localStorage.getItem("user_id")} >Back to dashboard </a></Col></Row>
          </Container>
        </div>
      </Route>
    </Switch>
  );
};

const LifeExpectancy: React.FC = () => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(
    reducer,
    initialState
  );

  return (
    <Router>
      <LifeExpectancyRoutes state={state} dispatch={dispatch} />
    </Router>
  );
};

export default LifeExpectancy;
