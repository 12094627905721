import { gql } from 'apollo-boost'
import { Property } from './types'

export interface RealEstateQuery {
  realEstate: {
    properties: Property[]
  }
}

export interface RealEstateMutation {
  updateRealEstateProperty: Property
}

const MORTGAGE_FRAGMENT = gql`
  fragment MortgageFields on RealEstateMortgage {
    id
    interest
    paymentFreq
    amortizationYears
    termYears
    name
    value
    termInterest
    totalInterest
    periodPayment
    netEquity
    interestType
  }
`

const PROPERTY_FRAGMENT = gql`
  fragment PropertyFields on RealEstateProperty {
    id
    annualGrowth
    name
    value
    ownershipPercent
    yearModified
    mortgage {
      ...MortgageFields
    }
  }

  ${MORTGAGE_FRAGMENT}
`

export const REAL_ESTATE_QUERY = gql`
  query {
    realEstate {
      properties {
        ...PropertyFields
      }
    }
  }

  ${PROPERTY_FRAGMENT}
`

export const UPDATE_REAL_ESTATE_MUTATION = gql`
  mutation UpdateRealEstate($propertyInput: PropertyInput!) {
    updateRealEstateProperty(propertyInput: $propertyInput) {
      ...PropertyFields
    }
  }

  ${PROPERTY_FRAGMENT}
`

export interface DeleteMutationInput {
  id: number
}

export const DELETE_PROPERTY_MUTATION = gql`
  mutation DeleteRealEstate($id: ID!) {
    deleteRealEstateProperty(id: $id)
  }
`

export const REAL_ESTATE_REFETCH = gql`
  query {
    realEstate {
      properties {
        id
        name
        value
        ownershipPercent
        mortgage {
          id
          name
          value
        }
      }
    }
  }
`
