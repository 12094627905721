import React from 'react'
import { Spinner } from 'reactstrap'

import './LoadingSpinner.scss'

interface LoadingSpinnerProps {
  overlay?: boolean
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  overlay = false,
}: LoadingSpinnerProps) => {
  return (
    <div className={`spinner-container ${overlay ? 'background' : ''}`}>
      <Spinner size="lg" />
    </div>
  )
}

export default LoadingSpinner
