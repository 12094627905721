import { gql } from 'apollo-boost'

export const RETIREMENT_GRAPH_FRAGMENT = gql`
  fragment RetirementGraph on Query {
    retirement {
      id
      graphPoints {
        x
        y
      }
    }
  }
`

export const RETIREMENT_GRAPH_REFETCH = gql`
  query {
    ...RetirementGraph
  }

  ${RETIREMENT_GRAPH_FRAGMENT}
`

export const EDUCATION_GRAPH_FRAGMENT = gql`
  fragment EducationGraph on Query {
    educationGraphs {
      firstName
      lastName
      points {
        x
        y
      }
    }
  }
`

export const REAL_ESTATE_GRAPH_FRAGMENT = gql`
  fragment RealEstateGraph on Query {
    realEstate {
      graphPoints {
        x
        y
      }
    }
  }
`

export const EDUCATION_GRAPH_REFETCH = gql`
  query {
    ...EducationGraph
  }

  ${EDUCATION_GRAPH_FRAGMENT}
`

export const REAL_ESTATE_GRAPH_REFETCH = gql`
  query {
    ...RealEstateGraph
  }

  ${REAL_ESTATE_GRAPH_FRAGMENT}
`

export const FINANCE_GRAPH_QUERY = gql`
  query {
    ...RetirementGraph
    ...EducationGraph
    ...RealEstateGraph
  }

  ${RETIREMENT_GRAPH_FRAGMENT}
  ${EDUCATION_GRAPH_FRAGMENT}
  ${REAL_ESTATE_GRAPH_FRAGMENT}
`
