import React from 'react'
import { Button, Container, Row, Col } from 'reactstrap'
import { Property } from '../types'
import { useTranslation } from 'react-i18next'
import '../styles.scss'
import { useHistory } from 'react-router-dom'
import { currencyFormatter } from '../../../../shared/utils'

interface Props {
  propertyList: Property[]
  editProperty: (p: Property) => void
  newProperty: () => void
  backUrl?: string
}

const RealEstateItemsView: React.FC<Props> = ({
  propertyList,
  editProperty,
  newProperty,
  backUrl = '/',
}: Props) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Container className="content">
      <h2>{t('features.calculator.realestate.title')}</h2>
      <h3>{t('features.calculator.realestate.propertyDetails')}</h3>
      {propertyList.map((property, idx) => (
        <Row key={idx}>
          <Col sm={3} className="px-4 px-sm-3">
            <small>
              {t('features.calculator.realestate.labels.property.name')}
            </small>
            <span className="d-block">{property.name}</span>
          </Col>
          <Col sm={3} className="px-4 px-sm-3">
            <small>
              {t('features.calculator.realestate.labels.property.value')}
            </small>
            <span className="d-block">
              {currencyFormatter.format(property.value)}
            </span>
          </Col>
          <Col sm={6} className="px-0 px-sm-3">
            <small className="px-4 px-sm-0">
              {t('features.calculator.realestate.labels.mortgage.amount')}
            </small>
            <Row sm={6} className="mx-0 mt-0 mb-3 mb-sm-1 px-0 py-0">
              <Col sm={6} className="px-4 px-sm-0">
                <span className="d-block">
                  {currencyFormatter.format(property.mortgage?.value || 0)}
                </span>
              </Col>
              <Col sm={6} className="px-3 px-sm-0 pt-1 pt-sm-0">
                <Button
                  color="primary"
                  className="w-100"
                  block
                  onClick={(): void => editProperty(property)}
                >
                  {t('features.calculator.realestate.details')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      ))}

      <Button
        title="[Add]"
        color="success"
        className="mb-3 col-12 col-sm-4 mt-1"
        onClick={newProperty}
      >
        + {t('features.calculator.netWorth.addItem')}
      </Button>

      <Button
        outline
        color="link"
        onClick={(): void => {
          history.push(backUrl)
        }}
        className="w-100"
      >
        {t('features.calculator.netWorth.confirmBack')}
      </Button>
    </Container>
  )
}

export default RealEstateItemsView
