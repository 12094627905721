/* Import the object which has methods
 to build a survival model. Check out the other model builder objects in the sidebar */
 import { SurvivalModelBuilder } from "@ottawamhealth/pbl-calculator-engine";

 /* Get the model json for the MPoRT model. Replace the modelName const with the 
  name of the model you want to build */
 const modelName = "MPoRT";
 const mportModelJson = require(`@ottawamhealth/pbl-calculator-engine-assets/${
   modelName
 }/model.json`);
 
 /* Build the model */
 const mportModel = SurvivalModelBuilder.buildFromModelJson(mportModelJson);
 
 /* Export the model so we can use it in another module */
 export { mportModel };
 