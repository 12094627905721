import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { DataPoint } from '../../../features/graph/Graph'

let sequentialMeaningLessNumber = 0

export const getToolTip = (point: DataPoint) => {
  const toolTipId = `point-${point.x}-${
    point.y
  }-${sequentialMeaningLessNumber++}`.replace(/\./g, '')

  const ToolTip = () => (
    <UncontrolledTooltip delay={0} fade={false} target={toolTipId}>
      {point.value}
    </UncontrolledTooltip>
  )

  return {
    ToolTip: process.env.NODE_ENV !== 'test' ? ToolTip : () => null,
    toolTipId,
  }
}
