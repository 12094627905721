import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../styles.scss';
import { Container, Button } from 'reactstrap';
import * as quizTypes from '../reducer';
import SelectItemView from './SelectItemView';
import { showLifeExpectancySmoking} from './LifeExpectancy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface Props {
  smokingStatus?: quizTypes.SmokingStatus;
  setSmoking: (s: quizTypes.SetSmoking) => void;
}

const Smoking: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { smokingStatus, setSmoking } = props;

  const [showError, setShowError] = useState<boolean>(false);
  const history = useHistory();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const handleSubmit = () => {
    if (smokingStatus) {
      setButtonDisabled(true)
       if(smokingStatus === "NEVER_SMOKED"){
        showLifeExpectancySmoking(smokingStatus,"")
        // let result = showLifeExpectancySmoking(smokingStatus,"")
        // toast.info(`Predicted Life Expectancy: ${result}`, {
        //   position: 'top-right',
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   onClose: () => {history.push('/alcohol');},
        // });
        history.push('/alcohol');
       }
       if(smokingStatus === 'USED_TO_LESS_PACK_DAILY'){
        showLifeExpectancySmoking(smokingStatus,"")
        history.push('/former-light-smoker');
       }
       if(smokingStatus === 'USED_TO_MORE_PACK_DAILY'){
        showLifeExpectancySmoking(smokingStatus,"")
        history.push('/former-heavy-smoker');
       }
       if(smokingStatus === 'CURRENT_LESS_PACK_DAILY'){
        showLifeExpectancySmoking(smokingStatus,"")
        // let result = showLifeExpectancySmoking(smokingStatus,"")
        // toast.info(`Predicted Life Expectancy: ${result}`, {
        //   position: 'top-right',
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   onClose: () => {history.push('/alcohol');},
        // });
        history.push('/alcohol');
       }
       if(smokingStatus === 'CURRENT_MORE_PACK_DAILY'){
        showLifeExpectancySmoking(smokingStatus,"")
        // let result = showLifeExpectancySmoking(smokingStatus,"")
        // toast.info(`Predicted Life Expectancy: ${result}`, {
        //   position: 'top-right',
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   onClose: () => {history.push('/alcohol');},
        // });
        history.push('/alcohol');
       }

      // {
      //   smokingStatus === 'NEVER_SMOKED' && history.push('/alcohol');
      // }
      // {
      //   smokingStatus === 'USED_TO_LESS_PACK_DAILY' &&
      //     history.push('/former-light-smoker');
      // }
      // {
      //   smokingStatus === 'USED_TO_MORE_PACK_DAILY' &&
      //     history.push('/former-heavy-smoker');
      // }
      // {
      //   smokingStatus === 'CURRENT_LESS_PACK_DAILY' && history.push('/alcohol');
      // }
      // {
      //   smokingStatus === 'CURRENT_MORE_PACK_DAILY' && history.push('/alcohol');
      // }
    } else {
      setShowError(true);
    }
  };

  const options = [
    {
      label: t('features.calculator.lifeExpectancy.smoking.never'),
      value: quizTypes.SmokingStatus.neverSmoked,
    },
    {
      label: t(
        'features.calculator.lifeExpectancy.smoking.useTo_less_than_pack'
      ),
      value: quizTypes.SmokingStatus.useTo_less_than_pack,
    },
    {
      label: t(
        'features.calculator.lifeExpectancy.smoking.useTo_more_than_pack'
      ),
      value: quizTypes.SmokingStatus.useTo_more_than_pack,
    },
    {
      label: t(
        'features.calculator.lifeExpectancy.smoking.current_less_than_pack'
      ),
      value: quizTypes.SmokingStatus.current_less_than_pack,
    },
    {
      label: t(
        'features.calculator.lifeExpectancy.smoking.current_more_than_pack'
      ),
      value: quizTypes.SmokingStatus.current_more_than_pack,
    },
  ];

  return (
    <Container className='content'>
      <h1 className='display-4 text-center title'>
        {t('features.calculator.lifeExpectancy.smoking.title')}
      </h1>
      <div className='mb-2'>
        {options.map((option, index) => (
          <SelectItemView
            key={index}
            label={option.label}
            selected={smokingStatus === option.value}
            onSelect={(): void =>
              setSmoking({
                type: 'set_smoking',
                smokingFrequency: option.value,
              })
            }
          />
        ))}
      </div>
      {showError && (
        <div className='form-error'>
          {t('features.calculator.lifeExpectancy.smoking.errors.empty')}
        </div>
      )}
      <hr />
      <Button disabled={buttonDisabled} color='primary' block size='lg' onClick={handleSubmit}>
        {t('features.getstarted.next')}
      </Button>
      <Button
        outline
        color='link'
        onClick={() => {
          history.goBack();
        }}
        className='w-100'
      >
        {t('features.calculator.lifeExpectancy.smoking.confirmBack')}
      </Button>
      <ToastContainer />
    </Container>
  );
};

export default Smoking;
