import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { currencyFormatter } from '../../../../shared/utils'

interface EducationConfirmProps {
  savingsGoal: number
  projectedSavings: number
  surplus: number
  backUrl: string
}

const EducationConfirm: React.FC<EducationConfirmProps> = (
  props: EducationConfirmProps
) => {
  const history = useHistory()
  const { t } = useTranslation()

  const surplusPositive = props.surplus >= 0
  const surplusDisplay = Math.abs(props.surplus)

  return (
    <div className="text-center mt-3">
      <h4>{t('features.calculator.education.savingsGoal')}</h4>
      <h2 className="mb-1">{currencyFormatter.format(props.savingsGoal)}</h2>
      <h5 className="mb-4">{t('features.calculator.education.forPeriod')}</h5>
      <h4>{t('features.calculator.education.projectedSavings')}</h4>
      <h2 className="mb-1">
        {currencyFormatter.format(props.projectedSavings)}
      </h2>
      <h5 className="mb-4">{t('features.calculator.education.byStudyAge')}</h5>
      <h4>
        {surplusPositive
          ? t('features.calculator.education.surplus')
          : t('features.calculator.education.shortfall')}
      </h4>
      <h2
        className={surplusPositive ? 'mb-1 text-success' : 'mb-1 text-danger'}
      >
        {currencyFormatter.format(surplusDisplay)}
      </h2>
      <Button
        color="primary"
        onClick={() => {
          history.push(props.backUrl)
        }}
      >
        {t('features.calculator.education.confirmBack')}
      </Button>
    </div>
  )
}

export default EducationConfirm
