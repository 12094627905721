import React, { useEffect, useReducer, Reducer } from "react";
import {
	HashRouter as Router,
	Switch,
	Route,
	useHistory,
} from "react-router-dom";
import reducer, { State, Action, initialState } from "./reducer";
import Goals from "./views/Goal";
import Name from "./views/Name";
import YearOfBirth from "./views/YearOfBirth";
import Sex from "./views/Sex";
import Country from "./views/Country";
import HaveChildren from "./views/HaveChildren";
import HaveOthers from "./views/HaveOthers";
import Header from "./views/Header";
import FillInfo from "./views/FillInfo";
import { useTranslation } from "react-i18next";
import Family from "./views/Family";
import Login from "./views/Login";
import * as types from "../../shared/types";

interface GetStartedRoutesProps {
  state: State
  dispatch: React.Dispatch<Action>
  membership?: types.Membership
}

const GetStartedRoutes: React.FC<GetStartedRoutesProps> = ({
	state,
	dispatch,
	membership,
}: GetStartedRoutesProps) => {
	const { t } = useTranslation();
	const history = useHistory();

	useEffect(() => {
		window.gtag("event", "get_started_page_view", {
			page: history.location.pathname,
		});
	}, [history.location]);

	return (
		<Switch>
			<Route exact path={["/", "/goal"]}>
				<div className="getstarted-page">
					<Header selectedIndex={0} />
					<Goals
						goal={state.goal}
						setGoal={(goal?: string): void =>
							dispatch({
								type: "set_goal",
								payload: goal,
							})
						}
					/>
				</div>
			</Route>
			<Route exact path="/name">
				<div className="getstarted-page">
					<Header selectedIndex={1} />
					<Name
						firstName={state.me?.firstName}
						lastName={state.me?.lastName}
						setName={(firstName?: string, lastName?: string): void =>
							dispatch({
								type: "set_name",
								payload: {
									firstName,
									lastName,
								},
							})
						}
					/>
				</div>
			</Route>
			<Route exact path="/year">
				<div className="getstarted-page">
					<Header selectedIndex={2} />
					<YearOfBirth
						name={`${state.me?.firstName} ${state.me?.lastName}`}
						year={state.me?.birthYear}
						setYear={(year?: number): void =>
							dispatch({
								type: "set_year",
								payload: year,
							})
						}
					/>
				</div>
			</Route>
			<Route exact path="/sex">
				<div className="getstarted-page">
					<Header selectedIndex={3} />
					<Sex
						name={`${state.me?.firstName} ${state.me?.lastName}`}
						sex={state.me?.sex}
						setSex={(sex?: types.Sex): void =>
							dispatch({
								type: "set_sex",
								payload: sex,
							})
						}
					/>
				</div>
			</Route>
			<Route exact path="/country">
				<div className="getstarted-page">
					<Header selectedIndex={4} />
					<Country
						name={`${state.me?.firstName} ${state.me?.lastName}`}
						country={state.me?.country}
						setCountry={(country?: string): void =>
							dispatch({
								type: "set_country",
								payload: country,
							})
						}
					/>
				</div>
			</Route>
			<Route exact path="/confirm-add-child">
				<div className="getstarted-page">
					<Header selectedIndex={5} />
					<HaveChildren name={`${state.me?.firstName} ${state.me?.lastName}`} />
				</div>
			</Route>
			<Route exact path="/confirm-add-other">
				<div className="getstarted-page">
					<Header selectedIndex={5} />
					<HaveOthers name={`${state.me?.firstName} ${state.me?.lastName}`} />
				</div>
			</Route>
			<Route exact path="/add-partner">
				<div className="getstarted-page">
					<Header selectedIndex={5} />
					<FillInfo
						name={`${state.me?.firstName} ${state.me?.lastName}`}
						title={t("features.getstarted.partner.title")}
						setPerson={person =>
							dispatch({
								type: "set_partner_info",
								payload: person,
							})
						}
						nextRoute="/confirm-add-child"
						cancelRoute="/confirm-add-child"
					/>
				</div>
			</Route>
			<Route exact path={["/add-child", "/add-child-*"]}>
				<div className="getstarted-page">
					<Header selectedIndex={5} />
					<FillInfo
						name={`${state.me?.firstName} ${state.me?.lastName}`}
						title={t("features.getstarted.child.title")}
						setPerson={(person): void =>
							dispatch({
								type: "set_child_info",
								payload: person,
							})
						}
						nextRoute="/confirm-add-other"
						cancelRoute="/confirm-add-other"
						addAnother
						preserveLastName
					/>
				</div>
			</Route>
			<Route exact path="/add-other">
				<div className="getstarted-page">
					<Header selectedIndex={5} />
					<FillInfo
						name={`${state.me?.firstName} ${state.me?.lastName}`}
						title={t("features.getstarted.other.title")}
						setPerson={person =>
							dispatch({
								type: "set_other_info",
								payload: person,
							})
						}
						nextRoute="/confirm-family"
						addAnother
					/>
				</div>
			</Route>
			<Route exact path="/confirm-family">
				<div className="getstarted-page">
					<Header selectedIndex={10} />
					<Family family={state} isLoggedIn={!!membership} />
				</div>
			</Route>
			<Route exact path="/login">
				<div className="getstarted-page">
					<Header selectedIndex={11} />
					<Login family={state} />
				</div>
			</Route>
		</Switch>
	);
};

interface GetStartedProps {
  membership?: types.Membership
}

const GetStarted: React.FC<GetStartedProps> = (props: GetStartedProps) => {
	const [state, dispatch] = useReducer<Reducer<State, Action>>(
		reducer,
		props.membership
			? {
				me: props.membership.person,
				children: [],
				others: [],
				membershipId: props.membership.id,
			}
			: initialState
	);

	return (
		<Router>
			<GetStartedRoutes
				state={state}
				dispatch={dispatch}
				membership={props.membership}
			/>
		</Router>
	);
};

export default GetStarted;
