import { gql } from 'apollo-boost'
import { ContributionType, Income, Account } from './types'

export const RETIREMENT_FRAGMENT = gql`
  fragment RetirementFragment on RetirementDetails {
    id
    currentIncome
    incomeType
    predictedExpenses
    expensesType
    postRetirementGrowthRate
    accounts {
      id
      annualGrowth
      contribution
      contributionType
      name
      value
    }
    incomes {
      id
      name
      contribution
      contributionType
    }
    projectedSavings
    savingsGoal
    surplus
  }
`
export const RETIREMENT_QUERY = gql`
  query {
    me {
      isRetired
    }
    retirement {
      ...RetirementFragment
    }
  }

  ${RETIREMENT_FRAGMENT}
`

export interface RetirementQuery {
  me: {
    isRetired: boolean
  }
  retirement: {
    currentIncome: number
    incomeType: ContributionType
    predictedExpenses: number
    expensesType: ContributionType
    postRetirementGrowthRate: number
    accounts: Account[]
    incomes: Income[]
    projectedSavings: number
    savingsGoal: number
    surplus: number
  } | null
}

export const RETIREMENT_MUTATION = gql`
  mutation UpdateRetirementDetails(
    $currentIncome: Float!
    $incomeType: String!
    $predictedExpenses: Float!
    $expensesType: String!
    $postRetirementGrowthRate: Float!
    $accounts: [RetirementAccountInput]!
    $incomes: [RetirementIncomeInput]!
  ) {
    updateRetirementDetails(
      currentIncome: $currentIncome
      incomeType: $incomeType
      predictedExpenses: $predictedExpenses
      expensesType: $expensesType
      postRetirementGrowthRate: $postRetirementGrowthRate
      accounts: $accounts
      incomes: $incomes
    ) {
      ...RetirementFragment
    }
  }

  ${RETIREMENT_FRAGMENT}
`

export const RETIREMENT_ACCOUNTS_REFETCH = gql`
  query {
    retirement {
      id
      accounts {
        id
        name
        value
      }
    }
  }
`
