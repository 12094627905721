import React, { SVGProps } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ObolxIcon,
  TravelIcon,
  FitnessIcon,
  HousingIcon,
  ChildIcon,
  MedicalIcon,
  FinanceIcon,
  GlobeIcon,
  UniversityIcon,
} from 'shared/views/Icon'

import profile1 from '../images/intro/profile_1.png'
import profile2 from '../images/intro/profile_2.png'
import { Button, ButtonGroup } from 'reactstrap'
import '../../../shared/views/person/ProfilePicture.scss'

interface IntroNoteProps {
  icon: React.FC<SVGProps<SVGSVGElement>>
}

// A dummy version of the timeline's ruler notes.
// We don't want to use the actual component because it (will be) interactive
const IntroNote: React.FC<IntroNoteProps> = (props: IntroNoteProps) => {
  return (
    <div className="ruler-note">
      <div className="ruler-note content-box">
        <props.icon className="ruler-note content-img" />
      </div>
    </div>
  )
}

interface IntroProfileProps {
  firstName: string
  lastName: string
}

// Ditto, but for profile photos
const IntroProfile: React.FC<IntroProfileProps> = (
  props: IntroProfileProps
) => {
  const { firstName, lastName } = props

  return (
    <svg className={`svg `} viewBox="-21 -21 42 42" width="42" height="42">
      <circle cx="0" cy="0" r="21" />
      <text x="0" y="0" dominantBaseline="central" textAnchor="middle">
        {`${firstName && [...firstName][0]}${lastName && [...lastName][0]}`}
      </text>
    </svg>
  )
}

interface TimelineIntroPageProps {
  onClose: () => void
}

const TimelineIntroPage: React.FC<TimelineIntroPageProps> = (
  props: TimelineIntroPageProps
) => {
  const { t } = useTranslation()

  return (
    <div className="container">
      <span className="text-center intro-title">
        {t('features.timeline.intro.title')}
      </span>
      <div className="intro-row">
        <IntroNote icon={ObolxIcon} />
        <IntroNote icon={TravelIcon} />
        <IntroNote icon={UniversityIcon} />
        <IntroNote icon={FitnessIcon} />
        <IntroNote icon={HousingIcon} />
        <IntroNote icon={ChildIcon} />
      </div>
      <p className="text-center">{t('features.timeline.intro.addNotes')}</p>
      <div className="intro-row">
        <div className="profile-picture circle">
          <img src={profile1} alt="" />
        </div>
        <div className="profile-picture circle">
          <img src={profile2} alt="" />
        </div>
        <IntroProfile firstName="C" lastName="W" />
        <IntroProfile firstName="A" lastName="W" />
      </div>
      <p className="text-center">{t('features.timeline.intro.viewDash')}</p>
      <div className="intro-row">
        <ButtonGroup className="intro-icons">
          <Button color="primary">
            <MedicalIcon />
          </Button>
          <Button color="primary">
            <FinanceIcon />
          </Button>
          <Button color="primary">
            <GlobeIcon />
          </Button>
        </ButtonGroup>
      </div>
      <p className="text-center">{t('features.timeline.intro.iconsDesc')}</p>
      <div className="intro-row intro-own-info">
        <div>
          <span>{t('features.timeline.intro.ownInfo')}</span>
        </div>
      </div>
      <Button onClick={props.onClose} color="primary" size="lg" block>
        {t('features.timeline.intro.close')}
      </Button>
    </div>
  )
}

export default TimelineIntroPage
