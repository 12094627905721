import React from "react";
import "./ConfirmModal.scss";
import * as yup from "yup";

import {
	Modal,
	ModalBody,
	ModalFooter,
	Button,
	Form,
	Input,
	Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Formik, FormikProps, ErrorMessage, Field } from "formik";

interface ConfirmationModalProps {
  isOpen: boolean
  title: string
  description?: string
  closeModal: () => void
  confirm: () => void
  confirmText?: string
  cancelText?: string
  repeatString: string
}

interface FormValues {
  repeat: string
}

export const ConfirmationModalInputBody: React.FC<ConfirmationModalProps> = ({
	title,
	description,
	closeModal,
	confirm,
	confirmText = "Confirm",
	cancelText = "Cancel",
	repeatString,
}: ConfirmationModalProps) => {
	const { t } = useTranslation();

	const validationSchema = yup.object({
		repeat: yup
			.string()
			.oneOf([repeatString, undefined], t("shared.confirmation.errors.mismatch"))
			.required(t("shared.confirmation.errors.empty")),
	});

	return (
		<>
			<div className="confirmation-modal header">
				<h3>{title}</h3>
			</div>
			<Formik
				initialValues={{ repeat: "" }}
				onSubmit={confirm}
				validationSchema={validationSchema}
				validateOnBlur={false}
				validateOnChange={false}
				validateOnMount={false}
			>
				{(formik: FormikProps<FormValues>) => (
					<Form
						onSubmit={async (event: React.FormEvent): Promise<void> => {
							event.preventDefault();
							await formik.submitForm();
						}}
						className="mt-3"
					>
						<ModalBody>
							{description ? (
								<div className="confirmation-modal text">
									<p className="mb-0">{description}</p>
								</div>
							) : null}
							<div className="confirmation-modal input">
								<p
									className="mb-1"
									dangerouslySetInnerHTML={{
										__html: t("shared.confirmation.input_html", {
											string: repeatString,
										}),
									}}
								/>
								<Field
									as={Input}
									name="repeat"
									disabled={formik.isSubmitting}
									className="mb-2"
								/>
								<ErrorMessage name="repeat">
									{msg => <div className="form-error">{msg}</div>}
								</ErrorMessage>
							</div>
						</ModalBody>
						<ModalFooter>
							{formik.isSubmitting ? (
								<Spinner />
							) : (
								<div className="d-flex justify-content-around w-100">
									<Button
										color="link"
										className="note-edit-modal-done"
										type="submit"
									>
										<h3 className="font-weight-light">{confirmText}</h3>
									</Button>
									<Button
										onClick={closeModal}
										color="link"
										className="note-edit-modal-done"
									>
										<h3>{cancelText}</h3>
									</Button>
								</div>
							)}
						</ModalFooter>
					</Form>
				)}
			</Formik>
		</>
	);
};

const ConfirmationModalInput: React.FC<ConfirmationModalProps> = (
	props: ConfirmationModalProps
) => {
	return (
		<Modal
			isOpen={props.isOpen}
			toggle={props.closeModal}
			centered
			contentClassName="confirmation-modal content"
		>
			<ConfirmationModalInputBody {...props} />
		</Modal>
	);
};

export default ConfirmationModalInput;
