import React from "react";
import { Navbar, Nav, NavItem, NavbarText } from "reactstrap";
import { useTranslation } from "react-i18next";
import "../styles.scss";

interface Props {
  progress: number
  total: number
}

// TODO: replace i18n fir lifeExpectancy.nav with: "Life Expectancy Quiz ({{progress}} of {{total}})",

const Header: React.FC<Props> = ({ progress, total }: Props) => {
	const { t } = useTranslation();
	return (
		<Navbar color="primary" className="text-white justify-content-center">
			<Nav className="justify-content-center">
				<NavItem>
					<NavbarText>
						{t("features.calculator.lifeExpectancy.nav", { progress, total })}
					</NavbarText>
				</NavItem>
			</Nav>
		</Navbar>
	);
};

export default Header;
