import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { MortgageFrequency } from '../types'
import { currencyFormatter } from '../../../../shared/utils'

interface RealEstateConfirmProps {
  termInterest: number
  totalInterest: number
  payment: number
  netEquity: number
  backUrl: string
  frequency: MortgageFrequency
}

const RealEstateConfirm: React.FC<RealEstateConfirmProps> = (
  props: RealEstateConfirmProps
) => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <div className="text-center mt-3">
      <h4>{t('features.calculator.realestate.termInterest')}</h4>
      <h2 className="mb-4">{currencyFormatter.format(props.termInterest)}</h2>
      <h4>{t('features.calculator.realestate.totalInterest')}</h4>
      <h2 className="mb-4">{currencyFormatter.format(props.totalInterest)}</h2>
      <h4>
        {t('features.calculator.realestate.payment', {
          frequency: t(
            `features.calculator.realestate.frequency.${props.frequency}`
          ),
        })}
      </h4>
      <h2 className="mb-4">{currencyFormatter.format(props.payment)}</h2>
      <h4>{t('features.calculator.realestate.netEquity')}</h4>
      <h2 className="mb-4">{currencyFormatter.format(props.netEquity)}</h2>
      <Button
        color="primary"
        onClick={() => {
          history.push(props.backUrl)
        }}
      >
        {t('features.calculator.realestate.confirmBack')}
      </Button>
    </div>
  )
}

export default RealEstateConfirm
