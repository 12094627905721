import React from 'react'
import * as yup from 'yup'
import { SetExtras } from '../reducer'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Form,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap'
import { Formik, FormikProps, Field, ErrorMessage } from 'formik'
import { useHistory } from 'react-router-dom'

interface ExtrasProps {
  hoursSleepPerNight?: number
  stressRating?: number
  // happinessRating?: number
  hoursSocializing?: number
  // postalCode?: string
  finishForm: (s: SetExtras) => Promise<void>
}

interface FormValues {
  hoursSleepPerNight?: number
  stressRating?: number
  // happinessRating?: number
  hoursSocializing?: number
  // postalCode?: string
}

const Extras: React.FC<ExtrasProps> = (props: ExtrasProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleSubmit = async (values: FormValues) => {
    if (
      values.hoursSleepPerNight &&
      values.stressRating &&
      // values.happinessRating &&
      values.hoursSocializing 
      // values.postalCode
    ) {
      history.push('/results')
      await props.finishForm({
        type: 'set_extras',
        hoursSleepPerNight: values.hoursSleepPerNight,
        stressRating: values.stressRating,
        hoursSocializing: values.hoursSocializing
        // happinessRating: values.happinessRating,
        
        // postalCode: values.postalCode,
      })
     
    }
  }

  const validationSchema = yup.object().shape({
    hoursSleepPerNight: yup
      .number()
      .required(
        t('features.calculator.lifeExpectancy.extras.sleep.errors.empty')
      )
      .min(
        1,
        t('features.calculator.lifeExpectancy.extras.sleep.errors.outOfRange')
      )
      .max(
        10,
        t('features.calculator.lifeExpectancy.extras.sleep.errors.outOfRange')
      )
      .integer(
        t('features.calculator.lifeExpectancy.extras.sleep.errors.outOfRange')
      ),
    stressRating: yup
      .number()
      .required(
        t('features.calculator.lifeExpectancy.extras.stress.errors.empty')
      )
      .min(
        1,
        t('features.calculator.lifeExpectancy.extras.stress.errors.outOfRange')
      )
      .max(
        10,
        t('features.calculator.lifeExpectancy.extras.stress.errors.outOfRange')
      )
      .integer(
        t('features.calculator.lifeExpectancy.extras.stress.errors.outOfRange')
      ),
    // happinessRating: yup
    //   .number()
    //   .required(
    //     t('features.calculator.lifeExpectancy.extras.happiness.errors.empty')
    //   )
    //   .min(
    //     1,
    //     t(
    //       'features.calculator.lifeExpectancy.extras.happiness.errors.outOfRange'
    //     )
    //   )
    //   .max(
    //     10,
    //     t(
    //       'features.calculator.lifeExpectancy.extras.happiness.errors.outOfRange'
    //     )
    //   )
    //   .integer(
    //     t(
    //       'features.calculator.lifeExpectancy.extras.happiness.errors.outOfRange'
    //     )
    //   ),
    hoursSocializing: yup
      .number()
      .required(
        t('features.calculator.lifeExpectancy.extras.socializing.errors.empty')
      )
      .min(
        1,
        t(
          'features.calculator.lifeExpectancy.extras.socializing.errors.outOfRange'
        )
      )
      .max(
        10,
        t(
          'features.calculator.lifeExpectancy.extras.socializing.errors.outOfRange'
        )
      )
      .integer(
        t(
          'features.calculator.lifeExpectancy.extras.socializing.errors.outOfRange'
        )
      ),
    // postalCode: yup
    //   .string()
    //   .required(
    //     t('features.calculator.lifeExpectancy.extras.postalCode.errors.empty')
    //   ),
  })

  return (
    <Container className="content">
      <h1 className="display-4 text-center title mb-3">
        {t('features.calculator.lifeExpectancy.extras.title')}
      </h1>
      <Formik
        initialValues={{
          hoursSleepPerNight: props.hoursSleepPerNight,
          stressRating: props.stressRating,
          // happinessRating: props.happinessRating,
          hoursSocializing: props.hoursSocializing,
          // postalCode: props.postalCode,
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
      >
        {({ submitForm, errors, isSubmitting }: FormikProps<FormValues>) => (
          <Form
            onSubmit={e => {
              e.preventDefault()
              if (!isSubmitting) {
                submitForm()
              }
            }}
          >
            <h6>
              {t('features.calculator.lifeExpectancy.extras.sleep.label')}
            </h6>
            <InputGroup className="mb-2">
              <Field
                as={Input}
                type="number"
                name="hoursSleepPerNight"
                placeholder={t(
                  'features.calculator.lifeExpectancy.extras.sleep.placeholder'
                )}
                className={`goal custom${
                  errors.hoursSleepPerNight ? ' has-errors' : ''
                }`}
              />
              <InputGroupAddon
                addonType="append"
                className={`${errors.hoursSleepPerNight ? 'has-errors' : ''}`}
              >
                {t('features.calculator.lifeExpectancy.units.time')}
              </InputGroupAddon>
            </InputGroup>
            <ErrorMessage name="hoursSleepPerNight">
              {msg => <div className="form-error">{msg}</div>}
            </ErrorMessage>
            <h6>
              {t('features.calculator.lifeExpectancy.extras.stress.label')}
            </h6>
            <Field
              as={Input}
              type="number"
              name="stressRating"
              placeholder={t(
                'features.calculator.lifeExpectancy.extras.stress.placeholder'
              )}
              className={`goal custom${
                errors.stressRating ? ' has-errors' : ''
              }`}
            />
            <ErrorMessage name="stressRating">
              {msg => <div className="form-error">{msg}</div>}
            </ErrorMessage>
            {/* <h6>
              {t('features.calculator.lifeExpectancy.extras.happiness.label')}
            </h6>
            <Field
              as={Input}
              type="number"
              name="happinessRating"
              placeholder={t(
                'features.calculator.lifeExpectancy.extras.happiness.placeholder'
              )}
              className={`goal custom${
                errors.happinessRating ? ' has-errors' : ''
              }`}
            />
            <ErrorMessage name="happinessRating">
              {msg => <div className="form-error">{msg}</div>}
            </ErrorMessage> */}
            <h6>
              {t('features.calculator.lifeExpectancy.extras.socializing.label')}
            </h6>
            <InputGroup className="mb-2">
              <Field
                as={Input}
                type="number"
                name="hoursSocializing"
                placeholder={t(
                  'features.calculator.lifeExpectancy.extras.socializing.placeholder'
                )}
                className={`goal custom${
                  errors.hoursSocializing ? ' has-errors' : ''
                }`}
              />
              <InputGroupAddon
                addonType="append"
                className={`${errors.hoursSocializing ? 'has-errors' : ''}`}
              >
                {t('features.calculator.lifeExpectancy.units.time')}
              </InputGroupAddon>
            </InputGroup>
            <ErrorMessage name="hoursSocializing">
              {msg => <div className="form-error">{msg}</div>}
            </ErrorMessage>
            {/* <h6>
              {t('features.calculator.lifeExpectancy.extras.postalCode.label')}
            </h6>
            <Field
              as={Input}
              type="text"
              name="postalCode"
              placeholder={t(
                'features.calculator.lifeExpectancy.extras.postalCode.placeholder'
              )}
              className={`goal custom${errors.postalCode ? ' has-errors' : ''}`}
            />
            <ErrorMessage name="postalCode">
              {msg => <div className="form-error">{msg}</div>}
            </ErrorMessage> */}
            <Button
              type="submit"
              color="primary"
              block
              size="lg"
              disabled={isSubmitting}
            >
              {t('features.getstarted.next')}
            </Button>
            <Button
              outline
              color='link'
              onClick={() => {
                history.goBack();
              }}
              className='w-100'
            >
              {"Go Back"}
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default Extras
