import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
	useHistory,
	useRouteMatch,
	Switch,
	Route,
	useParams,
	Redirect,
} from "react-router-dom";
import {
	PropertyInput,
	PropertyMutationInput,
	Property,
	MortgageFrequency,
} from "./types";
import * as queries from "./queries";
import LoadingSpinner from "shared/views/spinner/LoadingSpinner";
import Header from "shared/views/Header";
import { useTranslation } from "react-i18next";
import { apolloErrorToString } from "shared/utils/api/ApolloClient";
import RealEstateItemsView from "./views/RealEstateItemsView";
import RealEstateCalculatorForm from "./views/RealEstateCalculatorForm";
import RealEstateConfirm from "./views/RealEstateConfirm";
import { NET_WORTH_REFETCH } from "../networth/queries";
import { REAL_ESTATE_GRAPH_REFETCH } from "features/graph/finances/queries";

const RealEstateCalculator: React.FC = () => {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const { membershipId } = useParams();
	const match = useRouteMatch();

	const [selectedPropertyId, setSelectedPropertyId] = useState<
    number | undefined
  >(undefined);

	const realEstateQuery = useQuery<queries.RealEstateQuery>(
		queries.REAL_ESTATE_QUERY
	);

	const [
		submitForm,
		{ error: updateError, loading: updateLoading, data: updateData },
	] = useMutation<queries.RealEstateMutation, PropertyMutationInput>(
		queries.UPDATE_REAL_ESTATE_MUTATION
	);

	const [
		deleteProperty,
		{ error: deleteError, loading: deleteLoading },
	] = useMutation<boolean, queries.DeleteMutationInput>(
		queries.DELETE_PROPERTY_MUTATION
	);

	useEffect(() => {
		window.gtag("event", "realestate_calculator_page_view", {
			page: history.location.pathname,
		});
	}, [history.location]);

	if (!realEstateQuery.data || realEstateQuery.loading) {
		return <LoadingSpinner />;
	}

	const idOrEmpty = selectedPropertyId ? { id: selectedPropertyId } : {};

	const selectedProperty = realEstateQuery.data.realEstate.properties.find(
		p => p.id === selectedPropertyId
	);

	return (
		<Switch>
			<Route path={`${match.url}/results`}>
				<Header text={t("features.calculator.realestate.resultsHeader")} />
				{updateData?.updateRealEstateProperty && (
					<RealEstateConfirm
						termInterest={
              updateData.updateRealEstateProperty.mortgage?.termInterest || 0
						}
						totalInterest={
              updateData.updateRealEstateProperty.mortgage?.totalInterest || 0
						}
						payment={
              updateData.updateRealEstateProperty.mortgage?.periodPayment || 0
						}
						frequency={
              updateData.updateRealEstateProperty.mortgage?.paymentFreq ||
              MortgageFrequency.Monthly
						}
						netEquity={
              updateData.updateRealEstateProperty.mortgage?.netEquity || 0
						}
						backUrl={match.url}
					/>
				)}
				{!updateData?.updateRealEstateProperty && <Redirect to={match.url} />}
			</Route>
			<Route path={`${match.url}/property`}>
				<Header text={t("features.calculator.realestate.formHeader")} />
				<RealEstateCalculatorForm
					property={selectedProperty}
					onSubmit={async (values: PropertyInput) => {
						await submitForm({
							variables: {
								propertyInput: {
									...idOrEmpty,
									...values,
								},
							},
							refetchQueries: [
								{
									query: queries.REAL_ESTATE_QUERY,
								},
								{
									query: NET_WORTH_REFETCH,
								},
								{
									query: REAL_ESTATE_GRAPH_REFETCH,
								},
							],
							awaitRefetchQueries: true,
						});
						setSelectedPropertyId(undefined);
						if (values.mortgage) history.push(`${match.url}/results`);
						else history.push(match.url);
					}}
					mutationError={
						updateError
							? apolloErrorToString(updateError, i18n)
							: deleteError
								? apolloErrorToString(deleteError, i18n)
								: undefined
					}
					deleteProperty={async () => {
						await deleteProperty({
							variables: {
								id: selectedPropertyId || -1,
							},
							refetchQueries: [
								{
									query: queries.REAL_ESTATE_QUERY,
								},
								{
									query: NET_WORTH_REFETCH,
								},
								{
									query: REAL_ESTATE_GRAPH_REFETCH,
								},
							],
							awaitRefetchQueries: true,
						});
						setSelectedPropertyId(undefined);
						history.push(match.url);
					}}
					loading={updateLoading || deleteLoading}
					backUrl={match.url}
				/>
			</Route>
			<Route path={match.url}>
				<Header text={t("features.calculator.realestate.listHeader")} />
				<RealEstateItemsView
					propertyList={realEstateQuery.data.realEstate.properties}
					editProperty={async (p: Property) => {
						await setSelectedPropertyId(p.id);
						history.push(`${match.url}/property`);
					}}
					newProperty={() => {
						setSelectedPropertyId(undefined);
						history.push(`${match.url}/property`);
					}}
					backUrl={`/dashboard/${membershipId}/`}
				/>
			</Route>
		</Switch>
	);
};

export default RealEstateCalculator;
