import React from 'react'
import { Navbar, Nav, NavItem, NavbarText } from 'reactstrap'

interface HeaderProps {
  text: string
}

const Header: React.FC<HeaderProps> = ({ text }: HeaderProps) => {
  return (
    <Navbar color="primary" className="text-white justify-content-center">
      <Nav className="justify-content-center">
        <NavItem>
          <NavbarText>{text}</NavbarText>
        </NavItem>
      </Nav>
    </Navbar>
  )
}

export default Header
