import React from "react";
import * as types from "../../../shared/types";
import Header from "../../../shared/views/Header";
import { useTranslation } from "react-i18next";
import { Button, Form, Input } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Formik, FormikProps, Field, ErrorMessage } from "formik";
import * as yup from "yup";

import "../styles.scss";

interface AccountManagementPageProps {
  person: types.Person
  email: string
  editEmailError: string
  handleEmail: (newEmail: string) => void
}

interface FormValues {
  email: string
  confirm: string
}

const AccountManagementPage: React.FC<AccountManagementPageProps> = ({
	email,
	editEmailError,
	handleEmail,
}: AccountManagementPageProps) => {
	const { t } = useTranslation();
	const history = useHistory();

	const validationSchema = yup.object({
		email: yup
			.string()
			.email()
			.required(t("features.account.home.email.errors.email")),
		confirm: yup
			.string()
			.oneOf(
				[yup.ref("email"), undefined],
				t("features.account.home.email.errors.confirm")
			)
			.required(t("features.account.home.email.errors.confirm")),
	});

	const handleSubmit: (values: FormValues) => Promise<void> = async (
		values: FormValues
	) => {
		handleEmail(values.email);
	};

	return (
		<>
			<Header text={t("features.account.home.nav")} />
			<div className="account-home">
				<h2>{t("features.account.home.title")}</h2>
				<hr />
				<h3>{t("features.account.home.email.title")}</h3>
				<div className="email d-flex flex-column">
					<span>
						{t("features.account.home.email.current", { email: email })}
					</span>
					<Formik
						initialValues={{ email: "", confirm: "" }}
						onSubmit={handleSubmit}
						validationSchema={validationSchema}
						validateOnBlur={false}
						validateOnChange={false}
						validateOnMount={false}
					>
						{(formik: FormikProps<FormValues>) => (
							<Form
								onSubmit={async (event: React.FormEvent): Promise<void> => {
									event.preventDefault();
									await formik.submitForm();
								}}
								className="mt-3"
							>
								<span>{t("features.account.home.email.new")}</span>
								<Field
									as={Input}
									type="email"
									name="email"
									disabled={formik.isSubmitting}
									className={"note-edit-modal-field"}
								/>
								<ErrorMessage name="email">
									{msg => <div className="form-error">{msg}</div>}
								</ErrorMessage>
								<span>{t("features.account.home.email.confirm")}</span>
								<Field
									as={Input}
									name="confirm"
									disabled={formik.isSubmitting}
									className={"note-edit-modal-field"}
								/>
								<ErrorMessage name="confirm">
									{msg => <div className="form-error">{msg}</div>}
								</ErrorMessage>
								<div className="form-error">{editEmailError}</div>
								<Button type="submit" color="primary">
									{t("features.account.home.email.submit")}
								</Button>
							</Form>
						)}
					</Formik>
				</div>
				<hr />
				<Button
					outline
					color="link"
					onClick={() => history.push("/timeline")}
					className="w-100 py-3 mt-3"
				>
					{t("features.dashboard.home.back")}
				</Button>
			</div>
		</>
	);
};

export default AccountManagementPage;
