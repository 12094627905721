import { gql } from 'apollo-boost'
import { Item } from './types'

export interface NetWorthQuery {
  netWorth: {
    netWorth: number
    wealthScore: number
    items: Item[]
  }
}

const NET_WORTH_ITEM_FRAGMENT = gql`
  fragment NetWorthInfo on NetWorth {
    netWorth
    wealthScore
    items {
      id
      itemType
      name
      value
      ownershipPercent
    }
  }
`

export const NET_WORTH_QUERY = gql`
  query {
    netWorth {
      ...NetWorthInfo
    }
  }

  ${NET_WORTH_ITEM_FRAGMENT}
`

export const UPDATE_NET_WORTH_MUTATION = gql`
  mutation UpdateNetWorth($items: [NetWorthInput]!) {
    updateNetWorth(items: $items) {
      ...NetWorthInfo
    }
  }

  ${NET_WORTH_ITEM_FRAGMENT}
`

export const NET_WORTH_REFETCH = gql`
  query {
    netWorth {
      items {
        id
        name
        value
      }
    }
  }
`
