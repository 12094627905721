import React from 'react'
import { MILESTONE_ICONS, Note } from 'shared/types'
import AxisTranslator from 'shared/utils/axis'
import { NotesEmptyIcon } from 'shared/views/Icon'

interface TimelineRulerNoteProps {
  note: Note
  year: number
  start: number
  onViewNote: () => void
}

const TimelineRulerNote: React.FC<TimelineRulerNoteProps> = (
  props: TimelineRulerNoteProps
) => {
  const Icon = MILESTONE_ICONS[props.note.type]

  return (
    <div
      className="ruler-note bottom-aligner"
      style={{ left: `${AxisTranslator.year(props.year - props.start)}px` }}
    >
      <div className="ruler-note top-aligner">
        <div className="ruler-note vert-line" />
        <button
          className="ruler-note content-button"
          onClick={props.onViewNote}
        >
          <div className="ruler-note content-box">
            <Icon className="ruler-note content-img" />
          </div>
        </button>
      </div>
    </div>
  )
}

interface TimelineRulerNoteMultiProps {
  notes: Note[]
  year: number
  start: number
  onViewNotes: () => void
}

export const TimelineRulerNoteMulti: React.FC<TimelineRulerNoteMultiProps> = (
  props: TimelineRulerNoteMultiProps
) => {
  return (
    <div
      className="ruler-note bottom-aligner"
      style={{ left: `${AxisTranslator.year(props.year - props.start)}px` }}
    >
      <div className="ruler-note top-aligner">
        <div className="ruler-note vert-line" />
        <button
          className="ruler-note content-button"
          onClick={props.onViewNotes}
        >
          <div className="ruler-note content-box">
            <NotesEmptyIcon className="ruler-note content-img" />
          </div>
        </button>
      </div>
    </div>
  )
}

export default TimelineRulerNote
