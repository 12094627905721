import React, {
	useState,
	MutableRefObject,
	useEffect,
	useRef,
	useCallback,
	Dispatch,
	SetStateAction,
} from "react";
import "../styles.scss";
import { Profile } from "shared/types";
import MinimapSummary from "./MinimapSummary";
import { HeaderItem } from "./NavbarTimeline";

interface MinimapProps {
  targetRef: MutableRefObject<HTMLDivElement>
  selectedHeaderItem: HeaderItem
  onInfoClick: Dispatch<SetStateAction<boolean>>
  moveTarget: (x: number) => void
  profiles?: Array<Profile>
}

const Minimap = ({ targetRef, moveTarget, profiles, selectedHeaderItem, onInfoClick }: MinimapProps) => {
	const [viewWidth, setViewWidth] = useState(0);
	const [scrollWidth, setScrollWidth] = useState(1);
	const [scrollPercentage, setScrollPercentage] = useState(0);
	const [isDragging, setIsDragging] = useState(false);
	const [boxWidth, setBoxWidth] = useState(0);

	const [lastTouchX, setLastTouchX] = useState(0);

	const getScrollPos = useCallback(
		(element: HTMLElement) => {
			const percentage = element?.scrollLeft / (scrollWidth - viewWidth);
			setScrollPercentage(percentage);
		},
		[scrollWidth, viewWidth]
	);

	const onScroll = useCallback(
		(evt: Event) => {
			getScrollPos(evt.target as HTMLElement);
		},
		[getScrollPos]
	);

	const updateSize = useCallback(() => {
		const cw = targetRef.current?.clientWidth || 1;
		const sw = targetRef.current?.scrollWidth || 1;
		setViewWidth(cw);
		setScrollWidth(sw);
		setBoxWidth((cw * cw) / sw);
	}, [targetRef, setViewWidth, setScrollWidth, setBoxWidth]);

	useEffect(() => {
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => {
			window.removeEventListener("resize", updateSize);
		};
	}, [targetRef, viewWidth, scrollWidth, updateSize]);

	useEffect(() => {
		const currentRef = targetRef.current || document.createElement("div");
		currentRef.addEventListener("scroll", onScroll);
		return () => {
			currentRef.removeEventListener("scroll", onScroll);
		};
	}, [targetRef, viewWidth, scrollWidth, onScroll]);

	useEffect(() => {
		getScrollPos(targetRef.current);
	}, [getScrollPos, targetRef]);

	const ref = useRef<HTMLDivElement>(document.createElement("div"));

	const handleStart = () => {
		setIsDragging(true);
	};

	const handleEnd = () => {
		setIsDragging(false);
	};

	const handleDrag = useCallback(
		(deltaX: number) => {
			if (isDragging) {
				const percentage = deltaX / (viewWidth - boxWidth);
				moveTarget(percentage * (scrollWidth - viewWidth));
			}
		},
		[isDragging, viewWidth, boxWidth, scrollWidth, moveTarget]
	);

	const handleMouseDown = useCallback((event: MouseEvent) => {
		handleStart();
		event.preventDefault();
	}, []);

	const handleMouseUp = useCallback(() => {
		handleEnd();
	}, []);

	const handleMouseDrag = useCallback(
		(event: MouseEvent) => {
			handleDrag(event.movementX);
			event.preventDefault();
		},
		[handleDrag]
	);

	const handleTouchStart = useCallback(
		(event: TouchEvent) => {
			setIsDragging(true);
			setLastTouchX(event.targetTouches[0].clientX);
			event.preventDefault();
		},
		[setLastTouchX]
	);

	const handleTouchEnd = useCallback(() => {
		setIsDragging(false);
	}, []);

	const handleTouchDrag = useCallback(
		(event: TouchEvent) => {
			handleDrag(event.targetTouches[0].clientX - lastTouchX);
			setLastTouchX(event.targetTouches[0].clientX);
			event.preventDefault();
		},
		[lastTouchX, setLastTouchX, handleDrag]
	);

	useEffect(() => {
		const currentRef = ref.current || document.createElement("div");

		currentRef.addEventListener("mousedown", handleMouseDown);
		window.addEventListener("mouseup", handleMouseUp);
		document.addEventListener("mouseleave", handleMouseUp);
		window.addEventListener("mousemove", handleMouseDrag);

		currentRef.addEventListener("touchstart", handleTouchStart);
		window.addEventListener("touchend", handleTouchEnd);
		currentRef.addEventListener("touchmove", handleTouchDrag);

		return () => {
			currentRef.removeEventListener("mousedown", handleMouseDown);
			window.removeEventListener("mouseup", handleMouseUp);
			document.removeEventListener("mouseleave", handleMouseUp);
			window.removeEventListener("mousemove", handleMouseDrag);
			currentRef.removeEventListener("touchstart", handleTouchStart);
			window.removeEventListener("touchend", handleTouchEnd);
			currentRef.removeEventListener("touchmove", handleTouchDrag);
		};
	}, [
		ref,
		isDragging,
		lastTouchX,
		handleMouseDown,
		handleMouseDrag,
		handleMouseUp,
		handleTouchDrag,
		handleTouchStart,
		handleTouchEnd,
	]);

	return (
		<div className="minimap minimap-container">
			<div
				className={`minimap scrollbar${isDragging ? " active" : ""}`}
				ref={ref}
				style={{
					width: `${boxWidth}px`,
					transform: `translateX( ${scrollPercentage *
            (viewWidth - boxWidth)}px )`,
				}}
			>
				<svg className="handle" width="18" height="30">
					<rect
						className="box"
						x="1"
						y="1"
						width="16"
						height="28"
						stroke="2"
						rx="2"
					/>
					<line className="lines" x1="6" y1="9" x2="6" y2="19" />
					<line className="lines" x1="9" y1="9" x2="9" y2="19" />
					<line className="lines" x1="12" y1="9" x2="12" y2="19" />
				</svg>
			</div>
			<MinimapSummary profiles={profiles} selectedHeaderItem={selectedHeaderItem} onInfoClick={onInfoClick} />
		</div>
	);
};

export default Minimap;
