export enum ContributionType {
  Yearly = 'YEARLY',
  Monthly = 'MONTHLY',
}

export interface Account {
  id?: number
  name: string
  value: number
  annualGrowth: number
  contribution: number
  contributionType: ContributionType
}

export interface Income {
  name: string
  contribution: number
  contributionType: ContributionType
}

export interface AccountsInput {
  items: Account[]
}

export interface IncomesInput {
  items: Income[]
}
