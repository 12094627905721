import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles.scss';
import { Button, Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import * as quizTypes from '../reducer';
import SelectItemView from './SelectItemView';
import { showLifeExpectancyDeprivation} from './LifeExpectancy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface Props {
  deprivation?: quizTypes.Deprivation;
  setDeprivation: (s: quizTypes.SetDeprivation) => void;
}

const Deprivation: React.FC<Props> = ({
  deprivation,
  setDeprivation,
}: Props) => {
  const { t } = useTranslation();

  const [showError, setShowError] = useState<boolean>(false);
  const history = useHistory();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const handleSubmit = () => {
    if (deprivation) {
      setButtonDisabled(true)
      {
        let result = showLifeExpectancyDeprivation(deprivation)
          toast.info(`Predicted Life Expectancy: ${result}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {  history.push('/education')},
          });
        // history.push('/education');
      }
    } else {
      setShowError(true);
    }
  };

  const options = [
    {
      label: t('features.calculator.lifeExpectancy.deprivation.below_average'),
      value: quizTypes.Deprivation.below_average,
    },
    {
      label: t('features.calculator.lifeExpectancy.deprivation.average'),
      value: quizTypes.Deprivation.average,
    },
    {
      label: t('features.calculator.lifeExpectancy.deprivation.above_average'),
      value: quizTypes.Deprivation.above_average,
    },
  ];

  return (
    <Container className='content'>
      <h1 className='display-4 text-center title'>
        {t('features.calculator.lifeExpectancy.deprivation.title')}
      </h1>
      <div className='mb-2'>
        {options.map((option, index) => (
          <SelectItemView
            key={index}
            label={option.label}
            selected={deprivation === option.value}
            onSelect={(): void =>
              setDeprivation({
                type: 'set_deprivation',
                deprivation: option.value,
              })
            }
          />
        ))}
      </div>
      {showError && (
        <div className='form-error'>
          {t('features.calculator.lifeExpectancy.deprivation.errors.empty')}
        </div>
      )}
      <hr />
      <Button disabled={buttonDisabled} color='primary' block size='lg' onClick={handleSubmit}>
        {t('features.getstarted.next')}
      </Button>
      <Button
        outline
        color='link'
        onClick={() => {
          history.goBack();
        }}
        className='w-100'
      >
        {t('features.calculator.lifeExpectancy.deprivation.confirmBack')}
      </Button>
       <ToastContainer />
    </Container>
  );
};

export default Deprivation;
