export enum AssetType {
  Retirement = 'RETIREMENT',
  Savings = 'SAVINGS',
  Investments = 'INVESTMENTS',
  Home = 'HOME',
  Property = 'PROPERTY',
  Vehicle = 'VEHICLE',
  Valuables = 'VALUABLES',
}

export enum LiabilityType {
  HomeMortgage = 'HOME_MORTGAGE',
  PropertyMortgage = 'PROPERTY_MORTGAGE',
  CreditCard = 'CREDIT_CARD',
  LineOfCredit = 'LINE_CREDIT',
  Loan = 'LOAN',
}

export interface Item {
  id?: number
  itemType: AssetType | LiabilityType
  name: string
  value: number
  ownershipPercent: number
}

export interface ItemsInput {
  items: Item[]
}
