import React, { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
	useParams,
	useHistory,
	useRouteMatch,
	Switch,
	Route,
} from "react-router-dom";
import { ItemsInput } from "./types";
import {
	NetWorthQuery,
	NET_WORTH_QUERY,
	UPDATE_NET_WORTH_MUTATION,
} from "./queries";
import NetWorthCalculatorForm from "./views/NetWorthCalculatorForm";
import { FormValues } from "./views/NetWorthCalculatorForm";
import LoadingSpinner from "shared/views/spinner/LoadingSpinner";
import Header from "shared/views/Header";
import NetWorthConfirm from "./views/NetWorthConfirm";
import { useTranslation } from "react-i18next";
import { apolloErrorToString } from "shared/utils/api/ApolloClient";
import { RETIREMENT_ACCOUNTS_REFETCH } from "../retirement/queries";
import { REAL_ESTATE_REFETCH } from "../realestate/queries";
import {
	RETIREMENT_GRAPH_REFETCH,
	REAL_ESTATE_GRAPH_REFETCH,
} from "features/graph/finances/queries";

const NetWorthCalculator: React.FC = () => {
	const { membershipId } = useParams();
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const match = useRouteMatch();

	const netWorthQuery = useQuery<NetWorthQuery>(NET_WORTH_QUERY);
	const [submitForm, { error, loading }] = useMutation<
    NetWorthQuery,
    ItemsInput
  >(UPDATE_NET_WORTH_MUTATION);

	useEffect(() => {
		window.gtag("event", "networth_calculator_page_view", {
			page: history.location.pathname,
		});
	}, [history.location]);

	const onSubmit = async (input: FormValues) => {
		// we can't really format Formik fields,
		// so we store liabilities as positive in the form and flip them back on submit
		// also, explicitly map the mutation items since graphQL leaves extra properties
		// on our data that break the mutation
		const vars: ItemsInput = {
			items: [
				...input.assets.map(item => ({
					id: item.id,
					itemType: item.itemType,
					name: item.name,
					value: item.value,
					ownershipPercent: item.ownershipPercent,
				})),
				...input.liabilities.map(item => ({
					id: item.id,
					itemType: item.itemType,
					name: item.name,
					value: -item.value,
					ownershipPercent: item.ownershipPercent,
				})),
			],
		};
		try {
			await submitForm({
				variables: vars,
				refetchQueries: [
					{ query: NET_WORTH_QUERY },
					{ query: RETIREMENT_ACCOUNTS_REFETCH },
					{ query: REAL_ESTATE_REFETCH },
					{ query: RETIREMENT_GRAPH_REFETCH },
					{ query: REAL_ESTATE_GRAPH_REFETCH },
				],
				awaitRefetchQueries: true,
			});
		} catch (e) {
			// we're passing this error into the form via prop, so we can just return here
			return;
		}
		history.push(`/calculator/${membershipId}/networth/done`);
	};

	if (!netWorthQuery.data || netWorthQuery.loading) {
		return <LoadingSpinner />;
	}

	// and we have to flip them when receiving data so they'll be positive in the form
	return (
		<Switch>
			<Route path={`${match.url}/done`}>
				<Header text={t("features.calculator.netWorth.resultsHeader")} />
				<NetWorthConfirm
					netWorth={netWorthQuery.data.netWorth.netWorth}
					wealthScore={netWorthQuery.data.netWorth.wealthScore}
					backUrl={`/dashboard/${membershipId}/`}
				/>
			</Route>
			<Route path={`${match.url}`}>
				<Header text={t("features.calculator.netWorth.formHeader")} />
				<NetWorthCalculatorForm
					assets={
            netWorthQuery.data?.netWorth.items.filter(
              item => item.value >= 0
            ) || []
					}
					liabilities={
            netWorthQuery.data?.netWorth.items
              .filter(item => item.value < 0)
              .map(item => ({ ...item, value: -item.value })) || []
					}
					mutationError={error ? apolloErrorToString(error, i18n) : undefined}
					loading={loading}
					onSubmit={onSubmit}
				/>
			</Route>
		</Switch>
	);
};

export default NetWorthCalculator;
