import React, { useState } from "react";
import * as types from "../../shared/types";
import AccountManagementPage from "./views/Home";
import { gql } from "apollo-boost";
import { personFragment } from "shared/queries";
import { useQuery, useMutation } from "@apollo/react-hooks";
import LoadingSpinner from "shared/views/spinner/LoadingSpinner";
import ConfirmationModalInput from "shared/views/ConfirmModalInput";
import { useTranslation } from "react-i18next";
import { apolloErrorToString } from "shared/utils/api/ApolloClient";

interface AccountPageQuery {
  me: {
    person: types.Person
    email: string
  }
}

export const ACCOUNT_QUERY = gql`
  query {
    me {
      person {
        ...personFields
      }
      email
    }
  }

  ${personFragment}
`;

export const EDIT_EMAIL = gql`
  mutation EditEmail($newEmail: String!) {
    editEmail(newEmail: $newEmail)
  }
`;

const AccountManagement: React.FC = () => {
	const { t, i18n } = useTranslation();
	const { loading, error, data } = useQuery<AccountPageQuery>(ACCOUNT_QUERY);

	const [emailInput, setEmailInput] = useState<string>("");
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

	const [mutate, { error: mutationError }] = useMutation(EDIT_EMAIL);

	if (loading) return <LoadingSpinner />;

	if (error) {
		return <div />;
	}

	if (data) {
		return (
			<>
				<ConfirmationModalInput
					isOpen={showConfirmModal}
					repeatString={data.me.email}
					title={t("features.account.home.email.confirmModal.title")}
					description={t("features.account.home.email.confirmModal.warning")}
					closeModal={() => {
						setShowConfirmModal(false);
						setEmailInput("");
					}}
					confirm={async () => {
						try {
							await mutate({
								variables: { newEmail: emailInput },
								refetchQueries: [{ query: ACCOUNT_QUERY }],
								awaitRefetchQueries: true,
							});
						} catch (error) {
							// Ignored since error is captured in `mutationError`
						}
						setShowConfirmModal(false);
					}}
				/>
				<AccountManagementPage
					person={data.me.person}
					email={data.me.email}
					editEmailError={
						mutationError ? apolloErrorToString(mutationError, i18n) : ""
					}
					handleEmail={(newEmail: string) => {
						setEmailInput(newEmail);
						setShowConfirmModal(true);
					}}
				/>
			</>
		);
	}
	return <div />;
};

export default AccountManagement;
