import { gql } from 'apollo-boost'

// All person fields.
export const personFragment = gql`
  fragment personFields on Person {
    id
    firstName
    lastName
    birthYear
    sex
    country
    hasUser
  }
`

// All membership fields: id, role, and a person.
export const personAndMembershipFragment = gql`
  fragment membershipFields on GroupMembership {
    id
    person {
      ...personFields
    }
    role
    canEdit
    canDelete
    canInvite
    showCalculators
    showEducationCalculator
  }

  ${personFragment}
`

// A note's fields, excluding membership and group.
export const noteFragment = gql`
  fragment noteFields on Note {
    id
    description
    type
    calculator
    year
    isModified
    canEdit
  }
`

// All feature flag fields.
export const featureFlagsFragment = gql`
  fragment featureFlagsFields on FeatureFlags {
    id
    showTimelineIntro
    showConfirmPersonInfo
    showNewMemberOnboarding
  }
`

// all timeline fields, excluding notes.
export const timelineFragment = gql`
  fragment timelineFields on Timeline {
    id
    startYear
    endYear
    segments {
      type
      start
      end
    }
  }
`
