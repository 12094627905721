import React, { MutableRefObject, useState } from 'react'
import Graph, { DataSeries, GraphMarkerShape } from './Graph'
import * as types from '../../shared/types'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import LoadingSpinner from 'shared/views/spinner/LoadingSpinner'

import './EventsGraph.scss'
import EventViewModal from 'features/notes/views/EventViewModal'

export const EVENTS_QUERY = gql`
  query {
    events {
      id
      year
      row
      name
    }
  }
`

export const HIDE_EVENT = gql`
  mutation HideEvent($eventId: ID!) {
    excludeEvent(eventId: $eventId)
  }
`

interface EventsData {
  events: types.Event[]
}

interface EventsGraphProps {
  show: boolean
  startYear: number
  endYear: number
  targetRef: MutableRefObject<HTMLDivElement>
}

const EventsGraph: React.FC<EventsGraphProps> = (props: EventsGraphProps) => {
  const [currentNoteIndex, setCurrentNoteIndex] = useState<number | undefined>(
    undefined
  )

  const { data, loading, refetch } = useQuery<EventsData>(EVENTS_QUERY)
  const [mutate] = useMutation(HIDE_EVENT)

  if (!data || loading) {
    return <LoadingSpinner overlay />
  }

  const events: types.Event[] = data.events.sort((a, b) => a.year - b.year)

  const dataSeries: DataSeries[] = events.map(event => {
    const row = types.eventRowToInteger(event.row)
    return {
      id: event.id,
      name: event.name,
      markerShape: GraphMarkerShape.Diamond,
      data: [
        {
          x: event.year,
          y: row,
          value: event.name,
        },
      ],
      showPoppers: true,
    }
  })

  return (
    <>
      {currentNoteIndex !== undefined && (
        <EventViewModal
          isOpen={true}
          toggle={() => setCurrentNoteIndex(undefined)}
          event={events[currentNoteIndex]}
          events={events}
          hideEvent={async () => {
            await mutate({
              variables: { eventId: events[currentNoteIndex].id },
            })
            setCurrentNoteIndex(undefined)
            await refetch()
          }}
          shiftEvent={(direction: 'forward' | 'back') => {
            if (direction === 'forward')
              setCurrentNoteIndex(currentNoteIndex + 1)
            else setCurrentNoteIndex(currentNoteIndex - 1)
          }}
        />
      )}
      <div className="events-graph">
        <Graph
          show
          data={dataSeries}
          startYear={props.startYear}
          endYear={props.endYear}
          targetRef={props.targetRef}
          isEvents={true}
          onMarkerClick={(idx: number) => {
            setCurrentNoteIndex(idx)
          }}
        />
      </div>
    </>
  )
}

export default EventsGraph
