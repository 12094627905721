import React, { useEffect, useState } from "react";
import {
	BrowserRouter,
	Switch,
	withRouter,
	Route,
	useHistory,
} from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import { setupClient } from "shared/utils/api/ApolloClient";
import { Auth0Provider, useAuth0 } from "shared/views/auth/Provider";
import LandingPage from "features/landing";
import GetStartedPage from "features/getstarted";
import TimelinePage from "features/timeline";
import ApolloClient from "apollo-boost";
import "./i18n"; // init i18n
import Login from "features/landing/views/Login";
import Dashboard from "features/dashboard";
import EditPerson from "features/person/EditPerson";
import Invite from "features/dashboard/views/InviteMember";
import { Role } from "shared/types";
import ConfirmPerson from "features/person/ConfirmPersonInfo";
import InviteOnboarding from "features/getstarted/views/InviteOnboarding";
import Invitation from "features/invitation";
import AccountManagement from "features/account";
import NetWorthCalculator from "features/calculators/networth";
import PrivateRoute from "shared/views/auth/PrivateRoute";
import RetirementCalculator from "features/calculators/retirement";
import EducationCalculator from "features/calculators/education";
import RealEstateCalculator from "features/calculators/realestate";
import LifeExpectancyCalculator from "features/calculators/lifeExpectancy";
import { Auth0ClientOptions } from "@auth0/auth0-spa-js";

const options: Auth0ClientOptions = {
	domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
	client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
	redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI || "",
	audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
	cacheLocation: "localstorage",
};
console.log("======options=====",options)
const AppRoutes: React.FC = () => {
	const history = useHistory();

	useEffect(() => {
		// gtag will implicitly send the pathname with every event
		window.gtag("event", "page_view");
	}, [history.location.pathname]);

	return (
		<Switch>
			<Route exact path="/">
				<LandingPage />
			</Route>
			<Route exact path="/login">
				<Login />
			</Route>
			<Route path="/get-started">
				<GetStartedPage />
			</Route>
			<Route path="/invite/:token">
				<Invitation />
			</Route>
			<PrivateRoute path="/invite-onboarding">
				<InviteOnboarding />
			</PrivateRoute>
			<PrivateRoute exact path="/timeline">
				<TimelinePage />
			</PrivateRoute>
			<PrivateRoute path="/dashboard/:id/invite">
				<InviteWithRouter />
			</PrivateRoute>
			<PrivateRoute path="/dashboard/:id">
				<Dashboard />
			</PrivateRoute>
			<PrivateRoute exact path="/membership/new">
				<EditPerson />
			</PrivateRoute>
			<PrivateRoute path="/membership/:id">
				<EditPerson />
			</PrivateRoute>
			<PrivateRoute path="/confirm-info">
				<ConfirmPerson />
			</PrivateRoute>
			<PrivateRoute path="/account">
				<AccountManagement />
			</PrivateRoute>
			<PrivateRoute path="/calculator/:membershipId/networth">
				<NetWorthCalculator />
			</PrivateRoute>
			<PrivateRoute path="/calculator/:membershipId/retirement">
				<RetirementCalculator />
			</PrivateRoute>
			<PrivateRoute path="/calculator/:membershipId/education">
				<EducationCalculator />
			</PrivateRoute>
			<PrivateRoute path="/calculator/:membershipId/real-estate">
				<RealEstateCalculator />
			</PrivateRoute>
			<PrivateRoute path="/calculator/:membershipId/life-expectancy">
				<LifeExpectancyCalculator />
			</PrivateRoute>
		</Switch>
	);
};

const Router: React.FC = () => {
	const { token } = useAuth0();
	const [client, setClient] = useState<ApolloClient<any>>(setupClient());

	useEffect(() => {
		if (token) {
			setClient(setupClient(token));
		}
	}, [token]);

	if (token === undefined) {
		return null;
	}

	return (
		<ApolloProvider client={client}>
			<BrowserRouter>
				<AppRoutes />
			</BrowserRouter>
		</ApolloProvider>
	);
};

const InviteWithRouter = withRouter(props => {
	const locationState = {
		role: Role.Primary,
		id: -1,
		...props.location.state as {},
	};
	return <Invite role={locationState.role} id={locationState.id} />;
});

const App: React.FC = () => (
	<Auth0Provider options={options}>
		<Router />
	</Auth0Provider>
);

export default App;
