import React from "react";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import * as types from "../../shared/types";
import PersonForm, {
	PersonFormValues,
} from "../../shared/views/person/PersonForm";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";

import { GET_TIMELINE } from "features/timeline";
import Header from "shared/views/Header";
import LoadingSpinner from "shared/views/spinner/LoadingSpinner";
import {
	personAndMembershipFragment,
	featureFlagsFragment,
} from "shared/queries";

interface PersonQuery {
  me: {
    person: types.Person
    email: string
  }
  family: types.Family
  featureFlags: types.FeatureFlags
}

const PERSON_QUERY = gql`
  query {
    me {
      person {
        id
      }
    }
    family {
      id
      profiles {
        membership {
          ...membershipFields
        }
      }
    }
    featureFlags {
      ...featureFlagsFields
    }
  }

  ${personAndMembershipFragment}
  ${featureFlagsFragment}
`;

interface ConfirmPersonProps {
  membership: types.Membership
  editPerson: (values: PersonFormValues) => Promise<void>
}

export const ConfirmPersonInner: React.FC<ConfirmPersonProps> = ({
	membership,
	editPerson,
}: ConfirmPersonProps) => {
	const { t } = useTranslation();
	const history = useHistory();

	const defaultInitialValues: PersonFormValues = {
		firstName: "",
		lastName: "",
		birthYear: "",
		sex: "",
		country: "",
		reset: false,
	};

	const initialValues: PersonFormValues = membership
		? {
			...membership.person,
			reset: false,
		}
		: defaultInitialValues;

	const onSubmit = async (values: PersonFormValues) => {
		await editPerson(values);
		history.push("/timeline");
	};

	return (
		<>
			<Header text={t("features.person.confirm.header")} />
			<Container className="content">
				<h1 className="display-4 text-center title">
					{t("features.person.confirm.title")}
				</h1>
				<p>{t("features.person.confirm.body")}</p>
				<PersonForm
					initialValues={initialValues}
					onSubmit={onSubmit}
					submitText={t("features.person.confirm.done")}
				/>
			</Container>
		</>
	);
};

const EDIT_MEMBERSHIP_MUTATION = gql`
  mutation EditMembership(
    $person: PersonInput!
    $role: String!
    $membershipId: ID!
  ) {
    editMembership(person: $person, role: $role, membershipId: $membershipId) {
      id
    }
    updateFeatureFlags(showConfirmPersonInfo: false) {
      id
    }
  }
`;

const ConfirmPerson = () => {
	const { loading, error, data } = useQuery<PersonQuery>(PERSON_QUERY);
	const [editPersonMutation, { loading: mutationLoading }] = useMutation(
		EDIT_MEMBERSHIP_MUTATION
	);

	if (loading) {
		return <LoadingSpinner />;
	}

	if (error) {
		return <div />;
	}

	if (data) {
		const membership = data.family.profiles.find(
			profile => profile.membership.person.id === data.me.person.id
		)?.membership;

		const editPerson = async (values: PersonFormValues): Promise<void> => {
			await editPersonMutation({
				variables: {
					person: {
						firstName: values.firstName,
						lastName: values.lastName,
						birthYear: values.birthYear,
						country: values.country,
						sex: values.sex,
					},
					role: membership?.role,
					membershipId: membership?.id,
				},
				refetchQueries: [{ query: GET_TIMELINE }],
				awaitRefetchQueries: true,
			});
		};
		if (membership)
			return (
				<>
					{mutationLoading && <LoadingSpinner overlay={true} />}
					<ConfirmPersonInner membership={membership} editPerson={editPerson} />
				</>
			);
	}
	return <div />;
};

export default ConfirmPerson;
