import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, Container, Input } from 'reactstrap'
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik'
import * as yup from 'yup'
import '../styles.scss'
import { PERSON_NAME_MAX_LENGTH } from 'shared/types'

interface Values {
  firstName?: string
  lastName?: string
}

interface Props {
  firstName?: string
  lastName?: string
  setName: (firstName?: string, lastName?: string) => void
}

const Name: React.FC<Props> = ({ firstName, lastName, setName }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()

  const NameSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(t('features.getstarted.name.errors.firstName.empty')),
    lastName: yup
      .string()
      .required(t('features.getstarted.name.errors.lastName.empty')),
  })

  const onSubmit = ({ firstName, lastName }: Values): void => {
    setName(firstName, lastName)
    history.push('/year')
  }

  return (
    <Container className="content">
      <h1 className="display-4 text-center title">
        {t('features.getstarted.name.title')}
      </h1>
      {/* <p>{t('features.getstarted.name.body')}</p> */}
      <Formik
        initialValues={{ firstName, lastName }}
        onSubmit={onSubmit}
        validationSchema={NameSchema}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
      >
        {({ values, errors, touched }: FormikProps<Values>) => (
          <Form>
            <Field
              as={Input}
              name="firstName"
              type="text"
              placeholder={t('features.getstarted.name.firstName')}
              maxLength={PERSON_NAME_MAX_LENGTH}
              className={`goal custom${errors.firstName ? ' has-errors' : ''}`}
            />
            <ErrorMessage name="firstName">
              {msg => <div className="form-error">{msg}</div>}
            </ErrorMessage>
            <Field
              as={Input}
              name="lastName"
              type="text"
              placeholder={t('features.getstarted.name.lastName')}
              maxLength={PERSON_NAME_MAX_LENGTH}
              className={`goal custom${errors.lastName ? ' has-errors' : ''}`}
            />
            <ErrorMessage name="lastName">
              {msg => <div className="form-error">{msg}</div>}
            </ErrorMessage>
            <hr />
            <Button color="primary" block size="lg" type="submit">
              {t('features.getstarted.next')}
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default Name
