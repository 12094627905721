import React, { SVGProps, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { useAuth0 } from 'shared/utils/auth'
import {
  MedicalIcon,
  TimeIcon,
  FinanceIcon,
  GlobeIcon,
  SettingsIcon,
  SphinxIcon
} from 'shared/views/Icon'
import { useHistory } from 'react-router-dom'

export enum HeaderItem {
  Timeline = 'timeline',
  Rates = 'rates',
  Finance = 'finance',
  World = 'world',
  SphinxIcon = '  SphinxIcon'

}

interface NavbarIconProps {
  icon: React.FC<SVGProps<SVGSVGElement>>
  selected: boolean
  onClick: () => void
}

const NavbarButton: React.FC<NavbarIconProps> = (props: NavbarIconProps) => {
  const iconClass = props.selected
    ? 'timeline-nav-icon selected'
    : 'timeline-nav-icon'

  return (
    <Button color="link" className={iconClass} onClick={props.onClick}>
      <props.icon />
    </Button>
  )
}



interface NavbarPercentageProps {
  pointedYear3?:any
}

const NavbarPercentagge: React.FC<NavbarPercentageProps> = (props: NavbarPercentageProps) => {
  let allAges: any = "-"
  if(localStorage.getItem("survialAllAges") !== null){
    
     allAges = localStorage.getItem("survialAllAges")
     allAges = JSON.parse(allAges)
  }
  //  console.log(typeof allAges)
  //     let showVal='-';
  //   if(typeof allAges[props.pointedYear3]!=='undefined'){
  //   showVal=allAges[props.pointedYear3];
  //   }
  let currentYear = new Date().getFullYear()
   let newVal:any = 0
   let year = props.pointedYear3?props.pointedYear3:currentYear;
  if(year==currentYear){
    if(localStorage.getItem("survialAllAges") !== null ){
      newVal =  allAges[currentYear];
   }else{
     console.log("enter in else part year==currentYear")
    newVal =  '-'
   }
    

  }else{
    newVal = allAges[year]?allAges[year]:'-';

  }
    return (
    <Button style={{textDecoration:"none",color:"white"}} color="link" className={'timeline-nav-icon'} >
    {newVal}
    </Button>
  )
}



const icons: { item: HeaderItem; icon: React.FC<SVGProps<SVGSVGElement>> }[] = [
  {
    item: HeaderItem.Timeline,
    icon: TimeIcon,
  },
  {
    item: HeaderItem.Rates,
    icon: MedicalIcon,
  },
  {
    item: HeaderItem.Finance,
    icon: FinanceIcon,
  },
  {
    item: HeaderItem.World,
    icon: GlobeIcon,
  },
]


const iconSphinx: { item: HeaderItem; icon: React.FC<SVGProps<SVGSVGElement>> }[] = [
  {
    item: HeaderItem.SphinxIcon,
    icon:   SphinxIcon
    ,
  }
]

export interface NavbarTimelineProps {
  selected: HeaderItem
  setSelectedItem: (item: HeaderItem) => void
  pointedYear3?:any
}

const NavbarTimeline: React.FC<NavbarTimelineProps> = (
  props: NavbarTimelineProps
) => {
  const { t } = useTranslation()
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const { logout } = useAuth0()
  const history = useHistory()

  const toggleSettings = () => {
    setShowSettingsModal(!showSettingsModal)
  }

  return (
    <div className="bg-primary text-white timeline-nav">
      <a className="timeline-nav-brand" href="/">
        <span className="timeline-nav-brand">{t('shared.app.name')}</span>
      </a>
      <div className="timeline-nav-icon-bar" style={{border:"none"}}>
      <div style={{border:'1px solid white',borderRadius:"5px"}}>
        <ButtonGroup>
          {icons.map(({ item, icon }) => {
            const selected = props.selected === item
            return (
              <NavbarButton
                key={item}
                icon={icon}
                selected={selected}
                onClick={() => {
                  props.setSelectedItem(item)
                }}
              />
            )
          })}
        </ButtonGroup>
        </div>
        <div style={{border:'1px solid white',marginLeft:"10px",borderRadius:"5px"}}>
        <ButtonGroup>
          {iconSphinx.map(({ item, icon }) => {
            
            return (
              <NavbarButton
              key={item}
              icon={icon}
              selected={false}
              onClick={() => {
                // props.setSelectedItem(item)
              }}
               
              />
            )
          })}
          
           <NavbarPercentagge  pointedYear3={props.pointedYear3} /> 
         
        </ButtonGroup>
        </div>
        <div className="rulerCurrentTop" id="rulerCurrentTop"></div>
      </div>
      <div className="d-flex justify-content-end">
        <Dropdown isOpen={showSettingsModal} toggle={toggleSettings}>
          <DropdownToggle nav caret>
            <SettingsIcon />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={(): void => history.push('/account')}>
              {t('shared.navbar.account')}
            </DropdownItem>
            <DropdownItem onClick={async (): Promise<void> => await logout()}>
              {t('shared.navbar.logout')}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  )
}

export default NavbarTimeline
