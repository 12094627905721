import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Container, Button } from 'reactstrap'
import { Person } from '../reducer'
import '../styles.scss'
import PersonForm, { PersonFormValues } from 'shared/views/person/PersonForm'

interface Props {
  name?: string
  title: string
  person?: Person
  setPerson: (person?: PersonFormValues, index?: number) => void
  nextRoute: string
  cancelRoute?: string
  addAnother?: boolean
  preserveLastName?: boolean
}

const FillInfo: React.FC<Props> = ({
  name,
  title,
  setPerson,
  nextRoute,
  cancelRoute,
  addAnother,
  preserveLastName,
}: Props) => {
  const { t } = useTranslation()
  const history = useHistory()

  const initialValues: PersonFormValues = {
    firstName: '',
    lastName: '',
    birthYear: '',
    sex: '',
    country: '',
    reset: false,
  }

  const handleSubmit = (values: PersonFormValues): void => {
    setPerson(values)
    if (!values.reset) {
      history.push(nextRoute)
    }
  }

  const getResetValues = (values: PersonFormValues): PersonFormValues => {
    if (preserveLastName) {
      return {
        ...initialValues,
        lastName: preserveLastName ? values.lastName : initialValues.lastName,
        country: preserveLastName ? values.country : initialValues.country,
      }
    } else {
      return initialValues
    }
  }

  return (
    <Container className="content">
      <h3 className="text-center">{name}</h3>
      <h1 className="display-4 text-center title">{title}</h1>
      <PersonForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        getResetValues={getResetValues}
        submitText={t('features.getstarted.save')}
        submitResetText={
          addAnother ? t('features.getstarted.saveAnother') : undefined
        }
      />
      {cancelRoute && (
        <Button
          type="button"
          color="link"
          block
          size="lg"
          onClick={() => history.push(cancelRoute)}
        >
          {t('features.getstarted.skip')}
        </Button>
      )}
    </Container>
  )
}

export default FillInfo
