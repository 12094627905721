import React from 'react'
import './ConfirmModal.scss'

import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'

interface ConfirmationModalProps {
  isOpen: boolean
  title: string
  description?: string
  closeModal: () => void
  confirm: () => void
  confirmText?: string
  cancelText?: string
}

export const ConfirmationModalBody: React.FC<ConfirmationModalProps> = ({
  title,
  description,
  closeModal,
  confirm,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
}: ConfirmationModalProps) => {
  return (
    <>
      <div className="confirmation-modal header">
        <h3>{title}</h3>
      </div>
      {description ? (
        <ModalBody>
          <div className="confirmation-modal text">
            <p className="mb-0">{description}</p>
          </div>
        </ModalBody>
      ) : null}
      <ModalFooter>
        <div className="d-flex justify-content-around w-100">
          <Button
            onClick={confirm}
            color="link"
            className="note-edit-modal-done"
          >
            <h3 className="font-weight-light">{confirmText}</h3>
          </Button>
          <Button
            onClick={closeModal}
            color="link"
            className="note-edit-modal-done"
          >
            <h3>{cancelText}</h3>
          </Button>
        </div>
      </ModalFooter>
    </>
  )
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = (
  props: ConfirmationModalProps
) => {
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.closeModal}
      centered
      contentClassName="confirmation-modal content"
    >
      <ConfirmationModalBody {...props} />
    </Modal>
  )
}

export default ConfirmationModal
