import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, Container, Row, Col } from 'reactstrap'
import '../styles.scss'
import * as types from '../../../shared/types'
import { FemaleIcon, MaleIcon } from 'shared/views/Icon'

interface Props {
  name: string
  sex?: types.Sex
  setSex: (sex?: types.Sex) => void
}

const Sex: React.FC<Props> = ({ name, sex, setSex }: Props) => {
  const { t } = useTranslation()
  const [showError, setShowError] = useState<boolean>(false)
  const history = useHistory()

  const handleSubmit = () => {
    if (sex) {
      history.push('/country')
    } else {
      setShowError(true)
    }
  }

  return (
    <Container className="content">
      <h3 className="text-center">{name}</h3>
      <h1 className="display-4 text-center title">
        {t('features.getstarted.sex.title')}
      </h1>
      <Row className="mb-2">
        <Col className="text-right">
          <Button
            outline={sex === undefined || sex === types.Sex.Female}
            color="success"
            className="sex"
            onClick={(): void => setSex(types.Sex.Male)}
          >
            <MaleIcon />
            <div className="text-center sex-indicator">
              <span>{t('features.getstarted.sex.male')}</span>
            </div>
          </Button>
        </Col>
        <Col className="text-left">
          <Button
            outline={sex === undefined || sex === types.Sex.Male}
            color="success"
            onClick={(): void => setSex(types.Sex.Female)}
            className="sex"
          >
            <FemaleIcon />
            <div className="text-center sex-indicator">
              <span>{t('features.getstarted.sex.female')}</span>
            </div>
          </Button>
        </Col>
      </Row>
      {showError && (
        <div className="form-error">
          {t('features.getstarted.sex.errors.empty')}
        </div>
      )}
      <hr />
      <Button color="primary" block size="lg" onClick={handleSubmit}>
        {t('features.getstarted.next')}
      </Button>
    </Container>
  )
}

export default Sex
