import React from 'react'
import { Field, ErrorMessage } from 'formik'
import {
  Button,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap'
import { AssetType, LiabilityType } from '../types'
import { useTranslation } from 'react-i18next'
import '../styles.scss'

interface Props {
  name: 'assets' | 'liabilities'
  index: number
  isSubmitting: boolean
  handleChange: (e: React.ChangeEvent<any>) => void
  remove: (index: number) => unknown
}

const NetWorthItem: React.FC<Props> = ({
  name,
  index,
  isSubmitting,
  handleChange,
  remove,
}: Props) => {
  const { t } = useTranslation()
  const [showPercentage, setShowPercentage] = React.useState(false)
  const onClick = () => setShowPercentage(!showPercentage)
  const options =
    name === 'assets'
      ? Object.values(AssetType).map(type => (
          <option key={type} value={type}>
            {t(`features.calculator.netWorth.itemType.${type}`)}
          </option>
        ))
      : Object.values(LiabilityType).map(type => (
          <option key={type} value={type}>
            {t(`features.calculator.netWorth.itemType.${type}`)}
          </option>
        ))

  return (
    <Row form className="mb-3">
      <Col sm={3} className="networth-form-column">
        <label htmlFor={`${name}.${index}.value`}>
          {t('features.calculator.netWorth.labels.type')}
        </label>
        <Field
          as={Input}
          type="select"
          name={`${name}.${index}.itemType`}
          onChange={handleChange}
          disabled={isSubmitting}
        >
          <option value="">
            {t(`features.calculator.netWorth.default.${name}`)}
          </option>
          {options}
        </Field>
        <ErrorMessage name={`${name}.${index}.itemType`}>
          {msg => <div className="mt-1 form-error">{msg}</div>}
        </ErrorMessage>
      </Col>

      <Col sm={3} className="networth-form-column">
        <label htmlFor={`${name}.${index}.value`}>
          {t('features.calculator.netWorth.labels.name')}
        </label>
        <Field
          as={Input}
          name={`${name}.${index}.name`}
          onChange={handleChange}
          disabled={isSubmitting}
          placeholder={t(`features.calculator.netWorth.default.${name}`)}
        />
        <ErrorMessage name={`${name}.${index}.name`}>
          {msg => <div className="mt-1 form-error">{msg}</div>}
        </ErrorMessage>
      </Col>

      <Col sm={3} className="networth-form-column">
        <label htmlFor={`${name}.${index}.value`}>
          {t('features.calculator.netWorth.labels.value')}
        </label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">$</InputGroupAddon>
          <Field
            as={Input}
            type="number"
            name={`${name}.${index}.value`}
            onChange={handleChange}
            disabled={isSubmitting}
            placeholder={t('features.calculator.netWorth.default.value')}
          />
        </InputGroup>
        <ErrorMessage name={`${name}.${index}.value`}>
          {msg => <div className="mt-1 form-error">{msg}</div>}
        </ErrorMessage>
      </Col>
      {showPercentage ? <>
        <Col sm="2" className="networth-form-column">
        <label htmlFor={`${name}.${index}.value`}>
          {t('features.calculator.netWorth.labels.ownership')}
        </label>
        <InputGroup>
          <Field
            as={Input}
            type="number"
            name={`${name}.${index}.ownershipPercent`}
            onChange={handleChange}
            disabled={isSubmitting}
          />
          <InputGroupAddon addonType="append">%</InputGroupAddon>
        </InputGroup>
        <ErrorMessage name={`${name}.${index}.ownershipPercent`}>
          {msg => <div className="mt-1 form-error">{msg}</div>}
        </ErrorMessage>
      </Col>
          </>:<>

          <Col sm="1" className="networth-form-column">
            <label htmlFor={`${name}.${index}.value`}>
          {t('features.calculator.netWorth.labels.ownership')}
        </label>
        <Button
          title="[Remove]"
          color="primary"
          disabled={isSubmitting}
          block
          onClick={onClick}
        >
          %
        </Button>
      </Col>
          </>}

      {/* <Col sm="1" className="networth-form-column">
        <label htmlFor={`${name}.${index}.value`}>
          {t('features.calculator.netWorth.labels.ownership')}
        </label>
        <InputGroup>
          <Field
            as={Input}
            type="number"
            name={`${name}.${index}.ownershipPercent`}
            onChange={handleChange}
            disabled={isSubmitting}
          />
          <InputGroupAddon addonType="append">%</InputGroupAddon>
        </InputGroup>
        <ErrorMessage name={`${name}.${index}.ownershipPercent`}>
          {msg => <div className="mt-1 form-error">{msg}</div>}
        </ErrorMessage>
      </Col> */}
      {/* <Col sm="1" className="networth-form-column">
        <Button
          title="[Remove]"
          color="secondary"
          className="remove-btn"
          disabled={isSubmitting}
          block
          onClick={onClick}
        >
          %
        </Button>
      </Col> */}
      <Col sm="1" className="networth-form-column">
        <Button
          title="[Remove]"
          color="secondary"
          className="remove-btn"
          disabled={isSubmitting}
          block
          onClick={() => {
            remove(index)
          }}
        >
          -
        </Button>
      </Col>
    </Row>
  )
}

export default NetWorthItem
