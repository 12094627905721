import { Reducer } from 'react'
import { Sex } from 'shared/types'

export interface Person {
  firstName: string
  lastName: string
  birthYear: number
  sex: Sex
  country: string
}

export interface PartialPerson {
  firstName?: string
  lastName?: string
  birthYear?: number
  sex?: Sex
  country?: string
}

export interface State {
  goal?: string
  me?: PartialPerson
  partner?: Person
  children: Person[]
  others: Person[]
  membershipId?: number
}

export type ActionType =
  | 'set_goal'
  | 'set_name'
  | 'set_year'
  | 'set_sex'
  | 'set_country'
  | 'set_partner_info'
  | 'set_child_info'
  | 'set_other_info'

export interface Action {
  type: ActionType
  payload: any
}

export const initialState: State = {
  children: [],
  others: [],
}

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'set_goal':
      return {
        ...state,
        goal: action.payload,
      }
    case 'set_name':
      return {
        ...state,
        me: {
          ...state.me,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
        },
      }
    case 'set_year':
      return {
        ...state,
        me: {
          ...state.me,
          birthYear: action.payload,
        },
      }
    case 'set_sex':
      return {
        ...state,
        me: {
          ...state.me,
          sex: action.payload,
        },
      }
    case 'set_country':
      return {
        ...state,
        me: {
          ...state.me,
          country: action.payload,
        },
      }
    case 'set_partner_info':
      return {
        ...state,
        partner: {
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          birthYear: action.payload.birthYear,
          sex: action.payload.sex,
          country: action.payload.country,
        },
      }
    case 'set_child_info':
      return {
        ...state,
        children: [
          ...state.children,
          {
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            birthYear: action.payload.birthYear,
            sex: action.payload.sex,
            country: action.payload.country,
          },
        ],
      }
    case 'set_other_info':
      return {
        ...state,
        others: [
          ...state.others,
          {
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            birthYear: action.payload.birthYear,
            sex: action.payload.sex,
            country: action.payload.country,
          },
        ],
      }
    default:
      return state
  }
}

export default reducer
