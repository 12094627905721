import React from 'react'
import { useTranslation } from 'react-i18next'
import '../styles.scss'
import { Button, Container } from 'reactstrap'
import { useHistory } from 'react-router-dom'

interface Props {
  name: string
}

const HaveChildren: React.FC<Props> = ({ name }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Container className="content">
      <h3 className="text-center">{name}</h3>
      <h1 className="display-4 text-center title">
        {t('features.getstarted.children.title')}
      </h1>
      <Button
        block
        outline
        color="success"
        className="goal"
        onClick={() => history.push('/add-child')}
      >
        {t('features.getstarted.children.yes')}
      </Button>
      <Button
        block
        outline
        color="success"
        className="goal"
        onClick={() => history.push('/confirm-add-other')}
      >
        {t('features.getstarted.children.no')}
      </Button>
      <hr />
      <Button
        color="link"
        block
        size="lg"
        onClick={() => history.push('/confirm-add-other')}
      >
        {t('features.getstarted.children.skip')}
      </Button>
    </Container>
  )
}

export default HaveChildren
