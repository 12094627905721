import React, { useMemo, MutableRefObject, SetStateAction, Dispatch } from "react";
import { RateInfo, Sex } from "shared/types";
import Graph, { DataSeries, DataPoint, GraphMarkerShape } from "./Graph";
import { Person } from "features/getstarted/reducer";
import { MaleIcon, FemaleIcon } from "shared/views/Icon";

import "./RateInfoGraph.scss";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import LoadingSpinner from "shared/views/spinner/LoadingSpinner";
import { RateType } from "features/timeline";

interface RateButtonProps {
  text: string
  enabled: boolean
  onClick: () => void
}

const RateButton: React.FC<RateButtonProps> = (props: RateButtonProps) => {
	const className = props.enabled ? "rate-button enabled" : "rate-button";
	return (
		<Button color="primary" onClick={props.onClick} className={className}>
			<svg width="14" height="14">
				<circle cx="6" cy="6" r="4" />
			</svg>
			{props.text}
		</Button>
	);
};

export const RATE_QUERY = gql`
  query {
    fertilityRate {
      age
      sex
      value
    }
    mobilityRate {
      age
      sex
      value
    }
    happinessRate {
      age
      sex
      value
    }
  }
`;
interface RateData {
  fertilityRate: RateInfo[]
  mobilityRate: RateInfo[]
  happinessRate: RateInfo[]
}

interface RatesDict {
  [Sex.Male]: { [age: number]: RateInfo }
  [Sex.Female]: { [age: number]: RateInfo }
}

const createRateDict = (rates: RateInfo[]): RatesDict => {
	const ratesDict: RatesDict = {
		[Sex.Male]: {},
		[Sex.Female]: {},
	};

	rates.forEach(rate => {
		ratesDict[rate.sex][rate.age] = rate;
	});

	return ratesDict;
};

const createPersonData = (
	rates: RatesDict,
	startYear: number,
	endYear: number,
	person: Person
): DataPoint[] => {
	const data: DataPoint[] = [];

	for (let year = startYear; year <= endYear; year++) {
		const age = year - person.birthYear;
		const rate = rates[person.sex][age];
		if (rate) {
			data.push({ x: year, y: rate.value, value: rate.value });
		}
	}

	return data;
};

const createSeries = (
	rates: RatesDict,
	startYear: number,
	endYear: number,
	primary?: Person,
	partner?: Person
): DataSeries[] => {
	const series: DataSeries[] = [];
	if (primary) {
		series.push({
			icon: primary.sex === Sex.Male ? MaleIcon : FemaleIcon,
			sex: primary.sex,
			markerShape: GraphMarkerShape.Circle,
			data: createPersonData(rates, startYear, endYear, primary),
			showPoppers: false,
		});
	}
	if (partner) {
		series.push({
			icon: partner.sex === Sex.Male ? MaleIcon : FemaleIcon,
			sex: partner.sex,
			markerShape: GraphMarkerShape.Circle,
			data: createPersonData(rates, startYear, endYear, partner),
			showPoppers: false,
		});
	}
	return series;
};

const createAllSeries = (
	data: RateData,
	startYear: number,
	endYear: number,
	primary?: Person,
	partner?: Person
): { [key in RateType]: DataSeries[] } => {
	const fertilityDict = createRateDict(data.fertilityRate);
	const fertilitySeries = createSeries(
		fertilityDict,
		startYear,
		endYear,
		primary,
		partner
	);
	const mobilityDict = createRateDict(data.mobilityRate);
	const mobilitySeries = createSeries(
		mobilityDict,
		startYear,
		endYear,
		primary,
		partner
	);
	const happinessDict = createRateDict(data.happinessRate);
	const happinessSeries = createSeries(
		happinessDict,
		startYear,
		endYear,
		primary,
		partner
	);
	return {
		fertility: fertilitySeries,
		mobility: mobilitySeries,
		happiness: happinessSeries,
	};
};


interface RateInfoGraphProps {
	show: boolean
	startYear: number
	endYear: number
	primary?: Person
	partner?: Person
	targetRef: MutableRefObject<HTMLDivElement>
	selectedRate: RateType
	onRateSelect: Dispatch<SetStateAction<RateType>>
}

const RateInfoGraph: React.FC<RateInfoGraphProps> = (
	props: RateInfoGraphProps
) => {
	const { selectedRate, onRateSelect } = props;
	const { t } = useTranslation();

	const rateQuery = useQuery<RateData>(RATE_QUERY);

	const seriesDict = useMemo(() => {
		return createAllSeries(
			rateQuery.data || {
				fertilityRate: [],
				mobilityRate: [],
				happinessRate: [],
			},
			props.startYear,
			props.endYear,
			props.primary,
			props.partner
		);
	}, [
		rateQuery.data,
		props.startYear,
		props.endYear,
		props.primary,
		props.partner,
	]);


	if (!rateQuery.data) {
		return <LoadingSpinner overlay />;
	}

	return (
		<>
			<div className="rate-graph">
				<Graph
					show
					data={seriesDict[selectedRate]}
					startYear={props.startYear}
					endYear={props.endYear}
					targetRef={props.targetRef}
				/>
			</div>
			<div className="rate-selector-container">
				<div className="rate-selector">
					<RateButton
						text={t("features.graph.rate.fertility")}
						enabled={selectedRate === "fertility"}
						onClick={() => onRateSelect("fertility")}
					/>
					<RateButton
						text={t("features.graph.rate.mobility")}
						enabled={selectedRate === "mobility"}
						onClick={() => onRateSelect("mobility")}
					/>
					<RateButton
						text={t("features.graph.rate.happiness")}
						enabled={selectedRate === "happiness"}
						onClick={() => onRateSelect("happiness")}
					/>
				</div>
			</div>
		</>
	);
};

export default RateInfoGraph;
