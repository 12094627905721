import { Note, CalculatorType, NoteType, Role } from "shared/types";
import { NoteFormValues } from "features/notes/views/NoteEditModal";

const analyticsEvent = (event: string, params: Gtag.CustomParams): void => {
	window.gtag("event", event, params);
};

interface NoteEvent extends Gtag.CustomParams {
  calculator_type: CalculatorType
  note_type: NoteType
  // Google analytics does not support boolean parameters so we use strings instead
  group_note: string
}

const eventParamsFromNote = (note: Note): NoteEvent => {
	return {
		calculator_type: note.calculator,
		note_type: note.type,
		group_note: note.membership === undefined ? "true" : "false",
	};
};

const eventParamsFromNoteValues = (values: NoteFormValues): NoteEvent => {
	return {
		calculator_type: CalculatorType.Custom,
		note_type: values.type || NoteType.Milestone,
		group_note: values.membershipId === undefined ? "true" : "false",
	};
};

export const analyticsViewNote = (note: Note): void => {
	analyticsEvent("view_note", eventParamsFromNote(note));
};
export const analyticsDeleteNote = (note: Note): void => {
	analyticsEvent("delete_note", eventParamsFromNote(note));
};

export const analyticsShareNote = (note: Note): void => {
	analyticsEvent("share_note", eventParamsFromNote(note));
};

export const analyticsUnShareNote = (note: Note): void => {
	analyticsEvent("unshare_note", eventParamsFromNote(note));
};

export const analyticsEditNote = (values: NoteFormValues): void => {
	analyticsEvent("edit_note", eventParamsFromNoteValues(values));
};

export const analyticsAddNote = (values: NoteFormValues): void => {
	analyticsEvent("add_note", eventParamsFromNoteValues(values));
};

export const analyticsAddMembership = (role: Role): void => {
	analyticsEvent("add_membership", { role: role });
};

export const analyticsEditMembership = (role: Role): void => {
	analyticsEvent("edit_membership", { role: role });
};

export const analyticsDeleteMembership = (role: Role): void => {
	analyticsEvent("delete_membership", { role: role });
};
