import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { currencyFormatter } from "../../../../shared/utils";

interface NetWorthConfirmProps {
  netWorth: number
  wealthScore: number
  backUrl: string
}

const NetWorthConfirm: React.FC<NetWorthConfirmProps> = (
	props: NetWorthConfirmProps
) => {
	const history = useHistory();
	const { t } = useTranslation();

	return (
		<div className="text-center mt-3">
			<h4>{t("features.calculator.netWorth.netWorthResults")}</h4>
			<h2>{currencyFormatter.format(props.netWorth)}</h2>
			{/* <h4>{t("features.calculator.netWorth.wealthScoreResults")}</h4> */}
			{/* <h2>{props.wealthScore}</h2> */}
			<Button
				color="primary"
				onClick={() => {
					history.push(props.backUrl);
				}}
			>
				{t("features.calculator.netWorth.confirmBack")}
			</Button>
		</div>
	);
};

export default NetWorthConfirm;
