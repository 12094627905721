export enum MortgageFrequency {
  Monthly = 'MONTHLY',
  SemiMonthly = 'SEMIMONTHLY',
  Biweekly = 'BIWEEKLY',
  Weekly = 'WEEKLY',
}

export interface Mortgage {
  id?: number
  name: string
  value: number
  interest: number
  paymentFreq: MortgageFrequency
  amortizationYears: number
  termYears: number
  termInterest?: number
  totalInterest?: number
  periodPayment?: number
  netEquity?: number
  interestType?:string
}

export interface Property {
  id?: number
  name: string
  value: number
  annualGrowth: number
  yearModified: number
  ownershipPercent: number
  mortgage?: Mortgage
}

export interface PropertyInput {
  id?: number
  name: string
  value: number
  annualGrowth: number
  ownershipPercent: number
  mortgage?: Mortgage
}

export interface PropertyMutationInput {
  propertyInput: PropertyInput
}
