import { Reducer } from 'react';

export enum SmokingStatus {
  neverSmoked = 'NEVER_SMOKED',
  useTo_less_than_pack = 'USED_TO_LESS_PACK_DAILY',
  useTo_more_than_pack = 'USED_TO_MORE_PACK_DAILY',
  current_less_than_pack = 'CURRENT_LESS_PACK_DAILY',
  current_more_than_pack = 'CURRENT_MORE_PACK_DAILY',
}
export enum FormerLightSmoker {
  less_than_year_ago = 'LESS_THAN_YEAR_AGO',
  one_year_to_less_than_two_year_ago = 'USED_TO_LESS_PACK_DAILY',
  two_year_to_less_than_three_year_ago = 'CURRENT_LESS_PACK_DAILY',
  three_or_more_year_ago = 'CURRENT_MORE_PACK_DAILY',
}
export enum FormerHeavySmoker {
  less_than_year_ago = 'LESS_THAN_YEAR_AGO',
  one_year_to_less_than_two_year_ago = 'USED_TO_LESS_PACK_DAILY',
  two_year_to_less_than_three_year_ago = 'CURRENT_LESS_PACK_DAILY',
  three_or_more_year_ago = 'CURRENT_MORE_PACK_DAILY',
}
export enum AlcoholConsumption {
  less_than_four_week = 'LESS_THAN_FOUR_WEEK',
  four_to_twentyOne_week = 'FOUR_TO_TWENTYONE_WEEK',
  more_than_twentyOne_week = 'MORE_THAN_TWENTYONE_WEEK',
}
export enum Deprivation {
  below_average = 'BELOW_AVERAGE',
  average = 'AVERAGE',
  above_average = 'ABOVE_AVERAGE',
}
export enum Ethnicity {
  White = 'WHITE',
  Black = 'BLACK',
  Chinese = 'CHINESE',
  SoutheastAsian = 'SOUTHEAST_ASIAN',
  SouthOrWestAsian = 'SOUTH_WEST_ASIAN',
  LatinAmericanOrOther = 'LATIN_AMERICAN_OTHER',
}

export enum EducationLevel {
  post_secondary_school_graduate = 'POST_SECONDARY_SCHOOL_GRADUATE',
  high_school_graduate = 'HIGH_SCHOOL_GRADUATE',
  less_than_high_school_education = 'LESS_THAN_HIGH_SCHOOL_',
}
export interface State {
  heightCm?: number;
  dailySmoking?: number;
  weightKg?: number;
  smokingFrequency?: SmokingStatus;
  formerLightYearCount?: FormerLightSmoker;
  formerHeavyYearCount?: FormerHeavySmoker;
  alcoholConsumption?: AlcoholConsumption;
  deprivation?: Deprivation;
  drinksInLastWeek?: number;
  fruitJuiceIntake?: number;
  fruitIntake?: number;
  potatoIntake?: number;
  vegetableIntake?: number;
  vigorousActivityHours?: number;
  vigorousNonContinuousActivityHours?: number;
  lightActivityHours?: number;
  ethnicity?: Ethnicity;
  education?: EducationLevel;
  hasHeartDisease?: boolean;
  hasStroke?: boolean;
  hasCancer?: boolean;
  hasDiabetes?: boolean;
  hasNoneOfAbove?:boolean;
  hasCopd?: boolean;
  hoursSleepPerNight?: number;
  stressRating?: number;
  // happinessRating?: number;
  hoursSocializing?: number;
  // postalCode?: string;
}

export interface SetSmoking {
  type: 'set_smoking';
  smokingFrequency: SmokingStatus;
}
export interface SetFormerLightYear {
  type: 'set_former_light_year';
  formerLightYearCount: FormerLightSmoker;
}
export interface SetFormerHeavyYear {
  type: 'set_former_heavy_year';
  formerHeavyYearCount: FormerHeavySmoker;
}

export interface SetAlcohol {
  type: 'set_alcohol';
  alcoholConsumption: AlcoholConsumption;
}
export interface SetDeprivation {
  type: 'set_deprivation';
  deprivation: Deprivation;
}
export interface SetEducation {
  type: 'set_education';
  education: EducationLevel;
}
export interface SetAlcoholNumeric {
  type: 'set_alcohol_numeric';
  drinksInLastWeek: number;
}

export interface SetIntakes {
  type: 'set_intakes';
  fruitJuiceIntake: number;
  fruitIntake: number;
  potatoIntake: number;
  vegetableIntake: number;
}

export interface SetActivity {
  type: 'set_activity';
  vigorousActivityHours: number;
  vigorousNonContinuousActivityHours: number;
  lightActivityHours: number;
}

export interface SetEthnicity {
  type: 'set_ethnicity';
  ethnicity: Ethnicity;
}

export interface SetWeightHeight {
  type: 'set_weight_height';
  heightCm: number;
  weightKg: number;
}

export interface SetHealth {
  type: 'set_health';
  hasHeartDisease: boolean;
  hasStroke: boolean;
  hasCancer: boolean;
  hasDiabetes: boolean;
  hasNoneOfAbove:boolean;
}

export interface SetExtras {
  type: 'set_extras';
  hoursSleepPerNight: number;
  stressRating: number;
  //happinessRating: number;
   hoursSocializing: number;
  // postalCode: string;
}

export type Action =
  | SetSmoking
  | SetAlcohol
  | SetAlcoholNumeric
  | SetIntakes
  | SetActivity
  | SetEthnicity
  | SetEducation
  | SetWeightHeight
  | SetHealth
  | SetExtras
  | SetFormerLightYear
  | SetFormerHeavyYear
  | SetDeprivation;

export const initialState: State = {};

const reducer: Reducer<State, Action> = (state, action): State => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, ...payload } = action;
  return { ...state, ...payload };
};

export default reducer;
