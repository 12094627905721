import React,{useState} from 'react';
import { useTranslation } from 'react-i18next';
import '../styles.scss';
import {
  Button,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Formik, FormikProps, Form, Field, FieldProps } from 'formik';
import * as yup from 'yup';
import { SetIntakes } from '../reducer';
import { showLifeExpectancyDeit } from './LifeExpectancy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface FormValues {
  fruitJuice?: number;
  fruit?: number;
  potatoes?: number;
  vegetables?: number;
}

interface Props {
  fruitJuice?: number;
  fruit?: number;
  potatoes?: number;
  vegetables?: number;
  setIntakes: (s: SetIntakes) => void;
}

const FruitVegetables: React.FC<Props> = ({
  fruitJuice,
  fruit,
  potatoes,
  vegetables,
  setIntakes,
}: Props) => {
  const { t } = useTranslation();

  const history = useHistory();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const handleSubmit = (values: FormValues) => {
    if (
      values.fruitJuice !== undefined &&
      values.fruit !== undefined &&
      values.potatoes !== undefined &&
      values.vegetables !== undefined
    ) {
      setButtonDisabled(true)
      setIntakes({
        type: 'set_intakes',
        fruitJuiceIntake: values.fruitJuice,
        fruitIntake: values.fruit,
        potatoIntake: values.potatoes,
        vegetableIntake: values.vegetables,
      });

      // let result = showLifeExpectancyDeit(values.fruitJuice,values.fruit,values.potatoes,values.vegetables);
      // toast.info(`Predicted Life Expectancy: ${result}`, {
      //   position: 'top-right',
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   onClose: () => {
      //     history.push('/conditions');
      //   },
      // });
      showLifeExpectancyDeit(values.fruitJuice,values.fruit,values.potatoes,values.vegetables);
      history.push('/conditions');
    }
  };

  const validationSchema = yup.object().shape({
    fruitJuice: yup
      .number()
      .required(
        t('features.calculator.lifeExpectancy.fruitVeg.fruitJuice.errors.empty')
      )
      .min(
        0,
        t(
          'features.calculator.lifeExpectancy.fruitVeg.fruitJuice.errors.negative'
        )
      )
      .integer(
        t(
          'features.calculator.lifeExpectancy.fruitVeg.fruitJuice.errors.nonInteger'
        )
      ),
    fruit: yup
      .number()
      .required(
        t('features.calculator.lifeExpectancy.fruitVeg.fruit.errors.empty')
      )
      .min(
        0,
        t('features.calculator.lifeExpectancy.fruitVeg.fruit.errors.negative')
      )
      .integer(
        t('features.calculator.lifeExpectancy.fruitVeg.fruit.errors.nonInteger')
      ),
    potatoes: yup
      .number()
      .required(
        t('features.calculator.lifeExpectancy.fruitVeg.potatoes.errors.empty')
      )
      .min(
        0,
        t(
          'features.calculator.lifeExpectancy.fruitVeg.potatoes.errors.negative'
        )
      )
      .integer(
        t(
          'features.calculator.lifeExpectancy.fruitVeg.potatoes.errors.nonInteger'
        )
      ),
    vegetables: yup
      .number()
      .required(
        t('features.calculator.lifeExpectancy.fruitVeg.vegetables.errors.empty')
      )
      .min(
        0,
        t(
          'features.calculator.lifeExpectancy.fruitVeg.vegetables.errors.negative'
        )
      )
      .integer(
        t(
          'features.calculator.lifeExpectancy.fruitVeg.vegetables.errors.nonInteger'
        )
      ),
  });

  return (
    <Container className='content'>
      <h1 className='display-4 text-center title'>
        {t('features.calculator.lifeExpectancy.fruitVeg.title')}
      </h1>
      <Formik
        initialValues={{
          fruitJuice: fruitJuice,
          fruit: fruit,
          potatoes: potatoes,
          vegetables: vegetables,
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
      >
        {({ errors }: FormikProps<FormValues>) => (
          <Form>
            <h6>
              {t(
                'features.calculator.lifeExpectancy.fruitVeg.fruitJuice.label'
              )}
            </h6>
            <Field name='fruitJuice' type='number'>
              {({ field }: FieldProps) => (
                <InputGroup className='mb-2'>
                  <Input
                    placeholder={t(
                      'features.calculator.lifeExpectancy.fruitVeg.fruitJuice.placeholder'
                    )}
                    bsSize='lg'
                    type={'number'}
                    className={`goal custom${
                      errors.fruitJuice ? ' has-errors' : ''
                    }`}
                    {...field}
                  />
                  <InputGroupAddon
                    addonType='append'
                    className={`${errors.fruitJuice ? 'has-errors' : ''}`}
                  >
                    {t('features.calculator.lifeExpectancy.units.frequency')}
                  </InputGroupAddon>
                </InputGroup>
              )}
            </Field>
            {errors.fruitJuice && (
              <div className='form-error'>{errors.fruitJuice}</div>
            )}
            <h6>
              {t('features.calculator.lifeExpectancy.fruitVeg.fruit.label')}
            </h6>
            <Field name='fruit' type='number'>
              {({ field }: FieldProps) => (
                <InputGroup className='mb-2'>
                  <Input
                    placeholder={t(
                      'features.calculator.lifeExpectancy.fruitVeg.fruit.placeholder'
                    )}
                    bsSize='lg'
                    type={'number'}
                    className={`goal custom${
                      errors.fruit ? ' has-errors' : ''
                    }`}
                    {...field}
                  />
                  <InputGroupAddon
                    addonType='append'
                    className={`${errors.fruit ? 'has-errors' : ''}`}
                  >
                    {t('features.calculator.lifeExpectancy.units.frequency')}
                  </InputGroupAddon>
                </InputGroup>
              )}
            </Field>
            {errors.fruit && <div className='form-error'>{errors.fruit}</div>}
            <h6>
              {t('features.calculator.lifeExpectancy.fruitVeg.potatoes.label')}
            </h6>
            <Field name='potatoes' type='number'>
              {({ field }: FieldProps) => (
                <InputGroup className='mb-2'>
                  <Input
                    placeholder={t(
                      'features.calculator.lifeExpectancy.fruitVeg.potatoes.placeholder'
                    )}
                    bsSize='lg'
                    type={'number'}
                    className={`goal custom${
                      errors.potatoes ? ' has-errors' : ''
                    }`}
                    {...field}
                  />
                  <InputGroupAddon
                    addonType='append'
                    className={`${errors.potatoes ? 'has-errors' : ''}`}
                  >
                    {t('features.calculator.lifeExpectancy.units.frequency')}
                  </InputGroupAddon>
                </InputGroup>
              )}
            </Field>
            {errors.potatoes && (
              <div className='form-error'>{errors.potatoes}</div>
            )}
            <h6>
              {t(
                'features.calculator.lifeExpectancy.fruitVeg.vegetables.label'
              )}
            </h6>
            <Field name='vegetables' type='number'>
              {({ field }: FieldProps) => (
                <InputGroup className='mb-2'>
                  <Input
                    placeholder={t(
                      'features.calculator.lifeExpectancy.fruitVeg.vegetables.placeholder'
                    )}
                    bsSize='lg'
                    type={'number'}
                    className={`goal custom${
                      errors.vegetables ? ' has-errors' : ''
                    }`}
                    {...field}
                  />
                  <InputGroupAddon
                    addonType='append'
                    className={`${errors.vegetables ? 'has-errors' : ''}`}
                  >
                    {t('features.calculator.lifeExpectancy.units.frequency')}
                  </InputGroupAddon>
                </InputGroup>
              )}
            </Field>
            {errors.vegetables && (
              <div className='form-error'>{errors.vegetables}</div>
            )}
            <hr />
            <Button disabled={buttonDisabled} type='submit' color='primary' block size='lg'>
              {t('features.getstarted.next')}
            </Button>
            <Button
              outline
              color='link'
              onClick={() => {
                history.goBack();
              }}
              className='w-100'
            >
              {t('features.calculator.lifeExpectancy.fruitVeg.confirmBack')}
            </Button>
          </Form>
        )}
      </Formik>
      <ToastContainer />
    </Container>
  );
};

export default FruitVegetables;
