import React, { useState, useCallback } from 'react'
import { Formik, Form, FormikProps, Field, ErrorMessage } from 'formik'
import {
  Button,
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap'
import { Property, MortgageFrequency, PropertyInput } from '../types'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { MAX_ASSET_VALUE } from 'shared/constants'
import '../styles.scss'
import { useHistory } from 'react-router-dom'
import ConfirmationModal from 'shared/views/ConfirmModal'

interface Props {
  property?: Property
  onSubmit: (values: PropertyInput) => Promise<void>
  deleteProperty: () => Promise<void>
  mutationError?: string
  loading?: boolean
  backUrl?: string
}

export interface FormValues {
  name?: string
  value?: number
  agr?: number
  ownershipPercent?: number
  mortgageName?: string
  mortgageAmount?: number
  interest?: number
  frequency?: string
  amortizationYears?: number
  amortizationMonths?: number
  termYears?: number
  termMonths?: number
  interestType?:string
}

const RealEstateCalculatorForm: React.FC<Props> = ({
  property,
  onSubmit,
  mutationError,
  loading,
  backUrl,
  deleteProperty,
}: Props) => {
  const { t } = useTranslation()
  const [showMortgageFields, setShowMortgageFields] = useState<boolean>(
    !!property?.mortgage?.name
  )
  const history = useHistory()
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)

  const stringSchema = yup
    .string()
    .required(t('features.calculator.netWorth.errors.required'))

  const percentageSchema = yup
    .number()
    .required(t('features.calculator.netWorth.errors.required'))
    .positive(
      t('features.calculator.realestate.errors.positive', {
        max: 100,
      })
    )
    .max(
      100,
      t('features.calculator.realestate.errors.positive', {
        max: 100,
      })
    )

  const dollarSchema = yup
    .number()
    .required(t('features.calculator.netWorth.errors.required'))
    .min(
      0,
      t('features.calculator.netWorth.errors.outOfRange', {
        max: MAX_ASSET_VALUE.toLocaleString(),
      })
    )
    .max(
      MAX_ASSET_VALUE,
      t('features.calculator.netWorth.errors.outOfRange', {
        max: MAX_ASSET_VALUE.toLocaleString(),
      })
    )

  const numberSchema = yup
    .number()
    .required(
      t('features.calculator.lifeExpectancy.alcoholNumeric.errors.empty')
    )
    .integer(
      t('features.calculator.lifeExpectancy.alcoholNumeric.errors.nonInteger')
    )
    .min(
      0,
      t('features.calculator.lifeExpectancy.alcoholNumeric.errors.negative')
    )

  const validationSchema = yup.object().shape({
    name: stringSchema,
    value: dollarSchema,
    agr: percentageSchema,
    ownershipPercent: percentageSchema,

    mortgageName: showMortgageFields ? stringSchema : yup.string(),
    mortgageAmount: showMortgageFields ? dollarSchema : yup.number(),
    interest: showMortgageFields ? percentageSchema : yup.number(),
    frequency: showMortgageFields ? stringSchema : yup.string(),
    amortizationYears: showMortgageFields ? numberSchema : yup.number(),
    termYears: showMortgageFields ? numberSchema : yup.number(),
    interestType:showMortgageFields ? stringSchema : yup.string(),
  })

  const handleSubmit = useCallback(
    ({
      name,
      value,
      agr,
      ownershipPercent,
      mortgageName,
      mortgageAmount,
      interest,
      frequency,
      amortizationYears,
      termYears,
      interestType
    }: FormValues) => {
      // Assert required fields are valid
      if (name === undefined) return
      if (value === undefined) return
      if (agr === undefined) return
      if (ownershipPercent === undefined) return

      if (showMortgageFields) {
        // Assert mortgage fields are valid
        if (mortgageName === undefined) return
        if (mortgageAmount === undefined) return
        if (interest === undefined) return
        if (amortizationYears === undefined) return
        if (termYears === undefined) return

        const paymentFreq = Object.values(MortgageFrequency).find(
          f => f === frequency
        )
        if (paymentFreq === undefined) return

        onSubmit({
          name: name,
          value: value,
          annualGrowth: agr,
          ownershipPercent: ownershipPercent,
          mortgage: {
            name: mortgageName,
            value: mortgageAmount,
            interest: interest,
            paymentFreq: paymentFreq,
            amortizationYears: amortizationYears,
            termYears: termYears,
            interestType:interestType
          },
        })
      } else {
        onSubmit({
          name: name,
          value: value,
          annualGrowth: agr,
          ownershipPercent: ownershipPercent,
        })
      }
    },
    [onSubmit, showMortgageFields]
  )

  const numberOrUndefined: (n: number | undefined) => number | undefined = (
    n: number | undefined
  ) => {
    return isNaN(Number(n)) ? undefined : n
  }

  return (
    <>
      {showConfirmModal && (
        <ConfirmationModal
          isOpen={showConfirmModal}
          title={t('features.calculator.realestate.confirmModal.title')}
          description={t(
            'features.calculator.realestate.confirmModal.description'
          )}
          closeModal={() => setShowConfirmModal(false)}
          confirm={async () => {
            await setShowConfirmModal(false)
            await deleteProperty()
          }}
          confirmText={t('features.calculator.realestate.confirmModal.confirm')}
          cancelText={t('features.calculator.realestate.confirmModal.cancel')}
        />
      )}
      <Container className="content">
        <Formik
          initialValues={{
            name: property?.name || undefined,
            value: numberOrUndefined(property?.value),
            agr: numberOrUndefined(property?.annualGrowth),
            ownershipPercent: property?.ownershipPercent || 100,
            mortgageName: property?.mortgage?.name || undefined,
            mortgageAmount: numberOrUndefined(property?.mortgage?.value),
            interest: numberOrUndefined(property?.mortgage?.interest),
            frequency: property?.mortgage?.paymentFreq || undefined,
            interestType: property?.mortgage?.interestType || 'Fixed',
            amortizationYears: numberOrUndefined(
              property?.mortgage?.amortizationYears
            ),
            termYears: numberOrUndefined(property?.mortgage?.termYears),
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
        >
          {(formik: FormikProps<FormValues>) => {
            return (
              <Form>
                <h2>{t('features.calculator.realestate.propertyDetails')}</h2>
                <Row form className="mb-3">
                  <Col sm={4} className="mb-1 networth-form-column">
                    <label htmlFor={`name`}>
                      {t(
                        'features.calculator.realestate.labels.property.address'
                      )}
                    </label>
                    <Field
                      as={Input}
                      name={'name'}
                      onChange={formik.handleChange}
                      disabled={formik.isSubmitting}
                      placeholder={t(
                        `features.calculator.realestate.default.property.address`
                      )}
                    />
                    <ErrorMessage name={`name`}>
                      {msg => <div className="mt-1 form-error">{msg}</div>}
                    </ErrorMessage>
                  </Col>

                  <Col sm={4} className="networth-form-column">
                    <label htmlFor={`value`}>
                      {t(
                        'features.calculator.realestate.labels.property.value'
                      )}
                    </label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                      <Field
                        as={Input}
                        type="number"
                        name={`value`}
                        onChange={formik.handleChange}
                        disabled={formik.isSubmitting}
                        placeholder={t(
                          'features.calculator.realestate.default.property.value'
                        )}
                      />
                    </InputGroup>
                    <ErrorMessage name={`value`}>
                      {msg => <div className="mt-1 form-error">{msg}</div>}
                    </ErrorMessage>
                  </Col>

                  <Col sm={2} className="networth-form-column">
                    <label htmlFor={`agr`}>
                      {t('features.calculator.realestate.labels.property.agr')}
                    </label>
                    <InputGroup>
                      <Field
                        as={Input}
                        type="number"
                        name={`agr`}
                        onChange={formik.handleChange}
                        disabled={formik.isSubmitting}
                      />
                      <InputGroupAddon addonType="append">%</InputGroupAddon>
                    </InputGroup>
                    <ErrorMessage name={`agr`}>
                      {msg => <div className="mt-1 form-error">{msg}</div>}
                    </ErrorMessage>
                  </Col>

                  <Col sm={2} className="networth-form-column">
                    <label htmlFor={`ownershipPercent`}>
                      {t(
                        'features.calculator.realestate.labels.property.ownership'
                      )}
                    </label>
                    <InputGroup>
                      <Field
                        as={Input}
                        type="number"
                        name={`ownershipPercent`}
                        onChange={formik.handleChange}
                        disabled={formik.isSubmitting}
                      />
                      <InputGroupAddon addonType="append">%</InputGroupAddon>
                    </InputGroup>
                    <ErrorMessage name={`ownershipPercent`}>
                      {msg => <div className="mt-1 form-error">{msg}</div>}
                    </ErrorMessage>
                  </Col>
                </Row>

                {showMortgageFields && (
                  <>
                    <h2 className="mb-4">
                      {t('features.calculator.realestate.mortgageDetails')}
                    </h2>
                    <Row form className="mb-3">
                      <Col sm={3} className="mb-1 networth-form-column">
                        <label htmlFor={`mortgageName`} className="mt-1">
                          {t(
                            'features.calculator.realestate.labels.mortgage.name'
                          )}
                        </label>
                      </Col>
                      <Col sm={9} className="mb-1 networth-form-column">
                        <Field
                          as={Input}
                          name={`mortgageName`}
                          onChange={formik.handleChange}
                          disabled={formik.isSubmitting}
                          placeholder={t(
                            `features.calculator.realestate.default.mortgage.name`
                          )}
                        />
                        <ErrorMessage name={`mortgageName`}>
                          {msg => <div className="mt-1 form-error">{msg}</div>}
                        </ErrorMessage>
                      </Col>
                    </Row>

                    <Row form className="mb-3">
                      <Col sm={3} className="mb-1 networth-form-column">
                        <label htmlFor={`mortgageAmount`} className="mt-1">
                          {t(
                            'features.calculator.realestate.labels.mortgage.amount'
                          )}
                        </label>
                      </Col>
                      <Col sm={9} className="mb-1 networth-form-column">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            $
                          </InputGroupAddon>
                          <Field
                            as={Input}
                            type="number"
                            name={`mortgageAmount`}
                            onChange={formik.handleChange}
                            disabled={formik.isSubmitting}
                            placeholder={t(
                              'features.calculator.realestate.default.mortgage.amount'
                            )}
                          />
                        </InputGroup>
                        <ErrorMessage name={`mortgageAmount`}>
                          {msg => <div className="mt-1 form-error">{msg}</div>}
                        </ErrorMessage>
                      </Col>
                    </Row>

                    <Row form className="mb-3">
                      <Col sm={3} className="mb-1 networth-form-column">
                        <label htmlFor={`interest`} className="mt-1">
                          {t(
                            'features.calculator.realestate.labels.mortgage.interest'
                          )}
                        </label>
                      </Col>
                      <Col sm={7} className="mb-1 networth-form-column">
                        <InputGroup>
                          <Field
                            as={Input}
                            type="number"
                            name={`interest`}
                            onChange={formik.handleChange}
                            disabled={formik.isSubmitting}
                            placeholder={t(
                              'features.calculator.realestate.default.mortgage.interest'
                            )}
                          />
                          {/*<InputGroupAddon addonType="append">
                            {t(
                              'features.calculator.realestate.labels.mortgage.interestSuffix'
                            )}
                          </InputGroupAddon>*/}
                        </InputGroup>
                        <ErrorMessage name={`interest`}>
                          {msg => <div className="mt-1 form-error">{msg}</div>}
                        </ErrorMessage>
                      </Col>
                      <Col sm={2} className="mb-1 networth-form-column">
                        <Field
                          as={Input}
                          type="select"
                          name={`interestType`}
                          onChange={formik.handleChange}
                          disabled={formik.isSubmitting}

                        >
                          <option
                            value={'Fixed'}
                            label={'% Fixed'}
                          >
                            {'% Fixed'}
                          </option>
                          <option
                            value={'Variable'}
                            label={'% Variable'}
                          >
                            {'% Variable'}
                          </option>

                        </Field>
                        {/* <ErrorMessage name={`frequency`}>
                          {msg => <div className="mt-1 form-error">{msg}</div>}
                        </ErrorMessage> */}
                      </Col>
                    </Row>

                    <Row form className="mb-3">
                      <Col sm={3} className="mb-1 networth-form-column">
                        <label htmlFor={`frequency`} className="mt-1">
                          {t(
                            'features.calculator.realestate.labels.mortgage.frequency'
                          )}
                        </label>
                      </Col>
                      <Col sm={9} className="mb-1 networth-form-column">
                        <Field
                          as={Input}
                          type="select"
                          name={`frequency`}
                          onChange={formik.handleChange}
                          disabled={formik.isSubmitting}
                        >
                          <option
                            value={''}
                            label={t(
                              'features.calculator.realestate.frequency.DEFAULT'
                            )}
                          >
                            {t(
                              'features.calculator.realestate.frequency.DEFAULT'
                            )}
                          </option>
                          {Object.values(MortgageFrequency).map(type => (
                            <option
                              key={type}
                              value={type}
                              label={t(
                                `features.calculator.realestate.frequency.${type}`
                              )}
                            >
                              {t(
                                `features.calculator.realestate.frequency.${type}`
                              )}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage name={`frequency`}>
                          {msg => <div className="mt-1 form-error">{msg}</div>}
                        </ErrorMessage>
                      </Col>
                    </Row>

                    <Row form className="mb-3">
                      <Col sm={3} className="mb-1 networth-form-column">
                        <label htmlFor={`amortization`} className="mt-1">
                          {t(
                            'features.calculator.realestate.labels.mortgage.amortization'
                          )}
                        </label>
                      </Col>
                      <Col sm={9} className="mb-1 networth-form-column">
                        <InputGroup>
                          <Field
                            as={Input}
                            type="number"
                            name={`amortizationYears`}
                            onChange={formik.handleChange}
                            disabled={formik.isSubmitting}
                          />
                          <InputGroupAddon addonType="append">
                            {t(
                              'features.calculator.realestate.labels.mortgage.years'
                            )}
                          </InputGroupAddon>
                        </InputGroup>

                        <ErrorMessage name={`amortizationYears`}>
                          {msg => <div className="mt-1 form-error">{msg}</div>}
                        </ErrorMessage>
                      </Col>
                    </Row>
                    <Row form className="mb-3">
                      <Col sm={3} className="mb-1 networth-form-column">
                        <label htmlFor={`property.value`} className="mt-1">
                          {t(
                            'features.calculator.realestate.labels.mortgage.currentTerm'
                          )}
                        </label>
                      </Col>
                      <Col sm={9} className="mb-1 networth-form-column">
                        <InputGroup>
                          <Field
                            as={Input}
                            type="number"
                            name={`termYears`}
                            onChange={formik.handleChange}
                            disabled={formik.isSubmitting}
                          />
                          <InputGroupAddon addonType="append">
                            {t(
                              'features.calculator.realestate.labels.mortgage.years'
                            )}
                          </InputGroupAddon>
                        </InputGroup>
                        <ErrorMessage name={`termYears`}>
                          {msg => <div className="mt-1 form-error">{msg}</div>}
                        </ErrorMessage>
                      </Col>
                    </Row>
                    <Button
                      title="[Hide]"
                      color="secondary"
                      className="mb-3 col-12 col-sm-6 mt-1"
                      onClick={() => setShowMortgageFields(false)}
                      disabled={formik.isSubmitting}
                    >
                      {t('features.calculator.realestate.hideMortgage')}
                    </Button>
                  </>
                )}
                {!showMortgageFields && (
                  <>
                    <Button
                      title="[Show]"
                      color="success"
                      className="mb-3 col-12 col-sm-6 mt-1"
                      onClick={() => setShowMortgageFields(true)}
                      disabled={formik.isSubmitting}
                    >
                      {t('features.calculator.realestate.showMortgage')}
                    </Button>
                  </>
                )}
                <Button
                  type="submit"
                  color="primary"
                  className="mt-4"
                  disabled={formik.isSubmitting}
                  block
                >
                  {t('features.calculator.realestate.submit')}
                </Button>
                {mutationError && (
                  <div className="form-error mt-0">{mutationError}</div>
                )}
                {property && (
                  <Button
                    color="danger"
                    outline
                    className="mt-4"
                    disabled={formik.isSubmitting}
                    onClick={() => setShowConfirmModal(true)}
                    block
                  >
                    {t('features.calculator.realestate.delete')}
                  </Button>
                )}
                {backUrl && (
                  <Button
                    color="link"
                    outline
                    className="mt-4"
                    disabled={formik.isSubmitting}
                    onClick={() => history.push(backUrl || '/')}
                    block
                  >
                    {t('features.calculator.realestate.return')}
                  </Button>
                )}
              </Form>
            )
          }}
        </Formik>
      </Container>
    </>
  )
}

export default RealEstateCalculatorForm
