import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
  
} from 'react'
import { Note } from 'shared/types'
import TimelineRulerNote, { TimelineRulerNoteMulti } from './TimelineRulerNote'
import '../styles.scss'
import AxisTranslator from 'shared/utils/axis'
import { groupNotes } from 'shared/utils'

interface TimelineRulerProps {
  start: number
  end: number
  notes?: Note[]
  onViewNote: (note: Note) => void
  // targetRef?: MutableRefObject<HTMLDivElement>
  // props?: any
}

interface TimelineRulerMarksProps {
  start: number
  length: number
  mouseCustomEvent:any
}

interface TimelineRulerScrollerProps {
  start: number
  end: number
  notes?: Note[]
  targetRef: MutableRefObject<HTMLDivElement>
  onViewNote: (note: Note) => void
}


export interface PropsInterface {
  pointerYear?: any;
  pointerYearChild?:any;
  //moveTarget: (x: number) => void
  
}
export const TimelineRuler: React.FC<PropsInterface & TimelineRulerProps> = ({
  start,
  end,
  notes = [],
  onViewNote,
  pointerYear,
  pointerYearChild
  


}: TimelineRulerProps & PropsInterface) => {
  // console.log('targetRef',targetRef);
  const [isLoadSet, setIsLoadSet] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
    const [screenLeft, setScreenLeft] = useState<number>(517);
    const [isActive, setIsActive] = useState<number>(0);
    

    const [viewWidth, setViewWidth] = useState(0);
	const [scrollWidth, setScrollWidth] = useState(1);
	const [scrollPercentage, setScrollPercentage] = useState(0);
	const [isDragging, setIsDragging] = useState(false);
	const [boxWidth, setBoxWidth] = useState(0);

  const [lastTouchX, setLastTouchX] = useState(0);
  
   window.addEventListener("scroll", (event) => {
      setScrollLeft(window.pageXOffset);
      //console.log('event', window);
  });


  //document.addEventListener("DOMContentLoaded", function(event) { 
    
 

  var tOLeft2 = 3000;

  
  var pointerMove=2030;
  var nowYear2 = new Date().getFullYear()
  var g=0;
  if(isLoadSet===0){
  while (pointerMove !== nowYear2) {
    // if(g>500 || tOLeft2<300){
    //   pointerMove = nowYear2;
    // }
   
  
    tOLeft2 = tOLeft2-3;
    //console.log('tOLeft2_tOLeft2',tOLeft2);
  var draggedOver = document.elementFromPoint(tOLeft2, 130);
      var classLists = draggedOver?.classList;
      var firstChild = draggedOver?.firstChild;
      
      var is_valid=0;
      classLists?.forEach(item=>{
        if(item=='short-tick'|| item=='long-tick'){
          is_valid=1;
        }
      });
      var dataPt:any =0;

      if(is_valid===1){
        dataPt = draggedOver?.getAttribute('data-start');
      }else{
        var draggedOver = document.elementFromPoint(tOLeft2-2, 130);
        var classLists = draggedOver?.classList;
        var firstChild = draggedOver?.firstChild;
        classLists?.forEach(item=>{
          if(item==='short-tick'|| item==='long-tick'){
            is_valid=1;
          }
        });
        if(is_valid===1){
          dataPt = draggedOver?.getAttribute('data-start');
         // console.log('dataPt=0',dataPt);
        }
      }
     // console.log('matched=',dataPt);
     // console.log('is_valid=',is_valid);
      if(is_valid===1){
        dataPt = draggedOver?.getAttribute('data-start');
        pointerMove = dataPt;
        //console.log('pointerMove=',dataPt);
        //console.log('pointerMove=',dataPt);
        // if(dataPt==nowYear2){
        //   pointerMove = nowYear2;
        // }
      }

      
     
      if(pointerMove<=nowYear2 || tOLeft2<100){
        //console.log('ninnn',pointerMove);
        //console.log('ninnn22',nowYear2);
        
    pointerMove = nowYear2;
    
    // return;
      }

    // console.log( "Block statement execution no." )
    g++;
}

setTimeout(function(){
setIsLoadSet(1);
if(nowYear2===dataPt){
  setScreenLeft(tOLeft2);
}else{
  setScreenLeft(tOLeft2);
}

},200);
}

//});

    
  const rulerStart = start - (start % 5)
  const rulerEnd = end + (5 - (end % 5))
  const rulerLength = rulerStart - rulerEnd

  const fiveYears = []

  const nowYear = new Date().getFullYear()

  // push groups of 5 ticks
  let i = rulerStart
  while (i + 4 < rulerEnd) {
    fiveYears.push({ length: 5, start: i })
    i = i + 5
  }
  // if length is not 4mod5, add a shorter one to the end.
  if (rulerLength % 5 !== 4) {
    fiveYears.push({
      length: (rulerLength % 5) + 1,
      start: rulerEnd - (rulerLength % 5),
    })
  } else {
    fiveYears.push({ length: 5, start: rulerEnd - 4 })
  }
  

  const handleSubmit = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    // console.log('move',e);  
    if (isActive===1){
      //console.log("==========value =======",e.currentTarget.getAttribute('data-start'))
      //console.log("==========valuescrollLeft",pointerYearChild)
        //  pointerYear(e.currentTarget.getAttribute('data-start'));
        //  let  gt = e.screenX+pointerYearChild;;
        //  if(gt>0){
        //   setScreenLeft(gt);
        //  }
         
        
      
        // console.log('element', e.currentTarget.getAttribute('data-start'));  
    }
    
};

  const handleSubmitDown = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsActive(1);
    console.log('e', e);
    window.addEventListener("mousemove", handleMouseDrag);

  //   const currentRef = ref.current || document.createElement("div");
  // currentRef.addEventListener("touchstart", handleTouchStartRuler);
  // window.addEventListener("touchend", handleTouchEndRuler);
  // currentRef.addEventListener("touchmove", handleTouchDragRuler);

    // window.addEventListener("touchstart", handleTouchStartRuler);
    // window.addEventListener("touchmove", handleTouchDragRuler);
    // window.addEventListener("touchend", handleTouchEndRuler);
    // console.log('e', isActive);
};

const handleStart = () => {
  setIsDragging(true);
};

const handleEnd = () => {
  setIsDragging(false);
};
const handleMouseDown = useCallback((event: MouseEvent) => {
  handleStart();
  event.preventDefault();
}, []);

const handleMouseUp = useCallback(() => {
  handleEnd();
}, []);

const handleDrag = useCallback(
  (deltaX: number) => {
    if (isDragging) {
      const percentage = deltaX / (viewWidth - boxWidth);
     // moveTarget(percentage * (scrollWidth - viewWidth));
    }
  },
  [isDragging, viewWidth, boxWidth, scrollWidth]
);

const handleMouseDrag = useCallback(
  (event: MouseEvent) => {
    // console.log('event.movementX',pointerYearChild);
    // console.log('event.movementX',event.screenX);
    let  gt = event.screenX+pointerYearChild;;
         if(gt>0){
           let yearAdded = Math.round((gt-70)/10);
          let yearPoistion = rulerStart+yearAdded;
         pointerYear(yearPoistion);
           
          setScreenLeft(gt);
         }
    event.preventDefault();
  },
  [handleDrag,pointerYearChild,pointerYear,rulerStart]
);

const handleTouchStartRuler = useCallback(
  (event: TouchEvent) => {
    // setIsDragging(true);
    setIsActive(1);
    event.preventDefault();
  },
  [setLastTouchX]
);

const handleTouchEndRuler = useCallback(() => {
  console.log('touchEnd');
  // setIsDragging(false);
  setIsActive(0);
}, []);
const handleTouchDragRuler = useCallback(
  (event: TouchEvent) => {

    let  gt = event.targetTouches[0].clientX +pointerYearChild;;
         if(gt>0){
           let yearAdded = Math.round((gt-70)/10);
          let yearPoistion = rulerStart+yearAdded;
         pointerYear(yearPoistion);
           
          setScreenLeft(gt);
         }


    // console.log('event.targetTouches[0].clientX',event.targetTouches[0].clientX);
    // handleDrag(event.targetTouches[0].clientX - lastTouchX);
    // setLastTouchX(event.targetTouches[0].clientX);
    event.preventDefault();
  },
  [lastTouchX, setLastTouchX, handleDrag,pointerYearChild]
);


const ref = useRef<HTMLDivElement>(document.createElement("div"));

useEffect(() => {
  //const currentRef = ref.current || document.createElement("div");


  let rulerCurrentNode = document.getElementById("rulerCurrent");


  rulerCurrentNode?.addEventListener("touchstart", handleTouchStartRuler);
  window.addEventListener("touchend", handleTouchEndRuler);
  rulerCurrentNode?.addEventListener("touchmove", handleTouchDragRuler);

  return () => {
    
    
    rulerCurrentNode?.removeEventListener("touchstart", handleTouchStartRuler);
    window.removeEventListener("touchend", handleTouchEndRuler);
    rulerCurrentNode?.removeEventListener("touchmove", handleTouchDragRuler);
  };
}, [
  handleTouchDragRuler,
  handleTouchStartRuler,
  handleTouchEndRuler,
]);

const handleSubmitUp2 = () => {
    setIsActive(0);
    window.removeEventListener("mousemove", handleMouseDrag);
    

    // window.removeEventListener("touchstart", handleTouchStartRuler);
    // window.removeEventListener("touchmove", handleTouchDragRuler);
    // window.removeEventListener("touchend", handleTouchEndRuler);
    // console.log('e', isActive);

};
window.addEventListener("mouseup", handleSubmitUp2);
  const groups = groupNotes(notes || [])
  // console.log('fiveYears',fiveYears);
  return (
    <>
      <div className="ruler main-background aligner">
        <div className="ruler main-background background" />
      </div>
      <div className="ruler ruler-container">
      <div className="rulerCurrent" id="rulerCurrent" 
       onMouseDown={handleSubmitDown}
        style={{ left: screenLeft }}
      ></div>
        {fiveYears.map(({ length, start }, index) => {
          return (
            <>
            
            <TimelineRulerMarks
              length={length}
              start={start}
              mouseCustomEvent={handleSubmit}
              key={`ruler-mark-${index}`}
            />
            </>
            
          )
        })}
        <div className="ruler note-background aligner">
          <div className="ruler note-background background" />
        </div>
        <div
          className="year-redline ruler"
          style={{
            left: `${AxisTranslator.year(nowYear - rulerStart)}px`,
          }}
        />
        {groups.map((group, index) => {
          const firstNote = group[0]
          if (group.length === 1) {
            return (
              <TimelineRulerNote
                note={firstNote}
                year={firstNote.year}
                start={rulerStart}
                key={`ruler-note-${index}`}
                onViewNote={() => onViewNote(firstNote)}
              />
            )
          } else {
            return (
              <TimelineRulerNoteMulti
                notes={group}
                year={firstNote.year}
                start={rulerStart}
                key={`ruler-note-${index}`}
                onViewNotes={() => onViewNote(firstNote)}
              />
            )
          }
        })}
      </div>
    </>
  )
}

const TimelineRulerMarks: React.FC<TimelineRulerMarksProps> = ({
  start,
  length = 5,
  mouseCustomEvent
}: TimelineRulerMarksProps) => {
  if (length < 1)
    throw Error('TimelineRulerMarks requires a length of at least 1.')
  
  const alertCode = (event:Event) => {
    // alert('eeeeee');
    //console.log('n',event);
  }  
  var nowYear4 = new Date().getFullYear()
  if(nowYear4==start){

  }
    
  return (
    <div className="ruler marks group" data-start={start} >
      <span className="ruler marks label" data-start={start}>{start}</span>
      <div  onMouseOver={mouseCustomEvent}  className="ruler marks tick long-tick" data-start={start} />
      {length > 1 && <div  onMouseOver={mouseCustomEvent}  className="ruler marks tick short-tick" data-start={start+1}  />}
      {length > 2 && <div  onMouseOver={mouseCustomEvent}  className="ruler marks tick short-tick" data-start={start+2} />}
      {length > 3 && <div  onMouseOver={mouseCustomEvent}  className="ruler marks tick short-tick" data-start={start+3} />}
      {length > 4 && <div  onMouseOver={mouseCustomEvent}  className="ruler marks tick short-tick" data-start={start+4} />}
    </div>
  )
}

export interface PropsInterface2 {
  pointerYear2?: any;
  
}

const TimelineRulerScroller: React.FC<PropsInterface2 & TimelineRulerScrollerProps> = ({
  start,
  end,
  notes,
  targetRef,
  onViewNote,
  pointerYear2
}: PropsInterface2 & TimelineRulerScrollerProps) => {
  
  const [scrollPos, setScrollPos] = useState(0);
  const [pointPos, setPointPos] = useState<number>(0);


  const updatePos = useCallback(
    (event: any) => {
      const dist = event.target.scrollLeft
      setScrollPos(dist)

      var screenWidth = window.innerWidth;
      if(screenWidth<=767){
      var colorTable = document.getElementById("rulerCurrentTop");
      var tOLeft = colorTable?.offsetLeft;
      tOLeft = tOLeft?tOLeft:0;
      tOLeft = tOLeft+1;
      if(tOLeft > 0){
        setPointPos(tOLeft);    
      }
       var gTotal = tOLeft+dist;
       gTotal = Math.round(gTotal);
      var draggedOver = document.elementFromPoint(tOLeft, 130);
      var classLists = draggedOver?.classList;
      var firstChild = draggedOver?.firstChild;
      //console.log('classLists===',classLists);
      // if(classLists.indexOf(''))
      var is_valid=0;
      classLists?.forEach(item=>{
        if(item=='short-tick'|| item=='long-tick'){
          is_valid=1;
        }
      });
      var dataPt:any=0;
      if(is_valid===1){
        dataPt = draggedOver?.getAttribute('data-start');
      }else{
        var draggedOver = document.elementFromPoint(tOLeft-3, 130);
        var classLists = draggedOver?.classList;
        var firstChild = draggedOver?.firstChild;
        classLists?.forEach(item=>{
          if(item==='short-tick'|| item==='long-tick'){
            is_valid=1;
          }
        });
        if(is_valid===1){
          dataPt = draggedOver?.getAttribute('data-start');
        }
      }
      if(dataPt!==0){
          //console.log('screenWidth=',screenWidth);
        //console.log('dataPt=',dataPt);
        dataPt = parseInt(dataPt)+1;
        pointerYear2(dataPt);
      }
    }
      
      
      

      // console.log('draggedOver?.classList',draggedOver?.classList);
      // console.log('firstChild',firstChild);
      // console.log('gTotal',gTotal);
      // console.log('draggedOver',draggedOver);
       var colorTable2 = document.getElementsByClassName("short-tick");
       
      // var tOLeft = colorTable2?.offsetLeft;
      // tOLeft = tOLeft?tOLeft:0;
      // console.log('tOLeft',tOLeft);
    },
    [setScrollPos]
  )

  useEffect(() => {
    const current = targetRef.current || document.createElement('div')
    current.addEventListener('scroll', updatePos)
    return () => {
      current.removeEventListener('scroll', updatePos)
    }
  }, [targetRef, updatePos])



  // document.addEventListener('DOMContentLoaded', (event) => {
   
  // })

//     window.addEventListener('resize',function(e){
//       // console.log('eeee',e);
//       var colorTable = document.getElementById("rulerCurrentTop");
// var tOLeft = colorTable?.offsetLeft;
      
//       console.log('tOLeft',tOLeft);
//     });
  const printUrl = (arg: string) => {
    //console.log('arg',arg);
    // setSelectedYear(arg);
  }
  
  return (
    <div
      className="ruler scroller"
      style={{
        transform: `translateX( -${scrollPos}px )`,
      }}
    >
     

      <TimelineRuler
//targetRef={targetRef}
        start={start}
        end={end}
        notes={notes} 
        pointerYear={pointerYear2}
        pointerYearChild={scrollPos}
        onViewNote={onViewNote}
      />
    </div>
  )
}

export default TimelineRulerScroller
