import React, { useState, useEffect } from "react";
import { Formik, Form, FieldArray, FormikProps, Field } from "formik";
import {
	Button,
	Container,
	InputGroup,
	InputGroupAddon,
	Input,
	Row,
	Col,
} from "reactstrap";
import * as types from "../types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { MAX_ASSET_VALUE } from "shared/constants";
import "../styles.scss";
import EducationAccountItem from "./EducationAccountItem";

interface Props {
  initialValues?: FormValues
  onSubmit: (values: types.Education | null) => Promise<void>
  mutationError?: string
  loading?: boolean
}

export interface FormValues {
  schoolType?: types.SchoolType | "none"
  studyAge?: number
  studyYears?: number
  annualTuition?: number
  annualHousing?: number
  annualOther?: number
  accounts?: types.Account[]
}

interface AccountsListProps {
  items: types.Account[]
  formik: FormikProps<FormValues>
  remove: (index: number) => void
  push: (item: unknown) => void
}

const AccountsList: React.FC<AccountsListProps> = ({
	items,
	formik,
	remove,
	push,
}: AccountsListProps) => {
	const { t } = useTranslation();

	useEffect(() => {
		if (items.length === 0)
			push({
				name: "",
				value: 0,
				annualGrowth: 0,
				contribution: 0,
				contributionType: "",
			});
	});

	return (
		<>
			{items.map((item, index) => (
				<div key={index}>
					<EducationAccountItem
						index={index}
						isSubmitting={formik.isSubmitting}
						handleChange={formik.handleChange}
						remove={remove}
						canRemove={items.length > 1}
					/>
				</div>
			))}
			<Button
				title="[Add]"
				color="success"
				disabled={formik.isSubmitting}
				className="mb-3"
				onClick={() =>
				// Must set default values so the remove function works correctly
					push({
						name: "",
						value: 0,
						annualGrowth: 0,
						contribution: 0,
						contributionType: "",
					})
				}
			>
        + {t("features.calculator.education.addItem")}
			</Button>
		</>
	);
};

const EducationCalculatorForm: React.FC<Props> = (props: Props) => {
	const { t } = useTranslation();
	const [schoolTypeNone, setSchoolTypeNone] = useState<boolean>(
		!props.initialValues?.schoolType
	);

	const YearsSchema = yup
		.number()
		.required(
			t("features.calculator.lifeExpectancy.alcoholNumeric.errors.empty")
		)
		.integer(
			t("features.calculator.lifeExpectancy.alcoholNumeric.errors.nonInteger")
		)
		.positive(
			t("features.calculator.lifeExpectancy.alcoholNumeric.errors.negative")
		);

	const DollarSchema = yup
		.number()
		.required(t("features.calculator.netWorth.errors.required"))
		.min(
			0,
			t("features.calculator.netWorth.errors.outOfRange", {
				max: MAX_ASSET_VALUE.toLocaleString(),
			})
		)
		.max(
			MAX_ASSET_VALUE,
			t("features.calculator.netWorth.errors.outOfRange", {
				max: MAX_ASSET_VALUE.toLocaleString(),
			})
		);

	const StringSchema = yup
		.string()
		.min(1)
		.required(t("features.calculator.netWorth.errors.required"));

	const PercentageSchema = yup
		.number()
		.required(t("features.calculator.netWorth.errors.required"))
		.positive(
			t("features.calculator.netWorth.errors.outOfRange", {
				max: 100,
			})
		)
		.max(
			100,
			t("features.calculator.netWorth.errors.outOfRange", {
				max: 100,
			})
		);

	const validationSchema = yup.object().shape({
		schoolType: StringSchema,
		studyAge: !schoolTypeNone ? YearsSchema : yup.number(),
		studyYears: !schoolTypeNone ? YearsSchema : yup.number(),
		annualTuition: !schoolTypeNone ? DollarSchema : yup.number(),
		annualHousing: !schoolTypeNone ? DollarSchema : yup.number(),
		annualOther: !schoolTypeNone ? DollarSchema : yup.number(),
		accounts: yup.array().of(
			yup.object().shape({
				name: StringSchema,
				value: DollarSchema,
				annualGrowth: PercentageSchema,
				contribution: DollarSchema,
				contributionType: StringSchema,
			})
		),
	});

  
	return (
		<Container className="content">
			<Formik
				initialValues={{
					...props.initialValues,
					schoolType: props.initialValues?.schoolType || "none",
				}}
				onSubmit={(values: FormValues) => {
					if (values.schoolType === "none") {
						props.onSubmit(null);
					} else {
						// all these fields are defined
						props.onSubmit({
							schoolType: values.schoolType || types.SchoolType.University,
							studyAge: values.studyAge || 0,
							studyYears: values.studyYears || 0,
							annualTuition: values.annualTuition || 0,
							annualHousing: values.annualHousing || 0,
							annualOther: values.annualOther || 0,
							accounts: (values.accounts || []).map(account => ({
								annualGrowth: account.annualGrowth,
								contribution: account.contribution,
								contributionType: account.contributionType,
								name: account.name,
								value: account.value,
							})),
						});
					}
				}}
				validationSchema={validationSchema}
				validateOnBlur={false}
				validateOnChange={false}
				validateOnMount={false}
			>
				{(formik: FormikProps<FormValues>) => {
					return (
						<>
							<Form>
								<h2>{t("features.calculator.education.detailsHeader")}</h2>

								<Row form className="mb-3">
									<Col sm={3} className="mb-1 networth-form-column">
										<label htmlFor={"schoolType"} className="mt-1">
											{t("features.calculator.education.labels.schoolType")}
										</label>
									</Col>
									<Col sm={9} className="mb-1 networth-form-column">
										<Field
											as={Input}
											type="select"
											name="schoolType"
											onChange={(e: any) => {
												formik.handleChange(e);
												setSchoolTypeNone(e.target.value === "none");
											}}
											disabled={formik.isSubmitting}
										>
											<option value={"none"}>
												{t("features.calculator.education.schoolType.DEFAULT")}
											</option>
											{Object.values(types.SchoolType).map(type => (
												<option key={type} value={type}>
													{t(
														`features.calculator.education.schoolType.${type}`
													)}
												</option>
											))}
										</Field>
										{formik.errors.schoolType && (
											<div className="mt-1 form-error">
												{formik.errors.schoolType}
											</div>
										)}
									</Col>
								</Row>

								{formik.values.schoolType !== "none" && (
									<>
										<Row form className="mb-3">
											<Col sm={3} className="mb-1 networth-form-column">
												<label htmlFor={"studyAge"} className="mt-1">
													{t("features.calculator.education.labels.studyAge")}
												</label>
											</Col>
											<Col sm={9} className="mb-1 networth-form-column">
												<InputGroup>
													<Field
														as={Input}
														name={"studyAge"}
														type="number"
														onChange={formik.handleChange}
														disabled={formik.isSubmitting}
														placeholder={t(
															"features.calculator.education.default.studyAge"
														)}
													/>
													<InputGroupAddon addonType="append">
														{t("features.calculator.education.labels.yearsOld")}
													</InputGroupAddon>
												</InputGroup>
												{formik.errors.studyAge && (
													<div className="mt-1 form-error">
														{formik.errors.studyAge}
													</div>
												)}
											</Col>
										</Row>

										<Row form className="mb-3">
											<Col sm={3} className="mb-1 networth-form-column">
												<label htmlFor={"studyYears"} className="mt-1">
													{t("features.calculator.education.labels.studyYears")}
												</label>
											</Col>
											<Col sm={9} className="mb-1 networth-form-column">
												<InputGroup>
													<Field
														as={Input}
														name={"studyYears"}
														type="number"
														onChange={formik.handleChange}
														disabled={formik.isSubmitting}
														placeholder={t(
															"features.calculator.education.default.studyYears"
														)}
													/>
													<InputGroupAddon addonType="append">
														{t("features.calculator.education.labels.years")}
													</InputGroupAddon>
												</InputGroup>

												{formik.errors.studyYears && (
													<div className="mt-1 form-error">
														{formik.errors.studyYears}
													</div>
												)}
											</Col>
										</Row>

										<Row form className="mb-3">
											<Col sm={3} className="mb-1 networth-form-column">
												<label htmlFor={"annualTuition"} className="mt-1">
													{t(
														"features.calculator.education.labels.annualTuition"
													)}
												</label>
											</Col>
											<Col sm={9} className="mb-1 networth-form-column">
												<InputGroup>
													<InputGroupAddon addonType="prepend">
                            $
													</InputGroupAddon>
													<Field
														as={Input}
														type="number"
														name={"annualTuition"}
														onChange={formik.handleChange}
														disabled={formik.isSubmitting}
														placeholder={t(
															"features.calculator.education.default.annualTuition"
														)}
													/>
												</InputGroup>
												{formik.errors.annualTuition && (
													<div className="mt-1 form-error">
														{formik.errors.annualTuition}
													</div>
												)}
											</Col>
										</Row>

										<Row form className="mb-3">
											<Col sm={3} className="mb-1 networth-form-column">
												<label htmlFor={"annualHousing"} className="mt-1">
													{t(
														"features.calculator.education.labels.annualHousing"
													)}
												</label>
											</Col>
											<Col sm={9} className="mb-1 networth-form-column">
												<InputGroup>
													<InputGroupAddon addonType="prepend">
                            $
													</InputGroupAddon>
													<Field
														as={Input}
														type="number"
														name={"annualHousing"}
														onChange={formik.handleChange}
														disabled={formik.isSubmitting}
														placeholder={t(
															"features.calculator.education.default.annualHousing"
														)}
													/>
												</InputGroup>
												{formik.errors.annualHousing && (
													<div className="mt-1 form-error">
														{formik.errors.annualHousing}
													</div>
												)}
											</Col>
										</Row>

										<Row form className="mb-3">
											<Col sm={3} className="mb-1 networth-form-column">
												<label htmlFor={"annualOthere"} className="mt-1">
													{t(
														"features.calculator.education.labels.annualOther"
													)}
												</label>
											</Col>
											<Col sm={9} className="mb-1 networth-form-column">
												<InputGroup>
													<InputGroupAddon addonType="prepend">
                            $
													</InputGroupAddon>
													<Field
														as={Input}
														type="number"
														name={"annualOther"}
														onChange={formik.handleChange}
														disabled={formik.isSubmitting}
														placeholder={t(
															"features.calculator.education.default.annualOther"
														)}
													/>
												</InputGroup>
												{formik.errors.annualOther && (
													<div className="mt-1 form-error">
														{formik.errors.annualOther}
													</div>
												)}
											</Col>
										</Row>

										<FieldArray name="accounts">
											{({ push, remove }) => (
												<div className="items-list">
													<h2>
														{t("features.calculator.education.accountsHeader")}
													</h2>
													<AccountsList
														items={formik.values.accounts || []}
														formik={formik}
														push={push}
														remove={remove}
													/>
												</div>
											)}
										</FieldArray>
									</>
								)}
								<Button
									type="submit"
									color="primary"
									className="my-3"
									disabled={formik.isSubmitting}
									block
								>
									{t("features.calculator.education.submit")}
								</Button>
								{props.mutationError && (
									<div className="form-error mt-0">{props.mutationError}</div>
								)}
							</Form>
						</>
					);
				}}
			</Formik>
		</Container>
	);
};

export default EducationCalculatorForm;
