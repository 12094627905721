import React from 'react'
import { Container } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const InviteSent: React.FC = () => {
  const { t } = useTranslation()
  return (
    // This is temporary UI, remove this when we have official UI specs
    <Container className="content">
      <h1 className="mt-3 text-center">
        {t('features.getstarted.login.success.title')}
      </h1>
      <p className="text-center">
        {t('features.getstarted.login.success.body')}
      </p>
    </Container>
  )
}

export default InviteSent
