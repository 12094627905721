import React, { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
	useParams,
	useHistory,
	useRouteMatch,
	Switch,
	Route,
} from "react-router-dom";
import RetirementCalculatorForm, {
	FormValues,
} from "./views/RetirementCalculatorForm";
import Header from "shared/views/Header";
import { useTranslation } from "react-i18next";
import {
	RETIREMENT_QUERY,
	RetirementQuery,
	RETIREMENT_MUTATION,
} from "./queries";
import LoadingSpinner from "shared/views/spinner/LoadingSpinner";
import RetirementConfirm from "./views/RetirementConfirm";
import { NET_WORTH_REFETCH } from "../networth/queries";
import { RETIREMENT_GRAPH_REFETCH } from "features/graph/finances/queries";

const RetirementCalculator: React.FC = () => {
	const { membershipId } = useParams();
	const { t } = useTranslation();
	const history = useHistory();
	const match = useRouteMatch();
	const { data, loading } = useQuery<RetirementQuery>(RETIREMENT_QUERY);
	const [mutate] = useMutation<FormValues>(RETIREMENT_MUTATION);

	useEffect(() => {
		window.gtag("event", "retirement_calculator_page_view", {
			page: history.location.pathname,
		});
	}, [history.location]);

	if (!data || loading) {
		return <LoadingSpinner />;
	}

	const retirementData =
    data.retirement !== null
    	? data.retirement
    	: {
    		projectedSavings: 0,
    		savingsGoal: 0,
    		surplus: 0,
    		currentIncome: undefined,
    		incomeType: undefined,
    		predictedExpenses: undefined,
    		expensesType: undefined,
    		postRetirementGrowthRate: undefined,
    		accounts: [],
    		incomes: [],
    	};

	const {
		projectedSavings,
		savingsGoal,
		surplus,
		...initialValues
	} = retirementData;

	return (
		<Switch>
			<Route exact path={`${match.url}`}>
				<Header text={t("features.calculator.retirement.formHeader")} />
				<RetirementCalculatorForm
					initialValues={initialValues}
					isRetired={data.me.isRetired}
					onSubmit={async (values: FormValues) => {
						// some voodoo math to remove __typename from the data
						// since it'll break the mutation
						const cleaned: FormValues = {
							currentIncome: values.currentIncome,
							incomeType: values.incomeType,
							predictedExpenses: values.predictedExpenses,
							expensesType: values.expensesType,
							postRetirementGrowthRate: values.postRetirementGrowthRate,
							accounts: values.accounts.map(account => ({
								annualGrowth: account.annualGrowth,
								contribution: account.contribution,
								contributionType: account.contributionType,
								name: account.name,
								value: account.value,
							})),
							incomes: values.incomes.map(income => ({
								contribution: income.contribution,
								contributionType: income.contributionType,
								name: income.name,
							})),
						};
						await mutate({
							variables: cleaned,
							refetchQueries: [
								{
									query: RETIREMENT_QUERY,
								},
								{
									query: NET_WORTH_REFETCH,
								},
								{
									query: RETIREMENT_GRAPH_REFETCH,
								},
							],
							awaitRefetchQueries: true,
						});
						history.push(`${match.url}/results`);
					}}
				/>
			</Route>
			<Route exact path={`${match.url}/results`}>
				<Header text={t("features.calculator.retirement.resultsHeader")} />
				<RetirementConfirm
					backUrl={`/dashboard/${membershipId}`}
					projectedSavings={projectedSavings}
					savingsGoal={savingsGoal}
					surplus={surplus}
				/>
			</Route>
		</Switch>
	);
};

export default RetirementCalculator;
