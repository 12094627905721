import React, { MutableRefObject } from "react";
import Graph, { DataPoint, DataSeries, GraphMarkerShape } from "../Graph";
import { useQuery } from "@apollo/react-hooks";
import LoadingSpinner from "shared/views/spinner/LoadingSpinner";

import "./FinancesGraph.scss";
import { FINANCE_GRAPH_QUERY } from "./queries";
import { useTranslation } from "react-i18next";
import { currencyFormatter } from "shared/utils";

interface FinancesGraphProps {
  show: boolean
  startYear: number
  endYear: number
  targetRef: MutableRefObject<HTMLDivElement>
}

interface FinanceGraphQuery {
  retirement?: {
    graphPoints: DataPoint[]
  }
  educationGraphs: [
    {
      firstName: string
      lastName: string
      points: DataPoint[]
    }
  ]
  realEstate: {
    graphPoints: DataPoint[]
  }
}

const FinancesGraph: React.FC<FinancesGraphProps> = (
	props: FinancesGraphProps
) => {
	const { data, loading } = useQuery<FinanceGraphQuery>(FINANCE_GRAPH_QUERY);

	const { t } = useTranslation();

	if (!data || loading) {
		return <LoadingSpinner overlay />;
	}

	const graphData: DataSeries[] = [];

	if (data.retirement?.graphPoints) {
		graphData.push({
			markerShape: GraphMarkerShape.Diamond,
			data: data.retirement.graphPoints.map(pt => ({
				...pt,
				value: currencyFormatter.format(pt.y),
			})),
			name: t("features.graph.finances.retirement"),
			showPoppers: true,
		});
	}

	data.educationGraphs.forEach(graph => {
		graphData.push({
			markerShape: GraphMarkerShape.Diamond,
			data: graph.points.map(pt => ({
				...pt,
				value: currencyFormatter.format(pt.y),
			})),
			name: t("features.graph.finances.childEducationSavings", {
				firstName: graph.firstName,
			}),
			showPoppers: true,
		});
	});
	if (data.realEstate.graphPoints) {
		graphData.push({
			markerShape: GraphMarkerShape.Diamond,
			data: data.realEstate.graphPoints.map(pt => ({
				...pt,
				value: currencyFormatter.format(pt.y),
			})),
			name: t("features.graph.finances.realEstate"),
			showPoppers: true,
		});
	}

	return (
		<>
			<div className="finances-graph">
				{graphData.length === 0 && (
					<h5 className="finances-no-data text-center">
						{t("features.graph.finances.noData")}
					</h5>
				)}
				<Graph
					show
					data={graphData}
					startYear={props.startYear}
					endYear={props.endYear}
					targetRef={props.targetRef}
				/>
			</div>
		</>
	);
};

export default FinancesGraph;
