import React, { useState } from 'react'

import '../styles.scss'
import * as types from 'shared/types'
import {
  ModalHeader,
  Modal,
  ModalBody,
  Button,
  Input,
  Form,
  Label,
  ModalFooter,
  Alert,
} from 'reactstrap'

import { useTranslation } from 'react-i18next'
import { Formik, FormikProps } from 'formik'
import * as yup from 'yup'

interface NoteShareModalBodyProps {
  toggle: () => void
  note: types.Note
  unshare: (personId: number) => Promise<void>
  share: (personId: number) => Promise<void>
  family: types.Family
}

export interface FormValues {
  id: number[]
}

export const NoteShareModalBody: React.FC<NoteShareModalBodyProps> = ({
  toggle,
  note,
  family,
  unshare,
  share,
}: NoteShareModalBodyProps) => {
  const { t } = useTranslation()
  const [showShareSuccess, setShowShareSuccess] = useState(false)
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false)

  const handleShareSubmit = async (vals: FormValues) => {
    await vals.id.forEach(id => share(id))
    if (vals.id.length) setShowShareSuccess(true)
  }

  const handleDeleteSubmit = async (vals: FormValues) => {
    await vals.id.forEach(id => unshare(id))
    if (vals.id.length) setShowDeleteSuccess(true)
  }

  const IDSchema = yup.object().shape({
    id: yup.array().of(
      yup
        .number()
        .integer()
        .positive()
    ),
  })

  return (
    <>
      <ModalHeader toggle={toggle} />
      <h3 className="text-center">
        {t('features.notes.noteShareModal.header')}
      </h3>
      <ModalBody>
        <div className="share-form">
          <Formik
            initialValues={{ id: [] }}
            onSubmit={handleShareSubmit}
            validationSchema={IDSchema}
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
          >
            {(formik: FormikProps<FormValues>) => (
              <Form
                onSubmit={async (event: React.FormEvent): Promise<void> => {
                  event.preventDefault()
                  await formik.submitForm()
                }}
              >
                <Label>{t('features.notes.noteShareModal.share.title')}</Label>
                <Input
                  name="id"
                  type="select"
                  onChange={(event: any) =>
                    formik.setFieldValue(
                      'id',
                      [...event.target.options]
                        .filter(option => option.selected)
                        .map(option => option.value)
                    )
                  }
                  multiple
                >
                  {family.profiles
                    .filter(
                      profile =>
                        profile.membership.role !== types.Role.Partner &&
                        profile.membership.role !== types.Role.Primary &&
                        profile.membership.person.hasUser &&
                        note.sharedWith?.every(
                          person => person.id !== profile.membership.person.id
                        )
                    )
                    .map(profile => {
                      const name = `${profile.membership.person.firstName} ${profile.membership.person.lastName}`
                      const id = profile.membership.person.id
                      return (
                        <option value={id} key={id}>
                          {name}
                        </option>
                      )
                    })}
                </Input>
                {showShareSuccess && (
                  <Alert color="success">
                    {t('features.notes.noteShareModal.share.success')}
                  </Alert>
                )}
                <Button type="submit" color="primary" outline block>
                  {t('features.notes.noteShareModal.share.submit')}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <hr />
        <div className="manage-form">
          <Formik
            initialValues={{ id: [] }}
            onSubmit={handleDeleteSubmit}
            validationSchema={IDSchema}
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
          >
            {(formik: FormikProps<FormValues>) => (
              <Form
                onSubmit={async (event: React.FormEvent): Promise<void> => {
                  event.preventDefault()
                  await formik.submitForm()
                }}
              >
                <Label>{t('features.notes.noteShareModal.manage.title')}</Label>
                <Input
                  name="id"
                  type="select"
                  onChange={(event: any) =>
                    formik.setFieldValue(
                      'id',
                      [...event.target.options]
                        .filter(option => option.selected)
                        .map(option => option.value)
                    )
                  }
                  multiple
                >
                  {note.sharedWith?.map(person => {
                    const name = `${person.firstName} ${person.lastName}`
                    const id = person.id
                    return (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    )
                  })}
                </Input>
                {showDeleteSuccess && (
                  <Alert color="success">
                    {t('features.notes.noteShareModal.manage.success')}
                  </Alert>
                )}
                <Button type="submit" color="primary" outline block>
                  {t('features.notes.noteShareModal.manage.submit')}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-around w-100">
          <Button
            onClick={toggle}
            color="link"
            className="note-edit-modal-done"
          >
            <h2>{t('features.notes.noteViewModal.close')}</h2>
          </Button>
        </div>
      </ModalFooter>
    </>
  )
}

interface NoteShareModalProps extends NoteShareModalBodyProps {
  isOpen: boolean
}

const NoteShareModal: React.FC<NoteShareModalProps> = (
  props: NoteShareModalProps
) => {
  return (
    <Modal
      contentClassName="note-edit-modal"
      isOpen={props.isOpen}
      toggle={props.toggle}
      centered
    >
      <NoteShareModalBody {...props} />
    </Modal>
  )
}

export default NoteShareModal
