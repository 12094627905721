import React from "react";

import { Note, MILESTONE_ICONS, Family, Role } from "shared/types";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import countries from "../../getstarted/countries";
import ProfilePicture from "shared/views/person/ProfilePicture";
import { NavLeftIcon, NavRightIcon, ShareIcon } from "shared/views/Icon";

interface NoteViewModalBodyProps {
  note: Note
  family: Family
  toggle: () => void
  editNote: (note: Note) => void
  shareNote: (note: Note) => void
  hideNote: () => void
  shiftNote: (direction: "forward" | "back") => void
}

export const NoteViewModalBody: React.FC<NoteViewModalBodyProps> = (
	props: NoteViewModalBodyProps
) => {
	const { t } = useTranslation();

	const Icon = MILESTONE_ICONS[props.note.type];
	let iconName = props.note.type
	let persons =
    props.note.group && props.note.group.id === props.family.id
    	? props.family.profiles
    		.filter(profile => profile.membership.role !== Role.Member)
    		.map(profile => profile.membership.person)
    	: props.note.membership
    		? [props.note.membership?.person]
    		: props.note.owner
    			? [props.note.owner]
    			: [];

	// We want the picture stacking so that left is at the top.
	// To avoid fiddling with z-indexes, we will reverse this instead
	// and display the flex with row-reverse.
	persons = persons.reverse();

	const fullTextCountry = countries.filter(
		country => country.code === props.note.membership?.person.country
	);

	const description = props.note.description
		? props.note.description
		: t(`features.notes.noteViewModal.description.${props.note.calculator}`, {
			sex: props.note.membership?.person.sex.toLowerCase(),
			country: fullTextCountry.length ? fullTextCountry[0].name : "",
			age: props.note.year - (props.note.membership?.person.birthYear || 0),
			year: props.note.membership?.person.birthYear || 0,
		});

	const notes = props.note.membership
		? props.family.profiles.find(
			profile => profile.membership.id === props.note?.membership?.id
		)?.timeline.notes
		: props.family.notes;

	const idx = (notes?.findIndex(note => note.id === props.note.id) || 0) + 1;
	const numNotes = notes?.length;

	return (
		<>
			<NavLeftIcon
				className={idx === 1 ? "disabled" : "clickable"}
				onClick={() => {
					props.shiftNote("back");
				}}
			/>
			<div className="note-view-modal content">
				<div className="note-view-modal header">
					{iconName === "MILESTONE"?null:<Icon className="icon" />}
					
					<h3>{`${t(
						`shared.noteTypes.${props.note.type}`
					)} (${idx}/${numNotes})`}</h3>
					{props.note.canEdit && props.note.group && (
						<ShareIcon
							className="clickable share"
							onClick={() => props.shareNote(props.note)}
						/>
					)}
				</div>
				<ModalBody>
					<div className="note-view-modal pictures">
						{persons.map((person, index) => (
							<ProfilePicture person={person} key={index} large={true} />
						))}
					</div>
					<div className="note-view-modal text">
						<i>
							{t("features.notes.noteViewModal.year", {
								year: props.note.year,
							})}
						</i>
						<p className="mb-0">{description}</p>
						{props.note.group && props.note.group.id !== props.family.id && (
							<small>
								<i>
									{t("features.notes.noteViewModal.shared", {
										firstName: props.note.owner?.firstName,
										lastName: props.note.owner?.lastName,
									})}
								</i>
							</small>
						)}
					</div>
				</ModalBody>
				<ModalFooter>
					<div className="d-flex justify-content-around w-100">
						{props.note.group && props.note.group.id !== props.family.id && (
							<Button
								onClick={props.hideNote}
								color="link"
								className="note-edit-modal-done"
							>
								<h2 className="font-weight-light">
									{t("features.notes.noteViewModal.hide")}
								</h2>
							</Button>
						)}
						{props.note.canEdit && (
							<Button
								onClick={() => {
									props.editNote(props.note);
								}}
								color="link"
								className="note-edit-modal-done"
							>
								<h2 className="font-weight-light">
									{t("features.notes.noteViewModal.edit")}
								</h2>
							</Button>
						)}
						<Button
							onClick={props.toggle}
							color="link"
							className="note-edit-modal-done"
						>
							<h2>{t("features.notes.noteViewModal.close")}</h2>
						</Button>
					</div>
				</ModalFooter>
			</div>
			<NavRightIcon
				className={idx === numNotes ? "disabled" : "clickable"}
				onClick={() => {
					props.shiftNote("forward");
				}}
			/>
		</>
	);
};

interface NoteViewModalProps extends NoteViewModalBodyProps {
  isOpen: boolean
}

const NoteViewModal: React.FC<NoteViewModalProps> = (
	props: NoteViewModalProps
) => {
	return (
		<Modal
			isOpen={props.isOpen}
			toggle={props.toggle}
			centered
			contentClassName="note-view-modal box"
		>
			<NoteViewModalBody {...props} />
		</Modal>
	);
};

export default NoteViewModal;
