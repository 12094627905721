import React, {useEffect,useState} from 'react'
import { useParams, useHistory } from 'react-router-dom';
import * as types from '../../shared/types';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import DashboardHome from './views/Home';
import { GET_TIMELINE } from 'features/timeline';
import LoadingSpinner from 'shared/views/spinner/LoadingSpinner';
import { personAndMembershipFragment } from 'shared/queries';
import { analyticsDeleteMembership } from 'shared/views/analytics';
import { setAgeAndGender, removeLocalStorage, getHealthAge } from '../calculators/lifeExpectancy/views/LifeExpectancy';
interface Property {
  id: number;
  value: number;
  mortgage: {
    id: number;
    netEquity: number;
  };
}

interface DashboardQuery {
  family: types.Family;
  netWorth: {
    netWorth: number;
  } | null;
  retirement: {
    id: number;
    accounts: { value: number }[];
  } | null;
  education: {
    id: number;
    projectedSavings: number;
  } | null;
  realEstate: {
    properties: Property[];
  } | null;
}

export const DASHBOARD_QUERY = gql`
  query($id: ID!) {
    family {
      id
      profiles {
        membership {
          ...membershipFields
        }
        timeline {
          id
          startYear
          notes {
            id
            calculator
            year
          }
        }
      }
    }
    netWorth {
      netWorth
    }
    retirement {
      id
      accounts {
        value
      }
    }
    education(id: $id) {
      id
      projectedSavings
    }
    realEstate {
      properties {
        id
        value
        mortgage {
          id
          netEquity
        }
      }
    }
  }

  ${personAndMembershipFragment}
`;

export const DELETE_MEMBERSHIP = gql`
  mutation($id: ID!) {
    deleteMembership(id: $id)
  }
`;

export interface Calculators {
  netWorth: number | undefined;
  retirement: number | undefined;
  education: number | undefined;
  realEstate: number | undefined;
}

const Dashboard: React.FC = () => {
  const { id } = useParams();
  localStorage.setItem("user_id", id)
  const history = useHistory();
 
    // useEffect(() => {
    //   if(localStorage.getItem("refresh") ===  "yes"){
    //     let refresh:any="no"
    //     localStorage.setItem("refresh",refresh)
    //     window.location.reload();
    //   }
    // }, []);
 
  

  const { loading, error, data } = useQuery<DashboardQuery>(DASHBOARD_QUERY, {
    variables: { id: Number(id) },
  });
  const [mutate] = useMutation(DELETE_MEMBERSHIP);

  if (loading) return <LoadingSpinner />;

  if (error) {
    return <div />;
  }

  if (data) {
    //window.location.reload();
 
    const profile = data.family.profiles.find(
      (profile) => profile.membership.id.toString() === id
    );
    const membership = profile?.membership;
    //console.log("=======profile is=======",membership?.person.id)
    let person_id:any=0
    person_id = membership?.person.id
   
    if(localStorage.getItem("survialAllAges") !== null){
      fetch(process.env.REACT_APP_AUTH0_AUDIENCE+'update-life-expectancy-data-and-get-data?id='+localStorage.getItem("person_id")+'&lifeexpectancy='+localStorage.getItem("expectancy")+'&healthage='+localStorage.getItem("healthAge")+'&survialallages='+localStorage.getItem('survialAllAges')+'&update=yes')
      .then(response => response.json())
      .then((response) => {
      
      console.log(response)})
      .catch((error) => {console.log(error)}) 
    }

    const LifeExpectancyYear = profile?.timeline.notes.find(
      (note) => note.calculator === types.CalculatorType.LifeExpectancy
    )?.year;


    const HealthLifeExpectancy = profile?.timeline.notes.find(
      (note) => note.calculator === types.CalculatorType.HealthyLifeExpectancy
    )?.year;
    console.log("========healthlife expecntachy======",)

    //  console.log("=======life expectacy=====",localStorage.getItem("expectancy"))
    //  console.log(typeof localStorage.getItem("expectancy"))
    //  let LifeExpectancy = 0
    //  if(localStorage.getItem("expectancy") !== null){
    //   LifeExpectancy = Number(localStorage.getItem("expectancy"))
    //  }


    let LifeExpectancy = LifeExpectancyYear ? LifeExpectancyYear - (profile?.timeline.startYear || 0) : 80;
    if (localStorage.getItem("expectancy") !== null) {
      LifeExpectancy = Number(localStorage.getItem("expectancy"))
      localStorage.setItem("remoteTextWho","yes")
    }
    localStorage.setItem("newLifeExpectancy", String(LifeExpectancy));


    let healthAge: any = 0
    let whoLifeExpectancy:any = 0
    whoLifeExpectancy = LifeExpectancyYear ? LifeExpectancyYear - (profile?.timeline.startYear || 0) : 80;
    localStorage.setItem("whoLIfeExpectancy",whoLifeExpectancy)
      if (localStorage.getItem("healthAge") !== null) {
        healthAge = localStorage.getItem("healthAge")
    }
   

    console.log(
      '=====birthyear=====',
      data.family.profiles[0].membership.person.birthYear
    );
    console.log('=====sex=====', data.family.profiles[0].membership.person.sex);
    setAgeAndGender(
      data.family.profiles[0].membership.person.birthYear,
      data.family.profiles[0].membership.person.sex
    );
    
    removeLocalStorage()
    const realEstate = data.realEstate;

    if (membership)
      return (
        <DashboardHome
          membership={membership}
          lifeExpectancy={LifeExpectancy}
          healthAge={healthAge}
          deleteUser={(membership: types.Membership): void => {
            analyticsDeleteMembership(membership.role);
            mutate({
              variables: {
                id: membership.id,
              },
              refetchQueries: [
                {
                  query: GET_TIMELINE,
                },
              ],
            }).then(() => {
              history.push('/timeline');
            });
          }}
          calculators={{
            netWorth: data.netWorth?.netWorth,
            retirement: data.retirement?.accounts.reduce(
              (acc: number, curr: { value: number }) => acc + curr.value,
              0
            ),
            education: data.education?.projectedSavings,
            realEstate:
              realEstate !== null
                ? realEstate.properties.reduce(
                  (acc: number, cur: Property) =>
                    acc + cur.mortgage.netEquity,
                  0
                )
                : undefined,
          }}
        />
      );
  }
  return <div />;
};

export default Dashboard;
