import React, { useState } from "react";
import * as types from "../../../shared/types";
import { useTranslation } from "react-i18next";
import Header from "../../../shared/views/Header";
import ProfilePicture from "../../../shared/views/person/ProfilePicture";
import { Button } from "reactstrap";
import {
	ChartIcon,
	BarChartIcon,
	EditIcon,
	SchoolIcon,
	HousingIcon,
	SphinxIcon,
	BiologicalIcon
} from "shared/views/Icon";
import ConfirmationModal from "shared/views/ConfirmModal";
import { useHistory } from "react-router-dom";
import { Calculators } from "..";
import { currencyFormatter } from "../../../shared/utils";
import { LifeExpectancy } from "@ottawamhealth/pbl-calculator-engine/lib/engine/life-expectancy/life-expectancy";

interface DashboardHomeProps {
	membership: types.Membership
	calculators: Calculators
	lifeExpectancy: number
	healthAge?: number
	deleteUser: (membership: types.Membership) => void
}

export const DashboardHome: React.FC<DashboardHomeProps> = ({
	membership,
	deleteUser,
	lifeExpectancy,
	calculators,
	healthAge
}: DashboardHomeProps) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

	const LifeExpectancyIcon = () => (
		<div className="life-icon">{lifeExpectancy}</div>
	);
	const HealthAgeIcon = () => (
		<div className="life-icon">{healthAge}</div>
	);

	let halthAge: any = 0
	let healthAgeString = "Obtain from Quiz"
	if (localStorage.getItem("healthAge") !== null) {
		halthAge = localStorage.getItem("healthAge")
		healthAgeString = ""
	}
	let newLifeExpectancy: any = 0
	let newLifeExpectancyText: any = "Using WHO data, do quiz to increase accuracy."
	if (localStorage.getItem("newLifeExpectancy") !== null) {
		newLifeExpectancy = localStorage.getItem("newLifeExpectancy")
		if(localStorage.getItem("remoteTextWho") !== null){
			newLifeExpectancyText = ""
		}
	
	}

	return (
		<>
			{showConfirmModal && (
				<ConfirmationModal
					isOpen={showConfirmModal}
					title={t("features.dashboard.home.confirmModal.title")}
					description={t("features.dashboard.home.confirmModal.description")}
					closeModal={() => setShowConfirmModal(false)}
					confirm={() => {
						setShowConfirmModal(false);
						deleteUser(membership);
					}}
					confirmText={t("features.dashboard.home.confirmModal.confirm")}
					cancelText={t("features.dashboard.home.confirmModal.cancel")}
				/>
			)}
			<Header text={t("features.dashboard.nav.home")} />
			<div className="dashboard-home">
				<div className="head d-flex mb-md-5">
					<div className="picture d-inline-block">
						<ProfilePicture person={membership.person} large={true} />
					</div>
					<div className="text d-inline-block">
						<div className="titletext">
							{t("features.dashboard.home.headTitle", {
								firstName: membership.person.firstName,
							})}
						</div>
					</div>
					{membership.canEdit && (
						<div className="action d-inline-block">
							<Button
								outline
								color="primary"
								onClick={() => history.push(`/membership/${membership.id}`)}
							>
								<EditIcon />
							</Button>
						</div>
					)}
				</div>
				{membership.showCalculators && (
					<>
						<HomeCalculator
							Icon={BarChartIcon}
							title={t("features.dashboard.home.calculators.netWorth.title")}
							actionText={
								calculators?.netWorth
									? currencyFormatter.format(calculators.netWorth)
									: t("features.dashboard.home.calculators.netWorth.actionText")
							}
							onButtonDown={() => {
								history.push(`/calculator/${membership.id}/networth`);
							}}
						/>
						<HomeCalculator
							Icon={ChartIcon}
							title={t(
								"features.dashboard.home.calculators.retirementSavings.title"
							)}
							actionText={
								calculators?.retirement
									? currencyFormatter.format(calculators.retirement)
									: t(
										"features.dashboard.home.calculators.retirementSavings.actionText"
									)
							}
							onButtonDown={() => {
								history.push(`/calculator/${membership.id}/retirement`);
							}}
						/>
						<HomeCalculator
							Icon={HousingIcon}
							title={t("features.dashboard.home.calculators.realestate.title")}
							actionText={
								calculators?.realEstate
									? currencyFormatter.format(calculators.realEstate)
									: t(
										"features.dashboard.home.calculators.realestate.actionText"
									)
							}
							onButtonDown={() => {
								history.push(`/calculator/${membership.id}/real-estate`);
							}}
						/>
						{/* <HomeCalculator
							Icon={LifeExpectancyIcon}
							title={t(
								"features.dashboard.home.calculators.lifeExpectancy.title"
							)}
							description={t(
								"features.dashboard.home.calculators.lifeExpectancy.description"
							)}
							actionText={t(
								"features.dashboard.home.calculators.lifeExpectancy.actionText"
							)}
							onButtonDown={() => {
								history.push(`/calculator/${membership.id}/life-expectancy`);
							}}
						/> */}
						<HomeCalculator
							Icon={SphinxIcon}
							title={t(
								"features.dashboard.home.calculators.lifeExpectancy.title"
							)}
							description={newLifeExpectancyText}
							onButtonDown={() => {
								history.push(`/calculator/${membership.id}/life-expectancy`);
							}}
							actionText={newLifeExpectancy.toString() + ' yrs'}
							lifeeExpectancy={true}
						/>
						<HomeCalculator
							Icon={BiologicalIcon}
							title={"Biological Age"}
							description={healthAgeString}
							onButtonDown={() => {
								history.push(`/calculator/${membership.id}/life-expectancy`);
							}}
							actionText={halthAge.toString() + ' yrs'}
							lifeeExpectancy={true}
						/>
					</>
				)}
				{membership.showEducationCalculator && (
					<HomeCalculator
						Icon={() => <SchoolIcon />}
						title={t("features.dashboard.home.calculators.education.title")}
						actionText={
							calculators?.education
								? currencyFormatter.format(calculators.education)
								: t("features.dashboard.home.calculators.education.actionText")
						}
						onButtonDown={() =>
							history.push(`/calculator/${membership.id}/education`)
						}
					/>
				)}
				<Button outline color="link" onClick={() => history.push("/timeline")}>
					{t("features.dashboard.home.back")}
				</Button>
				{membership.canDelete && (
					<Button
						className="px-4 py-3 rounded-pill"
						outline
						color="danger"
						onClick={() => setShowConfirmModal(true)}
					>
						{t("features.dashboard.home.delete")}
					</Button>
				)}
			</div>
		</>
	);
};

interface HomeCalculatorProps {
	// Should find the actual type to not use any
	Icon?: React.FC
	title: string
	description?: string
	actionText?: string
	onButtonDown?: () => void,
	lifeeExpectancy?: boolean
}

export const HomeCalculator: React.FC<HomeCalculatorProps> = ({
	Icon,
	title,
	description,
	actionText,
	onButtonDown,
	lifeeExpectancy
}: HomeCalculatorProps) => {
	return (
		<div className="home-calculator">
			
					<div className="icon">{Icon ? <Icon /> : null}</div>
			
			<div className="text">
				<div className="titletext">{title}</div>
				{description ? <div className="description">{description}</div> : null}
			</div>
			<div className="action">
						<Button
							outline
							color="primary"
							onClick={onButtonDown}
							disabled={onButtonDown === undefined}
						>
							{actionText}
						</Button>
				

			</div>
		</div>
	);
};

export default DashboardHome;
