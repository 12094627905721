import React, { useState, useContext, useEffect } from 'react';
import createAuth0Client, {
  Auth0ClientOptions,
  LogoutOptions,
} from '@auth0/auth0-spa-js/';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';

// context
interface Auth0Context {
  isAuthenticated: boolean;
  loading: boolean;
  user?: any;
  token?: string | null;
  logout: () => Promise<void>;
}

export const Auth0Context = React.createContext<Auth0Context>({
  isAuthenticated: false,
  loading: false,
  logout: async () => {
    return;
  },
});

export const useAuth0 = (): Auth0Context => useContext(Auth0Context);

interface Props {
  options: Auth0ClientOptions;
  children?: React.ReactNode;
}

export const Auth0Provider: React.FC<Props> = ({
  options,
  children,
}: Props) => {
  const [auth0Client, setAuth0Client] = useState<Auth0Client | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState<string | null>();
  const [user] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initClient = async () => {
      const client = await createAuth0Client(options);
      setAuth0Client(client);
      try {
        const accessToken = await client.getTokenSilently();
        if (accessToken) {
          window.location.hash = '';
          setToken(accessToken);
          setIsAuthenticated(true);
        } else {
          setToken(null);
        }
      } catch (err) {
        setToken(null);
      }
      setLoading(false);
    };

    initClient();
  }, [options]);

  const logout = async (): Promise<void> => {
    const logoutOpts: LogoutOptions = {
      returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI || '',
      client_id: options.client_id,
    };
    await auth0Client?.logout(logoutOpts);
    setIsAuthenticated(false);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        token,
        logout: logout,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
