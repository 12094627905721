import React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { Spinner } from "reactstrap";
import { Family } from "shared/types";
import GetStarted from "..";
import { personAndMembershipFragment } from "shared/queries";

interface MembershipQuery {
  family: Family
}

export const GET_MEMBERSHIP = gql`
  query {
    family {
      id
      profiles {
        membership {
          ...membershipFields
        }
      }
    }
  }

  ${personAndMembershipFragment}
`;

const InviteOnboarding: React.FC = () => {
	const { data, loading, error } = useQuery<MembershipQuery>(GET_MEMBERSHIP);

	if (loading)
		return (
			<div className="spinner-container">
				<Spinner size="lg" />
			</div>
		);

	if (error) {
		return <div />;
	}

	if (data) {
		/* Preconditions:
    - after being invited, user's family exists before getstarted flow finishes
    - user's profile is the only one in the family
    */
		const membership = data.family.profiles[0].membership;

		return <GetStarted membership={membership} />;
	}

	return <div />;
};

export default InviteOnboarding;
