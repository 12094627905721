import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormikProps, Formik, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import { Form, Input, Button } from 'reactstrap'
import { Sex, PERSON_NAME_MAX_LENGTH } from '../../../shared/types'
import countries from '../../../features/getstarted/countries'

export interface PersonFormValues {
  firstName: string
  lastName: string
  birthYear?: number | ''
  sex: Sex | ''
  country: string
  reset: boolean
}

interface PersonFormProps {
  initialValues: PersonFormValues
  onSubmit:
    | ((values: PersonFormValues) => void)
    | ((values: PersonFormValues) => Promise<void>)
  disabled?: boolean
  submitText: string
  getResetValues?: (values: PersonFormValues) => PersonFormValues
  submitResetText?: string
}

const thisYear = new Date().getFullYear()

const PersonForm: React.FC<PersonFormProps> = (props: PersonFormProps) => {
  const { t } = useTranslation()

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required(t('features.getstarted.name.errors.firstName.empty')),
    lastName: yup
      .string()
      .required(t('features.getstarted.name.errors.lastName.empty')),
    birthYear: yup
      .number()
      .required(t('features.getstarted.year.errors.yearOfBirth.empty'))
      .integer(t('features.getstarted.year.errors.yearOfBirth.nonInteger'))
      .positive(t('features.getstarted.year.errors.yearOfBirth.negative'))
      .min(
        1900,
        t('features.getstarted.year.errors.yearOfBirth.outOfRange', {
          thisYear,
        })
      )
      .max(
        thisYear,
        t('features.getstarted.year.errors.yearOfBirth.outOfRange', {
          thisYear,
        })
      ),
    sex: yup.string().required(t('features.getstarted.sex.errors.empty')),
    country: yup.string().required(t('features.getstarted.sex.country.empty')),
  })

  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
    >
      {(formik: FormikProps<PersonFormValues>) => (
        <Form>
          <Field
            as={Input}
            name="firstName"
            placeholder={t('features.getstarted.info.firstName')}
            maxLength={PERSON_NAME_MAX_LENGTH}
            className={`goal custom${
              formik.errors.firstName ? ' has-errors' : ''
            }`}
          />
          <ErrorMessage name="firstName">
            {msg => <div className="form-error">{msg}</div>}
          </ErrorMessage>
          <Field
            as={Input}
            type="text"
            name="lastName"
            placeholder={t('features.getstarted.info.lastName')}
            maxLength={PERSON_NAME_MAX_LENGTH}
            className={`goal custom${
              formik.errors.lastName ? ' has-errors' : ''
            }`}
          />
          <ErrorMessage name="lastName">
            {msg => <div className="form-error">{msg}</div>}
          </ErrorMessage>
          <Field
            as={Input}
            type="number"
            name="birthYear"
            placeholder={t('features.getstarted.info.year')}
            className={`goal custom${
              formik.errors.birthYear ? ' has-errors' : ''
            }`}
          />
          <ErrorMessage name="birthYear">
            {msg => <div className="form-error">{msg}</div>}
          </ErrorMessage>
          <Field
            as={Input}
            type="select"
            name="sex"
            className={`goal custom${formik.errors.sex ? ' has-errors' : ''}`}
          >
            <option value="default">{t('features.getstarted.info.sex')}</option>
            <option value={Sex.Male}>Male</option>
            <option value={Sex.Female}>Female</option>
          </Field>
          <ErrorMessage name="sex">
            {msg => <div className="form-error">{msg}</div>}
          </ErrorMessage>
          <Field
            as={Input}
            type="select"
            name="country"
            className={`goal custom${
              formik.errors.country ? ' has-errors' : ''
            }`}
          >
            <option value="default">
              {t('features.getstarted.info.country')}
            </option>
            {countries.map((country, index) => (
              <option key={index} value={country.code}>
                {country.name}
              </option>
            ))}
          </Field>
          <ErrorMessage name="country">
            {msg => <div className="form-error">{msg}</div>}
          </ErrorMessage>
          <hr />
          <Button
            color="primary"
            block
            size="lg"
            onClick={async () => {
              formik.setFieldValue('reset', false)
              await formik.submitForm()
            }}
            disabled={
              !formik.values.firstName ||
              !formik.values.lastName ||
              !formik.values.birthYear ||
              !formik.values.sex ||
              !formik.values.country ||
              props.disabled
            }
          >
            {props.submitText}
          </Button>
          {props.submitResetText && (
            <Button
              type="button"
              color="primary"
              block
              outline
              size="lg"
              onClick={async () => {
                formik.setFieldValue('reset', true)
                await formik.submitForm()
                if (props.getResetValues) {
                  formik.resetForm({
                    values: props.getResetValues(formik.values),
                  })
                }
              }}
              disabled={
                !formik.values.firstName ||
                !formik.values.lastName ||
                !formik.values.birthYear ||
                !formik.values.sex ||
                !formik.values.country ||
                props.disabled
              }
            >
              {props.submitResetText}
            </Button>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default PersonForm
