import React, { useState } from 'react'

import { Event } from 'shared/types'
import { Modal, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { NavLeftIcon, NavRightIcon } from 'shared/views/Icon'
import {
  GraphSeriesMarker,
  GraphMarkerShape,
  GRAPH_MARKER_DIAMOND_WIDTH,
} from 'features/graph/Graph'

interface EventViewModalBodyProps {
  event: Event
  toggle: () => void
  hideEvent: () => void
  shiftEvent: (direction: 'forward' | 'back') => void
  events: Event[]
}

export const EventViewModalBody: React.FC<EventViewModalBodyProps> = (
  props: EventViewModalBodyProps
) => {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const description = props.event.name

  const idx =
    (props.events.findIndex(event => event.id === props.event.id) || 0) + 1
  const numNotes = props.events.length

  const diamondWidth = GRAPH_MARKER_DIAMOND_WIDTH
  const halfDiamondWidth = diamondWidth / 2

  return (
    <>
      <NavLeftIcon
        className={idx === 1 ? 'disabled' : 'clickable'}
        onClick={() => {
          if (idx !== 1) props.shiftEvent('back')
        }}
      />
      <div className="note-view-modal content">
        <div className="note-view-modal header">
          <h3>{`${t(
            'features.graph.eventModal.title'
          )} (${idx}/${numNotes})`}</h3>
        </div>
        <ModalBody>
          <div className="event-view-modal picture">
            <svg
              viewBox={`${-halfDiamondWidth} ${-halfDiamondWidth} ${diamondWidth} ${diamondWidth}`}
            >
              <GraphSeriesMarker
                position={{ x: 0, y: 0, value: 0 }}
                markerShape={GraphMarkerShape.Diamond}
                onClick={() => {
                  return
                }}
              >
                {/* Icon component goes here */}
              </GraphSeriesMarker>
            </svg>
          </div>
          <div className="note-view-modal text">
            <i>
              {t('features.notes.noteViewModal.year', {
                year: props.event.year,
              })}
            </i>
            <p className="mb-0">{description}</p>
          </div>
        </ModalBody>
        <ModalFooter className="event-view-modal">
          {isSubmitting ? (
            <Spinner />
          ) : (
            <>
              <div className="d-flex justify-content-around w-100">
                <Button
                  onClick={async () => {
                    setIsSubmitting(true)
                    await props.hideEvent()
                    setIsSubmitting(false)
                  }}
                  color="link"
                  className="note-edit-modal-done"
                >
                  <h2 className="font-weight-light">
                    {t('features.graph.eventModal.hide')}
                  </h2>
                </Button>
                <Button
                  onClick={props.toggle}
                  color="link"
                  className="note-edit-modal-done"
                >
                  <h2>{t('features.notes.noteViewModal.close')}</h2>
                </Button>
              </div>
            </>
          )}
        </ModalFooter>
      </div>
      <NavRightIcon
        className={idx === numNotes ? 'disabled' : 'clickable'}
        onClick={() => {
          if (idx !== numNotes) props.shiftEvent('forward')
        }}
      />
    </>
  )
}

interface EventViewModalProps extends EventViewModalBodyProps {
  isOpen: boolean
}

const EventViewModal: React.FC<EventViewModalProps> = (
  props: EventViewModalProps
) => {
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      centered
      contentClassName="note-view-modal box"
    >
      <EventViewModalBody {...props} />
    </Modal>
  )
}

export default EventViewModal
