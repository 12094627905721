import ApolloClient, { ApolloError } from 'apollo-boost'
import { i18n } from 'i18next'

export const setupClient = (token?: string) =>
  new ApolloClient({
    uri: process.env.REACT_APP_API_URL,
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {},
  })

export const apolloErrorToString = (
  error: ApolloError,
  i18nObj: i18n
): string => {
  const errorStrings = error.graphQLErrors.map(gqlErr => {
    const i18nKey = `shared.errors.${gqlErr.message}`
    if (i18nObj.exists(i18nKey)) {
      return i18nObj.t(i18nKey)
    } else {
      return gqlErr.message
    }
  })
  return errorStrings.join(' ')
}
