import React from 'react'
import { Profile, Note, Role } from 'shared/types'
import TimelineBar from './TimelineBar'
import '../styles.scss'
import AxisTranslator from 'shared/utils/axis'

interface TimelineProps {
  profiles: Array<Profile>
  start: number
  end: number
  onViewNote: (note: Note) => void
}

export const Timeline: React.FC<TimelineProps> = ({
  profiles,
  start,
  end,
  onViewNote,
}: TimelineProps) => {
  const timelineStart = start - (start % 5)
  const timelineEndMargin = 5 - (end % 5)

  const nowYear = new Date().getFullYear()

  const nonMembers = profiles.filter(
    profile => profile.membership.role !== Role.Member
  )
  const members = profiles.filter(
    profile => profile.membership.role === Role.Member
  )

  return (
    <div
      className="timeline bars-container"
      style={{
        paddingRight: `${AxisTranslator.year(timelineEndMargin, false) + 50}px`,
      }}
    >
      {nonMembers.map((profile, index) => (
        <TimelineBar
          person={profile.membership.person}
          timeline={profile.timeline}
          start={timelineStart}
          role={profile.membership.role}
          key={`timeline-bars-${index}`}
          onViewNote={onViewNote}
        />
      ))}
      {members.length !== 0 && <div className="member-separator"></div>}
      {members.map((profile, index) => (
        <TimelineBar
          person={profile.membership.person}
          timeline={profile.timeline}
          start={timelineStart}
          role={profile.membership.role}
          key={`timeline-bars-${index}`}
          onViewNote={onViewNote}
        />
      ))}
      <div
        className="year-redline timeline"
        style={{
          left: `${AxisTranslator.year(nowYear - timelineStart)}px`,
        }}
      />
    </div>
  )
}

export default Timeline
