import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Input, Container } from 'reactstrap'
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik'
import * as yup from 'yup'
import '../styles.scss'
import { useHistory } from 'react-router-dom'

interface Values {
  birthYear?: number
}

interface Props {
  name?: string
  year?: number
  setYear: (year?: number) => void
}

//breaks if somebody opens the app on new year's
const thisYear = new Date().getFullYear()

const YearOfBirth: React.FC<Props> = ({ name, year, setYear }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()

  const YearSchema = yup.object().shape({
    birthYear: yup
      .number()
      .required(t('features.getstarted.year.errors.yearOfBirth.empty'))
      .integer(t('features.getstarted.year.errors.yearOfBirth.nonInteger'))
      .positive(t('features.getstarted.year.errors.yearOfBirth.negative'))
      .min(
        1900,
        t('features.getstarted.year.errors.yearOfBirth.outOfRange', {
          thisYear,
        })
      )
      .max(
        thisYear,
        t('features.getstarted.year.errors.yearOfBirth.outOfRange', {
          thisYear,
        })
      ),
  })

  const handleSubmit = ({ birthYear }: Values): void => {
    setYear(birthYear)
    history.push('/sex')
  }

  return (
    <Container className="content">
      <h3 className="text-center">{name}</h3>
      <h1 className="display-4 text-center title">
        {t('features.getstarted.year.title')}
      </h1>
      <Formik
        initialValues={{ birthYear: year }}
        onSubmit={handleSubmit}
        validationSchema={YearSchema}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
      >
        {({ values, errors, touched }: FormikProps<Values>) => (
          <Form>
            <Field
              as={Input}
              type="number"
              name="birthYear"
              placeholder={t('features.getstarted.year.yearOfBirth')}
              className={`goal custom${errors.birthYear ? ' has-errors' : ''}`}
            />
            <ErrorMessage name="birthYear">
              {msg => <div className="form-error">{msg}</div>}
            </ErrorMessage>
            <hr />
            <Button type="submit" color="primary" block size="lg">
              {t('features.getstarted.next')}
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default YearOfBirth
