import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Input, Container } from 'reactstrap'
import '../styles.scss'
import { useHistory } from 'react-router-dom'
import Header from '../../../shared/views/Header'
import * as types from 'shared/types'
import * as yup from 'yup'
import { gql, ApolloError } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { apolloErrorToString } from 'shared/utils/api/ApolloClient'
import { FormikHelpers, Formik, Form, Field, ErrorMessage } from 'formik'

export const INVITE_MEMBER = gql`
  mutation($email: String!, $id: ID!, $uri: String!) {
    inviteMembership(email: $email, membershipId: $id, redirectUri: $uri)
  }
`

interface Values {
  email?: string
}

interface InviteMemberProps {
  role: types.Role
  id: number
}

const InviteMember: React.FC<InviteMemberProps> = ({
  role,
  id,
}: InviteMemberProps) => {
  const { i18n } = useTranslation()
  const [mutate] = useMutation(INVITE_MEMBER)
  const [submitted, setSubmitted] = useState<boolean>(false)

  const onSubmit = (values: Values, formik: FormikHelpers<Values>) => {
    const variables = {
      email: values.email,
      id: id,
      uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
    }
    if (values.email) {
      mutate({
        variables: variables,
      })
        .then(() => {
          setSubmitted(true)
        })
        .catch((error: ApolloError) => {
          formik.setFieldError('email', apolloErrorToString(error, i18n))
        })
    }
  }

  return (
    <InviteMemberInner
      role={role}
      id={id}
      onSubmit={onSubmit}
      submitted={submitted}
    />
  )
}

interface InviteMemberInnerProps {
  role: types.Role
  id: number
  onSubmit: (values: Values, formik: FormikHelpers<Values>) => void
  submitted: boolean
}

export const InviteMemberInner: React.FC<InviteMemberInnerProps> = ({
  role,
  id,
  onSubmit,
  submitted,
}: InviteMemberInnerProps) => {
  const { t: tr } = useTranslation()
  const history = useHistory()
  const t = (key: string) =>
    tr(`features.dashboard.invite${role.toLowerCase()}.${key}`)

  const initialValues: Values = {
    email: '',
  }
  const EmailSchema = yup.object().shape({
    email: yup
      .string()
      .required(t('features.getstarted.login.errors.email.empty'))
      .email(t('features.getstarted.login.errors.email.invalid')),
  })

  return (
    <>
      <Header text={tr('features.dashboard.nav.invite')} />
      <Container className="content invite-member">
        {!submitted && (
          <>
            <h1 className="display-4 text-center title">{t('title')}</h1>
            <p>{t('body')}</p>
          </>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
          validationSchema={EmailSchema}
        >
          <Form>
            {submitted ? (
              <p className="text-center">{t('sent')}</p>
            ) : (
              <>
                <Field
                  as={Input}
                  type="email"
                  name="email"
                  placeholder={t('email')}
                  className="goal custom"
                />
                <ErrorMessage name="email">
                  {msg => <div className="form-error">{msg}</div>}
                </ErrorMessage>
                <hr />
                <Button color="primary" block size="lg" type="submit">
                  {t('sendEmail')}
                </Button>
              </>
            )}
            <Button
              color="primary"
              block
              outline
              size="lg"
              onClick={() => history.push(`/dashboard/${id}`)}
            >
              {t('editInfo')}
            </Button>
            <Button
              block
              size="lg"
              outline
              color="link"
              onClick={() => history.push(`/timeline`)}
            >
              {tr('features.dashboard.home.back')}
            </Button>
          </Form>
        </Formik>
      </Container>
    </>
  )
}

export default InviteMember
