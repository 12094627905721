const {
  UnAbridgedLifeExpectancy,
} = require('@ottawamhealth/pbl-calculator-engine/lib/life-expectancy');
/* Import the MPoRT model we previously built. Look at the Building Models section for more information */
const { mportModel } = require('./building-models');
const { RefPopFunctionsBuilder } = require("@ottawamhealth/pbl-calculator-engine");
/* The Life table we will be using. This is for Canada. If you are doing 
this calculation on individuals from another country please use a life table 
from that country */
const canadaLifeTable = require('@ottawamhealth/pbl-calculator-engine-assets/MPoRT/life-table.json');

/* Construct an object which has the life expectancy functions using the above 
MPoRT model and lifetable*/
const unAbridgedLifeExpectancy = new UnAbridgedLifeExpectancy(
  mportModel,
  canadaLifeTable
);

// The population with whom we will be comparing individuals to
const referencePopulation = require("@ottawamhealth/pbl-calculator-engine-assets/MPoRT/ref-pops/Canada_2012.json");

/* Initialize the reference population functions using the MPoRT object and reference population objects parsed above */
const RefPopFunctions = RefPopFunctionsBuilder.withModel(
  mportModel.model
).withRefPop(referencePopulation);



const setAgeAndGender = (age, sex) => {
  const year = new Date().getFullYear();
  const currentAge = year - age;
  localStorage.setItem('age', currentAge);
  if (sex === 'FEMALE') {
    localStorage.setItem('sex', 'female');
  }
  if (sex === 'MALE') {
    localStorage.setItem('sex', 'male');
  }

};
const getLifeExpectancy = () => {
  const individualData = [
    { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
    { name: 'sex', coefficent: localStorage.getItem('sex') },
  ];
  const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
    individualData
  );
  console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
  let expectancy = 0;
  if (lifeExpectancy != null) {
    expectancy = parseInt(lifeExpectancy);
  }
  return expectancy;
};

const showLifeExpectancyBMI = (values) => {
  const meter = values[1] * 0.01;
  const m = meter * meter;
  const bmi = values[0] / m;
  console.log('========meter=====', meter);
  console.log('=============', meter * meter);
  console.log('======kg======', values[0]);
  console.log('======cm======', values[1]);
  console.log('===========bmi values =======', bmi);
  localStorage.setItem('BMI_cont', bmi);
  const individualData = [
    { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
    { name: 'sex', coefficent: localStorage.getItem('sex') },
    { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
  ];
  const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
    individualData
  );
  console.log("=====bmi individual value =======",individualData)
  console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
  let expectancy = 0;
  if (lifeExpectancy != null) {
    expectancy = parseInt(lifeExpectancy);
  }
  return expectancy;
};

const showLIfeExpectancyActivity = (
  continuousActivity,
  nonContinuousActivity,
  lightActivity
) => {
  console.log('enter in life exptectancy activity');
  // PhysicalActivity
  let mets =
    (continuousActivity * 9.5) / 7 +
    (nonContinuousActivity * 5.5) / 7 +
    (lightActivity * 3.5) / 7;
  localStorage.setItem('PhysicalActivity', parseInt(mets));
  console.log('=======mets======', parseInt(mets));
  const individualData = [
    { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
    { name: 'sex', coefficent: localStorage.getItem('sex') },
    { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
    { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity')) },
  ];
  const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
    individualData
  );
  console.log("========physical activity indiviual values is =======",individualData)
  console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
  let expectancy = 0;
  if (lifeExpectancy != null) {
    expectancy = parseInt(lifeExpectancy);
  }
  return expectancy;
};

const showLifeExpectancySmoking = (smokingStatus, lightOrHeavy) => {
  console.log("enter in showlife expectancy smoking")
  console.log("=====smokingstatus===", smokingStatus)
  console.log("====light or heavy=====", lightOrHeavy)
 
  
  if (smokingStatus === "NEVER_SMOKED") {
    localStorage.setItem('TypeOfSmoker', 0);
    localStorage.removeItem("stpn")
    console.log("=======never smoked=====",localStorage.getItem('TypeOfSmoker'))
    const individualData = [
      { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
      { name: 'sex', coefficent: localStorage.getItem('sex') },
      { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
      { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity')) },
      { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker') )},
    ]; 
    console.log("individual data is :==========================",individualData);
    const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
      individualData
    );
    
    console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
    let expectancy = 0;
    if (lifeExpectancy != null) {
      expectancy = parseInt(lifeExpectancy);
    }
    return expectancy;
  }
  if (smokingStatus === 'USED_TO_LESS_PACK_DAILY') {
    localStorage.setItem('TypeOfSmoker', 1);
    if (lightOrHeavy === 'LESS_THAN_YEAR_AGO') {
      localStorage.setItem("stpn", "stpn1")
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity'))},
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker'))},
        { name: 'stpn', coefficent: localStorage.getItem('stpn') },
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    }
    if (lightOrHeavy === 'USED_TO_LESS_PACK_DAILY') {
      localStorage.setItem("stpn", "stpn2")
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont'))},
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity'))},
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker'))},
        { name: 'stpn', coefficent: localStorage.getItem('stpn') },
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    }
    if (lightOrHeavy === 'CURRENT_LESS_PACK_DAILY') {
      localStorage.setItem("stpn", "stpn3")
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont'))},
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity')) },
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker')) },
        { name: 'stpn', coefficent: localStorage.getItem('stpn') },
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    }
    if (lightOrHeavy === 'CURRENT_MORE_PACK_DAILY') {
      localStorage.setItem("stpn", "stpn4")
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent:parseInt( localStorage.getItem('BMI_cont')) },
        { name: 'PhysicalActivity', coefficent:parseInt(localStorage.getItem('PhysicalActivity') )},
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker') )},
        { name: 'stpn', coefficent: localStorage.getItem('stpn') },
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    }
  }
  if (smokingStatus === 'USED_TO_MORE_PACK_DAILY') {
    localStorage.setItem('TypeOfSmoker', 2);

    if (lightOrHeavy === 'LESS_THAN_YEAR_AGO') {
      localStorage.setItem("stpn", "stpn1")
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity') )},
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker')) },
        { name: 'stpn', coefficent: localStorage.getItem('stpn') },
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    }
    if (lightOrHeavy === 'USED_TO_LESS_PACK_DAILY') {
      localStorage.setItem("stpn", "stpn2")
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont') )},
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity')) },
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker') )},
        { name: 'stpn', coefficent: localStorage.getItem('stpn') },
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    }
    if (lightOrHeavy === 'CURRENT_LESS_PACK_DAILY') {
      localStorage.setItem("stpn", "stpn3")
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont'))},
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity'))},
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker'))},
        { name: 'stpn', coefficent: localStorage.getItem('stpn') },
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    }
    if (lightOrHeavy === 'CURRENT_MORE_PACK_DAILY') {
      localStorage.setItem("stpn", "stpn4")
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity')) },
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker')) },
        { name: 'stpn', coefficent: localStorage.getItem('stpn') },
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    }

  }
  if (smokingStatus === 'CURRENT_LESS_PACK_DAILY') {
    localStorage.setItem('TypeOfSmoker', 3);
    localStorage.removeItem("stpn")
    const individualData = [
      { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
      { name: 'sex', coefficent: localStorage.getItem('sex') },
      { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
      { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity') )},
      { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker')) },
    ];
    const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
      individualData
    );
    console.log("individual data is :==========================",individualData);
    console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
    let expectancy = 0;
    if (lifeExpectancy != null) {
      expectancy = parseInt(lifeExpectancy);
    }
    return expectancy;
  }
  if (smokingStatus === 'CURRENT_MORE_PACK_DAILY') {
    localStorage.removeItem("stpn")
    localStorage.setItem('TypeOfSmoker', 4);
    const individualData = [
      { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
      { name: 'sex', coefficent: localStorage.getItem('sex') },
      { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont') )},
      { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity') )},
      { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker') )},
    ];
    const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
      individualData
    );
    console.log("individual data is :==========================",individualData);
    console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
    let expectancy = 0;
    if (lifeExpectancy != null) {
      expectancy = parseInt(lifeExpectancy);
    }
    return expectancy;
  }
};

const showLifeExpectancyAlcohol = (alcoholConsumption) => {
  if (alcoholConsumption === "LESS_THAN_FOUR_WEEK") {
    localStorage.setItem("AlcoholType", 0)
    if (localStorage.getItem('stpn') !== null) {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont') )},
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity') )},
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker')) },
        { name: 'stpn', coefficent: localStorage.getItem('stpn') },
        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType')) },
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    } else {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity') )},
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker')) },
        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType')) }
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    }

  }
  if (alcoholConsumption === "FOUR_TO_TWENTYONE_WEEK") {
    localStorage.setItem("AlcoholType", 1)
    if (localStorage.getItem('stpn') !== null) {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity')) },
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker')) },
        { name: 'stpn', coefficent: localStorage.getItem('stpn') },
        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType')) }
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    } else {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity')) },
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker')) },
        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType') )}
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    }

  }
  if (alcoholConsumption === "MORE_THAN_TWENTYONE_WEEK") {
    localStorage.setItem("AlcoholType", 2)
    if (localStorage.getItem('stpn') !== null) {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity')) },
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker') )},
        { name: 'stpn', coefficent: localStorage.getItem('stpn') },
        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType')) }
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    } else {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity')) },
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker')) },
        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType')) }
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    }

  }

}

const showLifeExpectancyDeprivation = (deprivation) => {

  if (deprivation === "BELOW_AVERAGE") {
    localStorage.setItem("DepInd", 0)
    if (localStorage.getItem('stpn') !== null) {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity')) },
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker')) },
        { name: 'stpn', coefficent: localStorage.getItem('stpn') },
        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType')) },
        { name: 'DepInd', coefficent: parseInt(localStorage.getItem('DepInd')) },
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    } else {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont') )},
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity') )},
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker'))},
        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType')) },
        { name: 'DepInd', coefficent: parseInt(localStorage.getItem('DepInd')) },
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    }

  }
  if (deprivation === "AVERAGE") {
    localStorage.setItem("DepInd", 1)
    if (localStorage.getItem('stpn') !== null) {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent:parseInt( localStorage.getItem('BMI_cont')) },
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity')) },
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker') )},
        { name: 'stpn', coefficent: localStorage.getItem('stpn') },
        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType')) },
        { name: 'DepInd', coefficent: parseInt(localStorage.getItem('DepInd')) },
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    } else {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont') )},
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity') )},
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker') )},

        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType') )},
        { name: 'DepInd', coefficent: parseInt(localStorage.getItem('DepInd') )},
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    }

  }
  if (deprivation === "ABOVE_AVERAGE") {
    localStorage.setItem("DepInd", 2)
    if (localStorage.getItem('stpn') !== null) {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont') )},
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity')) },
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker')) },
        { name: 'stpn', coefficent: localStorage.getItem('stpn') },
        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType')) },
        { name: 'DepInd', coefficent: parseInt(localStorage.getItem('DepInd') )},
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    } else {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity') )},
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker')) },

        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType')) },
        { name: 'DepInd', coefficent: parseInt(localStorage.getItem('DepInd') )},
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    }

  }
}

const showLifeExpectancyEducation = (education) => {

  if (education === "POST_SECONDARY_SCHOOL_GRADUATE") {
    localStorage.setItem("Edu1", 0)
    localStorage.setItem("DepInd", 2)
    if (localStorage.getItem('stpn') !== null) {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity') )},
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker') )},
        { name: 'stpn', coefficent: localStorage.getItem('stpn') },
        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType') )},
        { name: 'DepInd', coefficent: parseInt(localStorage.getItem('DepInd')) },
        { name: 'Edu1', coefficent:parseInt( localStorage.getItem('Edu1'))},
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    } else {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity') )},
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker')) },

        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType')) },
        { name: 'DepInd', coefficent: parseInt(localStorage.getItem('DepInd')) },
        { name: 'Edu1', coefficent:parseInt(localStorage.getItem('Edu1')) },
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    }

  }
  if (education === "HIGH_SCHOOL_GRADUATE") {
    localStorage.setItem("Edu1", 1)
    localStorage.setItem("DepInd", 1)
    if (localStorage.getItem('stpn') !== null) {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont') )},
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity')) },
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker') )},
        { name: 'sptn', coefficent: localStorage.getItem('stpn') },
        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType')) },
        { name: 'DepInd', coefficent: parseInt(localStorage.getItem('DepInd')) },
        { name: 'Edu1', coefficent: parseInt(localStorage.getItem('Edu1') )},
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    } else {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont') )},
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity')) },
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker')) },

        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType') )},
        { name: 'DepInd', coefficent: parseInt(localStorage.getItem('DepInd')) },
        { name: 'Edu1', coefficent: parseInt(localStorage.getItem('Edu1') )},
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    }

  }
  if (education === "LESS_THAN_HIGHT_SCHOOL_") {
    localStorage.setItem("Edu1", 2)
    localStorage.setItem("DepInd", 0)
    if (localStorage.getItem('stpn') !== null) {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont') )},
        { name: 'PhysicalActivity', coefficent:parseInt( localStorage.getItem('PhysicalActivity') )},
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker') )},
        { name: 'stpn', coefficent: localStorage.getItem('stpn') },
        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType')) },
        { name: 'DepInd', coefficent:parseInt( localStorage.getItem('DepInd')) },
        { name: 'Edu1', coefficent:parseInt( localStorage.getItem('Edu1') )},
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    } else {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont') )},
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity')) },
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker')) },

        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType')) },
        { name: 'DepInd', coefficent: parseInt(localStorage.getItem('DepInd') )},
        { name: 'Edu1', coefficent: parseInt(localStorage.getItem('Edu1') )},
      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
      }
      return expectancy;
    }

  }
}

const showLifeExpectancyDeit = (fruitJuice, fruit, potatoes, vegetables) => {
  localStorage.setItem("juiu", "juiw")
  localStorage.setItem("jui", fruitJuice)
  localStorage.setItem("frtu", "frtw")
  localStorage.setItem("frt", fruit)
  localStorage.setItem("potu", "potw")
  localStorage.setItem("pot", potatoes)
  localStorage.setItem("vegu", "vegw")
  localStorage.setItem("veg", vegetables)
  if (localStorage.getItem('stpn') !== null) {
    const individualData = [
      { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
      { name: 'sex', coefficent: localStorage.getItem('sex') },
      { name: 'BMI_cont', coefficent:parseInt( localStorage.getItem('BMI_cont')) },
      { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity')) },
      { name: 'TypeOfSmoker', coefficent:parseInt( localStorage.getItem('TypeOfSmoker') )},
      { name: 'stpn', coefficent: localStorage.getItem('stpn') },
      { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType') )},
      { name: 'DepInd', coefficent: parseInt(localStorage.getItem('DepInd')) },
      { name: 'Edu1', coefficent: parseInt(localStorage.getItem('Edu1')) },
      { name: 'juiu', coefficent: localStorage.getItem('juiu') },
      { name: 'jui', coefficent: parseInt(localStorage.getItem('jui')) },
      { name: 'frtu', coefficent: localStorage.getItem('frtu') },
      { name: 'frt', coefficent: parseInt(localStorage.getItem('frt')) },

      { name: 'potu', coefficent: localStorage.getItem('potu') },
      { name: 'pot', coefficent: parseInt(localStorage.getItem('pot')) },

      { name: 'vegu', coefficent: localStorage.getItem('vegu') },
      { name: 'veg', coefficent: parseInt(localStorage.getItem('veg')) },

    ];
    const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
      individualData
    );
    console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
    let expectancy = 0;
    if (lifeExpectancy != null) {
      expectancy = parseInt(lifeExpectancy);
    }
    return expectancy;
  } else {
    const individualData = [
      { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
      { name: 'sex', coefficent: localStorage.getItem('sex') },
      { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
      { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity') )},
      { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker')) },

      { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType')) },
      { name: 'DepInd', coefficent: parseInt(localStorage.getItem('DepInd') )},
      { name: 'Edu1', coefficent: parseInt(localStorage.getItem('Edu1')) },
      { name: 'juiu', coefficent: localStorage.getItem('juiu') },
      { name: 'jui', coefficent: parseInt(localStorage.getItem('jui')) },
      { name: 'frtu', coefficent: localStorage.getItem('frtu') },
      { name: 'frt', coefficent: parseInt(localStorage.getItem('frt')) },

      { name: 'potu', coefficent: localStorage.getItem('potu') },
      { name: 'pot', coefficent: parseInt(localStorage.getItem('pot') )},

      { name: 'vegu', coefficent: localStorage.getItem('vegu') },
      { name: 'veg', coefficent: parseInt(localStorage.getItem('veg')) },

    ];
    const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
      individualData
    );
    console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
    let expectancy = 0;
    if (lifeExpectancy != null) {
      expectancy = parseInt(lifeExpectancy);
    }
    return expectancy;
  }

}

const showLifeExpectancyConditions = (conditions) => {
  console.log("=====conditions====", conditions)
  console.log(typeof conditions)
  localStorage.setItem("HeartDis_cat", 0)
  localStorage.setItem("Stroke_cat", 0)
  localStorage.setItem("Cancer_cat", 0)
  localStorage.setItem("Diabetes_cat", 0)
  for (const [key, value] of Object.entries(conditions)) {
    if (key === "hasHeartDisease") {
      if (value === true) {
        localStorage.setItem("HeartDis_cat", 1)
      } 
    }
    if (key === "hasStroke") {
      if (value === true) {
        localStorage.setItem("Stroke_cat", 1)
      } 
    }
    if (key === "hasCancer") {
      if (value === true) {
        localStorage.setItem("Cancer_cat", 1)
      } 
    }
    if (key === "hasDiabetes") {
      if (value === true) {
        localStorage.setItem("Diabetes_cat", 1)
      } 
    }
    

    if (localStorage.getItem('stpn') !== null) {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity')) },
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker')) },
        { name: 'stpn', coefficent: localStorage.getItem('stpn') },
        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType')) },
        { name: 'DepInd', coefficent: parseInt(localStorage.getItem('DepInd')) },
        { name: 'Edu1', coefficent: parseInt(localStorage.getItem('Edu1')) },
        { name: 'juiu', coefficent: localStorage.getItem('juiu') },
        { name: 'jui', coefficent: parseInt(localStorage.getItem('jui')) },
        { name: 'frtu', coefficent: localStorage.getItem('frtu') },
        { name: 'frt', coefficent: parseInt(localStorage.getItem('frt')) },

        { name: 'potu', coefficent: localStorage.getItem('potu') },
        { name: 'pot', coefficent: parseInt(localStorage.getItem('pot')) },

        { name: 'vegu', coefficent: localStorage.getItem('vegu') },
        { name: 'veg', coefficent:parseInt( localStorage.getItem('veg')) },

        { name: 'HeartDis_cat', coefficent: parseInt(localStorage.getItem('HeartDis_cat')) },
        { name: 'Stroke_cat', coefficent: parseInt(localStorage.getItem('Stroke_cat') )},
        { name: 'Cancer_cat', coefficent: parseInt(localStorage.getItem('Cancer_cat')) },
        { name: 'Diabetes_cat', coefficent: parseInt(localStorage.getItem('Diabetes_cat')) },

      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      //const healthAge = RefPopFunctions.getHealthAge(individualData);
     // console.log("health age is  is :==========================",healthAge);
     // localStorage.setItem("healthAge", parseInt(healthAge))

     

      const survivalToAllAges = unAbridgedLifeExpectancy.getSurvivalForAllAges(
        individualData
    );
    /* With the above array, we can find the probability of surviving to all ages
    greater than the individual's age. For eg., in the below code we find the
    probability value for age 80 */
    console.log("========survivalto all ages=====",survivalToAllAges)
    let year = new Date().getFullYear();
    let dict = new Object()
    for(let i =0;i<survivalToAllAges.length;i++){
      
      console.log("=====year=====",year+i,+"======",survivalToAllAges[i].age)
      // ; // create an empty array
      // dict.push({
      //     "year":   year+i,
      //     "survivalProbability": (survivalToAllAges[i].survivalProbability*100).toFixed(2)
      // });
      dict[year+i] = (survivalToAllAges[i].survivalProbability*100).toFixed(0)+"%"
      if(survivalToAllAges[i].age === 101)
          break;
}
localStorage.setItem("survialAllAges",JSON.stringify(dict))
console.log("======dict=====",dict)
    const survivalToAge80 = survivalToAllAges.find((survivalInfo) => {
        return survivalInfo.age === 90
    }).survivalProbability;
    
    console.log(`Probability of surviving to age ========90: ${survivalToAge80}`)
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
        localStorage.setItem("expectancy", expectancy)
      }

      let currentYear = new Date().getFullYear();
      let differenceYear = expectancy-parseInt(localStorage.getItem("age"));
      let expectancyYear = currentYear+differenceYear
      localStorage.setItem("expectancyYear",expectancyYear)
        let difference = parseInt(localStorage.getItem("expectancy"))-parseInt(localStorage.getItem("whoLIfeExpectancy"))
        console.log("======differece is =====",difference)
        let actualHealthAge =  parseInt(localStorage.getItem("age"))-difference
        console.log("======health age =====",actualHealthAge)
        localStorage.setItem("healthAge",actualHealthAge)
        
      return expectancy;
    } else {
      const individualData = [
        { name: 'age', coefficent: parseInt(localStorage.getItem('age')) },
        { name: 'sex', coefficent: localStorage.getItem('sex') },
        { name: 'BMI_cont', coefficent: parseInt(localStorage.getItem('BMI_cont')) },
        { name: 'PhysicalActivity', coefficent: parseInt(localStorage.getItem('PhysicalActivity')) },
        { name: 'TypeOfSmoker', coefficent: parseInt(localStorage.getItem('TypeOfSmoker') )},

        { name: 'AlcoholType', coefficent: parseInt(localStorage.getItem('AlcoholType') )},
        { name: 'DepInd', coefficent: parseInt(localStorage.getItem('DepInd')) },
        { name: 'Edu1', coefficent: parseInt(localStorage.getItem('Edu1')) },
        { name: 'juiu', coefficent: localStorage.getItem('juiu') },
        { name: 'jui', coefficent: parseInt(localStorage.getItem('jui')) },
        { name: 'frtu', coefficent: localStorage.getItem('frtu') },
        { name: 'frt', coefficent: parseInt(localStorage.getItem('frt')) },

        { name: 'potu', coefficent: localStorage.getItem('potu') },
        { name: 'pot', coefficent: parseInt(localStorage.getItem('pot')) },

        { name: 'vegu', coefficent: localStorage.getItem('vegu') },
        { name: 'veg', coefficent: parseInt(localStorage.getItem('veg') )},

        { name: 'HeartDis_cat', coefficent: parseInt(localStorage.getItem('HeartDis_cat') )},
        { name: 'Stroke_cat', coefficent: parseInt(localStorage.getItem('Stroke_cat')) },
        { name: 'Cancer_cat', coefficent:parseInt( localStorage.getItem('Cancer_cat')) },
        { name: 'Diabetes_cat', coefficent: parseInt(localStorage.getItem('Diabetes_cat')) }

      ];
      const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
        individualData
      );
      console.log("individual data is :==========================",individualData);
      //const healthAge = RefPopFunctions.getHealthAge(individualData);
      //console.log("health age is  is :==========================",healthAge);
      //localStorage.setItem("healthAge", parseInt(healthAge))
      console.log(`Normal Life Expectancy: ${lifeExpectancy}`);
      const survivalToAllAges = unAbridgedLifeExpectancy.getSurvivalForAllAges(
        individualData
    );
    console.log("========survivalto all ages=====",survivalToAllAges)
    let year = new Date().getFullYear();
    let dict= new Object()
    for(let i =0;i<survivalToAllAges.length;i++){

          // console.log(survivalToAllAges[i].age)
          // console.log("=====year=====",year+i,"======",survivalToAllAges[i].age)
        //   dict.push({
        //     "year":   year+i,
        //     "survivalProbability": (survivalToAllAges[i].survivalProbability*100).toFixed(2)
        // });
        dict[year+i] = (survivalToAllAges[i].survivalProbability*100).toFixed(0)+"%"
        if(survivalToAllAges[i].age === 101)
          break;
    }
    localStorage.setItem("survialAllAges",JSON.stringify(dict))
    console.log("======dict=====",dict)
    
      


      let expectancy = 0;
      if (lifeExpectancy != null) {
        expectancy = parseInt(lifeExpectancy);
        localStorage.setItem("expectancy", expectancy)
      }

      let currentYear = new Date().getFullYear();
      let differenceYear = expectancy-parseInt(localStorage.getItem("age"));
      let expectancyYear = currentYear+differenceYear
      localStorage.setItem("expectancyYear",expectancyYear)

      let difference = parseInt(localStorage.getItem("expectancy"))-parseInt(localStorage.getItem("whoLIfeExpectancy"))
        console.log("======differece is =====",difference)
        let actualHealthAge =  parseInt(localStorage.getItem("age"))-difference
        console.log("======health age -=-=====",actualHealthAge)
        localStorage.setItem("healthAge",actualHealthAge)
        
      return expectancy;
    }
   
  }

}


const removeLocalStorage = () => {

  localStorage.removeItem('BMI_cont')
  localStorage.removeItem('PhysicalActivity')
  localStorage.removeItem('TypeOfSmoker')

  localStorage.removeItem('AlcoholType')
  localStorage.removeItem('DepInd')
  localStorage.removeItem('Edu1')
  localStorage.removeItem('juiu')
  localStorage.removeItem('jui')
  localStorage.removeItem('frtu')
  localStorage.removeItem('frt')
  localStorage.removeItem('stpn')
  localStorage.removeItem('potu')
  localStorage.removeItem('pot')

  localStorage.removeItem('vegu')
  localStorage.removeItem('veg')

  localStorage.removeItem('HeartDis_cat')
  localStorage.removeItem('Stroke_cat')
  localStorage.removeItem('Cancer_cat')
  localStorage.removeItem('Diabetes_cat')

  localStorage.removeItem("final_year")
  localStorage.removeItem("sur1")
  localStorage.removeItem("sur2")
  localStorage.removeItem("sur3")
  localStorage.removeItem("sur4")
  localStorage.removeItem("sur5")
  localStorage.removeItem("sur6")
}


const getHealthAge =  ()=>{
 


  const individualData = [
    {name: "age", coefficent: 47},
 {name: "sex", coefficent: "male"},
 {name: "BMI_cont", coefficent: 24},
 {name: "PhysicalActivity", coefficent: 13.22},
{name: "TypeOfSmoker", coefficent: 0},
{name: "AlcoholType", coefficent: 1},
 {name: "DepInd", coefficent: 0},
 {name: "Edu1", coefficent: 0},
 {name: "juiu", coefficent: "juiw"},
 {name: "jui", coefficent: 5},
 {name: "frtu", coefficent: "frtw"},
 {name: "frt", coefficent: 5},
 {name: "potu", coefficent: "potw"},
 {name: "pot", coefficent: 5},
 {name: "vegu", coefficent: "vegw"},
 {name: "veg", coefficent: 5},
 { name: "car", coefficent: 5 },
 { name: "caru", coefficent: "carw" },
 { name: "sal", coefficent: 5 },
 { name: "salu", coefficent: "salw" },
 {name: "HeartDis_cat", coefficent: 0},
 {name: "Stroke_cat", coefficent: 0},
 {name: "Cancer_cat", coefficent: 0},
 {name: "Diabetes_cat", coefficent: 0},
 { name: "Immig1", coefficent: 0 },
];
const lifeExpectancy = unAbridgedLifeExpectancy.calculateForIndividual(
  individualData
);
//const healthAge = RefPopFunctions.getHealthAge(individualData);
console.log(`Normal Life Expectancy===============yulric: ${lifeExpectancy}`);
//console.log(`Normal Life healthAge===============yulric: ${healthAge}`);



const survivalToAge70 = unAbridgedLifeExpectancy.getSurvivalToAge(
  individualData,
  52
);

console.log(`Probability of surviving to age 70: ${survivalToAge70}`);
return  localStorage.getItem("healthAge")
}
export {
  setAgeAndGender,
  getLifeExpectancy,
  showLifeExpectancyBMI,
  showLIfeExpectancyActivity,
  showLifeExpectancySmoking,
  showLifeExpectancyAlcohol,
  showLifeExpectancyDeprivation,
  showLifeExpectancyEducation,
  showLifeExpectancyDeit,
  showLifeExpectancyConditions,
  removeLocalStorage,
  getHealthAge
};
