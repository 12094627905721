import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles.scss';
import { Button, Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import * as quizTypes from '../reducer';
import SelectItemView from './SelectItemView';
import { showLifeExpectancyConditions } from './LifeExpectancy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface Props {
  health: Partial<Health>;
  setHealth: (input: quizTypes.SetHealth) => void;
  // finishForm:any
}

enum ConditionTypes {
  HeartDisease = 'hasHeartDisease',
  Stroke = 'hasStroke',
  Cancer = 'hasCancer',
  Diabetes = 'hasDiabetes',
  NoneOfAbove = "hasNoneOfAbove"
}

interface Health {
  [ConditionTypes.HeartDisease]: boolean;
  [ConditionTypes.Stroke]: boolean;
  [ConditionTypes.Cancer]: boolean;
  [ConditionTypes.Diabetes]: boolean;
  [ConditionTypes.NoneOfAbove]: boolean;
}

const HealthToSetHealth: (h: Health) => quizTypes.SetHealth = (h: Health) => {
  return {
    type: 'set_health',
    hasHeartDisease: h[ConditionTypes.HeartDisease],
    hasStroke: h[ConditionTypes.Stroke],
    hasCancer: h[ConditionTypes.Cancer],
    hasDiabetes: h[ConditionTypes.Diabetes],
    hasNoneOfAbove:h[ConditionTypes.NoneOfAbove],
  };
};

const Conditions: React.FC<Props> = ({ health, setHealth }: Props) => {
  const { t } = useTranslation();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [conditions, setConditions] = useState<Health>({
    [ConditionTypes.HeartDisease]: health.hasHeartDisease || false,
    [ConditionTypes.Stroke]: health.hasStroke || false,
    [ConditionTypes.Cancer]: health.hasCancer || false,
    [ConditionTypes.Diabetes]: health.hasDiabetes || false,
    [ConditionTypes.NoneOfAbove]: health.hasNoneOfAbove || false,
  });

  const history = useHistory();

  useEffect(() => {
    console.log(health);
  }, [health]);

  const handleSubmit = async () => {
    setButtonDisabled(true)
    setHealth(HealthToSetHealth(conditions));
    //let result = showLifeExpectancyConditions(conditions);
    // toast.info(`Predicted Life Expectancy: ${result}`, {
    //   position: 'top-right',
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   onClose: () => {
    //     history.push('/additional-information');
    //   },
    // });
    showLifeExpectancyConditions(conditions);
    history.push('/additional-information');
    // await finishForm({
    //   type: 'set_extras',
    //   hasHeartDisease:conditions.hasHeartDisease,
    //   hasStroke:conditions.hasStroke,
    //   hasCancer:conditions.hasCancer,
    //   hasDiabetes:conditions.hasDiabetes,
    //   hasNoneOfAbove:conditions.hasNoneOfAbove,
    // })
    // history.push('/');

    
  };

  const options = [
    {
      value: ConditionTypes.HeartDisease,
      label: t('features.calculator.lifeExpectancy.conditions.HeartDisease'),
    },
    {
      value: ConditionTypes.Stroke,
      label: t('features.calculator.lifeExpectancy.conditions.Stroke'),
    },
    {
      value: ConditionTypes.Cancer,
      label: t('features.calculator.lifeExpectancy.conditions.Cancer'),
    },
    {
      value: ConditionTypes.Diabetes,
      label: t('features.calculator.lifeExpectancy.conditions.Diabetes'),
    },
    {
      value: ConditionTypes.NoneOfAbove,
      label: "None of above",
    },
  ];

  return (
    <Container className='content'>
      <h1 className='display-4 text-center title'>
        {t('features.calculator.lifeExpectancy.conditions.title')}
      </h1>
      <p>{t('features.calculator.lifeExpectancy.conditions.description')}</p>
      <div className='mb-2'>
        {options.map((option, index) => (
          <SelectItemView
            key={index}
            label={option.label}
            selected={conditions[option.value]}
            onSelect={(): void => {
              const isSelected = conditions[option.value];
              setConditions({ ...conditions, [option.value]: !isSelected });
            }}
          />
        ))}
      </div>
      <hr />
      <Button disabled={buttonDisabled} color='primary' block size='lg' onClick={handleSubmit}>
        {t('features.getstarted.next')}
      </Button>
      <Button
        outline
        color='link'
        onClick={() => {
          history.goBack();
        }}
        className='w-100'
      >
        {t('features.calculator.lifeExpectancy.conditions.confirmBack')}
      </Button>
      <ToastContainer />
    </Container>
  );
};

export default Conditions;
