import React, {
  MutableRefObject,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { NavProfilePicture } from '../../../shared/views/person/ProfilePicture'
import { Profile, Role } from 'shared/types'
import { AddIcon } from 'shared/views/Icon'
import { Button } from 'reactstrap'
import { useHistory } from 'react-router-dom'

interface LeftNavProps {
  profiles?: Profile[]
}

interface LeftNavScrollerProps {
  profiles?: Profile[]
  targetRef: MutableRefObject<HTMLDivElement>
  onAddNote: () => void
}

export const LeftNav: React.FC<LeftNavProps> = ({
  profiles = [],
}: LeftNavProps) => {
  const history = useHistory()

  const nonMembers = profiles.filter(
    profile => profile.membership.role !== Role.Member
  )
  const members = profiles.filter(
    profile => profile.membership.role === Role.Member
  )

  return (
    <div className="left-nav left-navbar">
      {nonMembers.map((profile, index) => {
        const handleClick = () => {
          if (profile.membership.canInvite) {
            history.push(`/dashboard/${profile.membership.id}/invite`, {
              role: profile.membership.role,
              id: profile.membership.id,
            })
          } else {
            history.push(`/dashboard/${profile.membership.id}`)
          }
        }
        return (
          <NavProfilePicture
            membership={profile.membership}
            key={`person-pic-${index}`}
            onClick={handleClick}
          />
        )
      })}
      {members.map((profile, index) => {
        const handleClick = () => {
          if (profile.membership.canInvite) {
            history.push(`/dashboard/${profile.membership.id}/invite`, {
              role: profile.membership.role,
              id: profile.membership.id,
            })
          } else {
            history.push(`/dashboard/${profile.membership.id}`)
          }
        }
        return (
          <NavProfilePicture
            membership={profile.membership}
            key={`person-pic-${index}`}
            onClick={handleClick}
          />
        )
      })}
      <Button
        outline
        color="primary"
        className="add-person"
        onClick={() => {
          history.push('/membership/new')
        }}
      >
        <AddIcon />
      </Button>
    </div>
  )
}

const LeftNavScroller: React.FC<LeftNavScrollerProps> = ({
  profiles,
  targetRef,
  onAddNote,
}: LeftNavScrollerProps) => {
  const [scrollPos, setScrollPos] = useState(0)

  const updatePos = useCallback(
    (event: any) => {
      const dist = event.target.scrollTop
      setScrollPos(dist)
    },
    [setScrollPos]
  )

  useEffect(() => {
    const current = targetRef.current || document.createElement('div')
    current.addEventListener('scroll', updatePos)
    return () => {
      current.removeEventListener('scroll', updatePos)
    }
  }, [targetRef, updatePos])

  return (
    <div className="left-nav scroller">
      <div className="add-note-float">
        <AddNoteButton onClick={onAddNote} />
      </div>
      <div
        style={{
          transform: `translateY( -${scrollPos}px )`,
        }}
      >
        <LeftNav profiles={profiles} />
      </div>
    </div>
  )
}

interface AddNoteButtonProps {
  onClick: () => void
}

const AddNoteButton: React.FC<AddNoteButtonProps> = (
  props: AddNoteButtonProps
) => (
  <div className="add-note top-aligner">
    <div className="add-note vert-line" />
    <button className="add-note content-button" onClick={props.onClick}>
      <div className="add-note content-box">
        <AddIcon className="add-note content-img" />
      </div>
    </button>
  </div>
)

export default LeftNavScroller
