import React, { useState } from 'react'
import * as types from '../../../shared/types'
import { useTranslation } from 'react-i18next'
import Header from 'shared/views/Header'
import { Button } from 'reactstrap'
import InviteSent from 'shared/views/auth/InviteSent'
import ConfirmationModal from 'shared/views/ConfirmModal'

interface ViewInvitationProps {
  inviter: types.Person
  respond: (accept: boolean) => void
  hasTimeline: boolean
}

const ViewInvitation: React.FC<ViewInvitationProps> = ({
  inviter,
  respond,
  hasTimeline,
}: ViewInvitationProps) => {
  const { t } = useTranslation()
  const [inviteAccepted, setInviteAccepted] = useState(false)
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <ConfirmationModal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
        title={t('features.invite.invitation.confirmModal.title')}
        description={t('features.invite.invitation.confirmModal.description')}
        confirm={async () => {
          await respond(true)
          setShowModal(false)
          setInviteAccepted(true)
        }}
      />
      <Header text={t('features.invite.invitation.header')} />
      {inviteAccepted ? (
        <InviteSent />
      ) : (
        <div className="content">
          <h1 className="display-4 text-center title">
            {t('features.invite.invitation.title', {
              firstName: inviter.firstName,
              lastName: inviter.lastName,
            })}
          </h1>
          <p>
            {t('features.invite.invitation.body', {
              firstName: inviter.firstName,
              lastName: inviter.lastName,
            })}
          </p>
          <hr />
          <Button
            color="primary"
            block
            size="lg"
            type="submit"
            onClick={async () => {
              if (!hasTimeline) {
                await respond(true)
                setInviteAccepted(true)
              } else {
                setShowModal(true)
              }
            }}
          >
            {t('features.invite.invitation.accept')}
          </Button>
          <Button
            outline
            color="link"
            block
            size="lg"
            type="submit"
            onClick={async () => {
              await respond(false)
              window.location.href = '/'
            }}
          >
            {t('features.invite.invitation.reject')}
          </Button>
        </div>
      )}
    </>
  )
}

export default ViewInvitation
