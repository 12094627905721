import React from 'react'
import { Person, Membership } from 'shared/types'
import './ProfilePicture.scss'
import { MagnifyIcon } from '../Icon'

interface NavProfilePictureProps {
  membership: Membership
  large?: boolean
  onClick?: () => void
}
interface ProfilePictureProps {
  person: Person
  large?: boolean
}

export const NavProfilePicture: React.FC<NavProfilePictureProps> = ({
  membership,
  large = false,
  onClick = () => {
    return
  },
}: NavProfilePictureProps) => {
  return (
    <div className="profile-picture aligner clickable" onClick={onClick}>
      <ProfilePicture person={membership.person} large={large} />
      <div className="profile-picture name-aligner">
        <span className="profile-picture name">
          {membership.person.firstName}
        </span>
      </div>
      <div className="profile-picture inspector">
        <MagnifyIcon />
      </div>
    </div>
  )
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({
  person,
  large,
}: ProfilePictureProps) => {
  // This is where we would check if the profile contains a picture. if not, return the text profile picture.
  return <TextProfilePicture person={person} large={large} />
}

const TextProfilePicture: React.FC<ProfilePictureProps> = ({
  person,
  large,
}: ProfilePictureProps) => {
  const twoLetters = `${person.firstName &&
    [...person.firstName][0]}${person.lastName && [...person.lastName][0]}`
  return (
    <>
      <svg
        className={`svg ${large ? 'large' : ''}`}
        viewBox="-21 -21 42 42"
        width="42"
        height="42"
      >
        <circle cx="0" cy="0" r="21" />
        <text x="0" y="0" dominantBaseline="central" textAnchor="middle">
          {twoLetters}
        </text>
      </svg>
    </>
  )
}

export default ProfilePicture
