import React from 'react'
import { Button, Container } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import '../styles.scss'
import { State } from '../reducer'
import { Sex, Role } from '../../../shared/types'
import { useHistory } from 'react-router-dom'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { GET_TIMELINE } from 'features/timeline'
import { FemaleIcon, MaleIcon } from 'shared/views/Icon'

type RoleType = 'primary' | 'partner' | 'child' | 'member'

const EDIT_MEMBERSHIP_MUTATION = gql`
  mutation EditMembership(
    $person: PersonInput!
    $role: String!
    $membershipId: ID!
  ) {
    editMembership(person: $person, role: $role, membershipId: $membershipId) {
      id
    }
  }
`

const ADD_MEMBERSHIP_MUTATION = gql`
  mutation AddMembership($person: PersonInput!, $role: String!) {
    addMembership(person: $person, role: $role) {
      id
    }
  }
`

const SET_SHOW_ONBOARDING_FALSE = gql`
  mutation {
    updateFeatureFlags(showNewMemberOnboarding: false) {
      id
    }
  }
`

interface PersonProps {
  firstName?: string
  lastName?: string
  sex?: Sex
  age?: number
  role: RoleType
}

const Person: React.FC<PersonProps> = ({
  firstName,
  lastName,
  sex,
  age,
  role,
}: PersonProps) => {
  const { t } = useTranslation()

  const getRole = (role: RoleType): string =>
    t(`features.getstarted.family.role.${role}`)

  return (
    <div>
      <p className="lead name">{`${firstName} ${lastName}`}</p>
      <p className="small">
        {sex &&
          (sex === Sex.Male ? (
            <MaleIcon className="sex-small" />
          ) : (
            <FemaleIcon className="sex-small" />
          ))}
        {`${getRole(role)}, ${age}`}
      </p>
    </div>
  )
}

interface Props {
  family: State
  isLoggedIn?: boolean
}

const Family: React.FC<Props> = ({ family, isLoggedIn }: Props) => {
  const history = useHistory()
  const [editMutation] = useMutation(EDIT_MEMBERSHIP_MUTATION)
  const [addMutation] = useMutation(ADD_MEMBERSHIP_MUTATION)
  const [disableOnboardingMutation] = useMutation(SET_SHOW_ONBOARDING_FALSE)

  const handleClick: () => void = !isLoggedIn
    ? () => history.push('/login')
    : async () => {
        if (family.partner) {
          await addMutation({
            variables: {
              person: {
                firstName: family.partner.firstName,
                lastName: family.partner.lastName,
                birthYear: family.partner.birthYear,
                sex: family.partner.sex,
                country: family.partner.country,
              },
              role: Role.Partner,
            },
          })
        }
        family.children.forEach(async child => {
          await addMutation({
            variables: {
              person: {
                firstName: child.firstName,
                lastName: child.lastName,
                birthYear: child.birthYear,
                sex: child.sex,
                country: child.country,
              },
              role: Role.Child,
            },
          })
        })
        family.others.forEach(async member => {
          await addMutation({
            variables: {
              person: {
                firstName: member.firstName,
                lastName: member.lastName,
                birthYear: member.birthYear,
                sex: member.sex,
                country: member.country,
              },
              role: Role.Member,
            },
          })
        })
        await editMutation({
          variables: {
            membershipId: family.membershipId,
            person: {
              firstName: family.me?.firstName,
              lastName: family.me?.lastName,
              birthYear: family.me?.birthYear,
              sex: family.me?.sex,
              country: family.me?.country,
            },
            role: Role.Primary,
          },
        })
        await disableOnboardingMutation({
          refetchQueries: [
            {
              query: GET_TIMELINE,
            },
          ],
        })
        window.location.href = '/timeline'
      }

  return <FamilyInner family={family} handleClick={handleClick} />
}

interface FamilyInnerProps {
  family: State
  handleClick?: () => void
}

export const FamilyInner: React.FC<FamilyInnerProps> = ({
  family,
  handleClick = () => {
    return
  },
}: FamilyInnerProps) => {
  const { t } = useTranslation()
  const now = new Date()

  return (
    <Container className="content">
      <p>You can edit / add members later</p>
      {family.me && (
        <Person
          firstName={family.me.firstName}
          lastName={family.me.lastName}
          sex={family.me.sex}
          age={now.getFullYear() - (family.me.birthYear || 0)}
          role="primary"
        />
      )}
      {family.partner && (
        <Person
          firstName={family.partner.firstName}
          lastName={family.partner.lastName}
          sex={family.partner.sex}
          age={now.getFullYear() - family.partner.birthYear}
          role="partner"
        />
      )}
      {family.children.map((child, index) => (
        <Person
          key={index}
          firstName={child.firstName}
          lastName={child.lastName}
          sex={child.sex}
          age={now.getFullYear() - child.birthYear}
          role="child"
        />
      ))}
      {family.others.map((other, index) => (
        <Person
          key={index}
          firstName={other.firstName}
          lastName={other.lastName}
          sex={other.sex}
          age={now.getFullYear() - other.birthYear}
          role="member"
        />
      ))}
      <Button color="primary" block size="lg" onClick={handleClick}>
        {t('features.getstarted.family.done')}
      </Button>
    </Container>
  )
}

export default Family
