import { Note } from '../types'

export const groupNotes = (notes: Note[]): Note[][] => {
  if (notes.length === 0) {
    return []
  }
  const groups: Note[][] = [[]]
  let lastNoteYear = notes[0].year
  notes.forEach(note => {
    if (note.year !== lastNoteYear) {
      groups.push([])
    }
    groups[groups.length - 1].push(note)
    lastNoteYear = note.year
  })
  return groups
}

export const currencyFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})
