import React from 'react'
import { Button } from 'reactstrap'

interface SelectProps {
  label: string
  selected: boolean
  onSelect: () => void
}

const SelectItemView: React.FC<SelectProps> = ({
  label,
  selected,
  onSelect,
}: SelectProps) => (
  <Button
    block
    outline={!selected}
    color="success"
    className="goal"
    onClick={onSelect}
  >
    {label}
  </Button>
)

export default SelectItemView
