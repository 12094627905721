import React, { useCallback, Dispatch, SetStateAction } from "react";
import "../styles.scss";
import { Profile } from "shared/types";
import { InfoIcon } from "shared/views/Icon";
import { HeaderItem } from "./NavbarTimeline";

interface MinimapSummaryProps {
	profiles?: Profile[]
	onInfoClick: Dispatch<SetStateAction<boolean>>
	selectedHeaderItem: HeaderItem
}

const MinimapSummary: React.FC<MinimapSummaryProps> = ({
	profiles = [],
	selectedHeaderItem,
	onInfoClick
}: MinimapSummaryProps) => {
	const minYear = Math.min(
		...profiles.map(profile => profile.timeline.startYear)
	);
	const maxYear = Math.max(...profiles.map(profile => profile.timeline.endYear));

	const start = minYear - (minYear % 5);
	const end = maxYear + (5 - (maxYear % 5));

	const count = profiles.length;

	const nowYear = new Date().getFullYear();

	const px = useCallback(
		(num: number) => {
			return (num * (end - start)) / window.innerWidth;
		},
		[end, start]
	);

	return (
		<div className="minimap summary">
			<svg
				className="minimap summary"
				viewBox={`${start} -7 ${end - start} ${9 + 13 * count}`}
				preserveAspectRatio="none"
				height="60px"
				width="100%"
			>
				{profiles.map((profile, index) => (
					<rect
						className="timeline"
						x={profile.timeline.startYear}
						y={13 * index}
						width={profile.timeline.endYear - profile.timeline.startYear}
						height="8"
						key={index}
					/>
				))}
				{profiles.map((profile, index) => (
					<React.Fragment key={`profile ${index}`}>
						{profile.timeline.segments.map((segment, indexTwo) => (
							<rect
								className={`bar ${segment.type}`}
								x={segment.start}
								y={13 * index}
								width={segment.end - segment.start}
								height="8"
								key={`minimap bar ${index} ${indexTwo}`}
							/>
						))}
						{profile.timeline.notes.map((note, indexTwo) => (
							<line
								className="minimap note"
								x1={note.year}
								x2={note.year}
								y1={13 * index}
								y2={13 * index + 8}
								strokeWidth={px(3)}
								key={`minimap note ${index} ${indexTwo}`}
							/>
						))}
					</React.Fragment>
				))}
				<line
					className="year-redline minimap"
					x1={nowYear}
					x2={nowYear}
					y1={-7}
					y2={9 + 13 * count}
					strokeWidth={px(2)}
				/>
			</svg>
			{selectedHeaderItem === HeaderItem.Rates && <div onClick={onInfoClick as any} className="info-container"><InfoIcon /></div>}
		</div>
	);
};

export default MinimapSummary;
