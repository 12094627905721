import React from 'react'
import { Segment, SegmentType } from 'shared/types'
import AxisTranslator from 'shared/utils/axis'
import { SchoolIcon, WorkIcon, UniversityIcon } from 'shared/views/Icon'
import '../styles.scss'

interface TimelineBarSegmentProps {
  start: number
  segment: Segment
}

const icons = {
  [SegmentType.School]: SchoolIcon,
  [SegmentType.University]: UniversityIcon,
  [SegmentType.Work]: WorkIcon,
  [SegmentType.Gold]: null,
  [SegmentType.Silver]: null,
  [SegmentType.Bronze]: null,
}

const TimelineBarSegment: React.FC<TimelineBarSegmentProps> = ({
  start,
  segment,
}: TimelineBarSegmentProps) => {
  const Icon = icons[segment.type]

  return (
    <div
      className={`bar segment ${segment.type}-segment`}
      style={{
        left: AxisTranslator.year(segment.start - start, false),
        width: AxisTranslator.year(segment.end - segment.start, false),
      }}
    >
      {Icon && <Icon />}
    </div>
  )
}

export default TimelineBarSegment
