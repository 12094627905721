import React from 'react'
import { Field, ErrorMessage } from 'formik'
import {
  Button,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import '../styles.scss'
import { ContributionType } from '../types'

interface Props {
  index: number
  isRetired: boolean
  isSubmitting: boolean
  handleChange: (e: React.ChangeEvent<any>) => void
  remove: (index: number) => unknown
}

const RetirementAccountItem: React.FC<Props> = ({
  index,
  isRetired,
  isSubmitting,
  handleChange,
  remove,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Row form className="mb-3">
      <Col sm={3} className="networth-form-column">
        <label htmlFor={`accounts.${index}.name*`}>
          {t('features.calculator.retirement.labels.name')}
        </label>
        <Field
          as={Input}
          name={`accounts.${index}.name`}
          onChange={handleChange}
          disabled={isSubmitting}
          placeholder={t(`features.calculator.retirement.default.name`)}
        />
        <ErrorMessage name={`accounts.${index}.name`}>
          {msg => <div className="mt-1 form-error">{msg}</div>}
        </ErrorMessage>
      </Col>

      <Col sm={2} className="networth-form-column">
        <label htmlFor={`accounts.${index}.value`}>
          {t('features.calculator.retirement.labels.value')}
        </label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">$</InputGroupAddon>
          <Field
            as={Input}
            type="number"
            name={`accounts.${index}.value`}
            onChange={handleChange}
            disabled={isSubmitting}
            placeholder={t('features.calculator.retirement.default.value')}
          />
        </InputGroup>
        <ErrorMessage name={`accounts.${index}.value`}>
          {msg => <div className="mt-1 form-error">{msg}</div>}
        </ErrorMessage>
      </Col>

      <Col sm="2" className="networth-form-column">
        <label htmlFor={`accounts.${index}.annualGrowth`}>
          {t('features.calculator.retirement.labels.annualGrowth')}
        </label>
        <InputGroup>
          <Field
            as={Input}
            type="number"
            name={`accounts.${index}.annualGrowth`}
            onChange={handleChange}
            disabled={isRetired || isSubmitting}
            placeholder={t(
              'features.calculator.retirement.default.annualGrowth'
            )}
          />
          <InputGroupAddon addonType="append">%</InputGroupAddon>
        </InputGroup>
        <ErrorMessage name={`accounts.${index}.annualGrowth`}>
          {msg => <div className="mt-1 form-error">{msg}</div>}
        </ErrorMessage>
      </Col>

      <Col sm={2} className="networth-form-column">
        <label htmlFor={`accounts.${index}.contribution`}>
          {t('features.calculator.retirement.labels.contribution')}
        </label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">$</InputGroupAddon>
          <Field
            as={Input}
            type="number"
            name={`accounts.${index}.contribution`}
            onChange={handleChange}
            disabled={isRetired || isSubmitting}
            placeholder={t(
              'features.calculator.retirement.default.contribution'
            )}
          />
        </InputGroup>
        <ErrorMessage name={`accounts.${index}.contribution`}>
          {msg => <div className="mt-1 form-error">{msg}</div>}
        </ErrorMessage>
      </Col>

      <Col sm={2} className="networth-form-column">
        <label htmlFor={`accounts.${index}.contributionType`}>
          {t('features.calculator.retirement.labels.contributionType')}
        </label>
        <Field
          as={Input}
          type="select"
          name={`accounts.${index}.contributionType`}
          onChange={handleChange}
          disabled={isRetired || isSubmitting}
        >
          <option value={'default'}>
            {t(
              'features.calculator.retirement.contributionType.options.DEFAULT'
            )}
          </option>
          {Object.values(ContributionType).map(type => (
            <option key={type} value={type}>
              {t(
                `features.calculator.retirement.contributionType.options.${type}`
              )}
            </option>
          ))}
        </Field>
        <ErrorMessage name={`accounts.${index}.contributionType`}>
          {msg => <div className="mt-1 form-error">{msg}</div>}
        </ErrorMessage>
      </Col>

      <Col sm="1" className="networth-form-column">
        <Button
          title="[Remove]"
          color="secondary"
          className="remove-btn"
          disabled={isSubmitting}
          block
          onClick={() => {
            remove(index)
          }}
        >
          -
        </Button>
      </Col>
    </Row>
  )
}

export default RetirementAccountItem
